<template>
	<div class="base-header">
		<div class="nav2">
			<ul>
				<li v-for="(item, index) in menu" :key="index" @click="goMenu(item.id, item.path)" :class="{
            activeli: $route.name == item.paths,
          }">
					<span :class="{
            active: $route.name == item.paths,
          }">{{ item.value }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "base-header",
		data() {
			return {
				menu: [{
						id: 1,
						img1: require("@/assets/img/nav2/b1.png"),
						img2: require("@/assets/img/nav2/b11.png"),
						selState: true,
						path: "Index",
						paths: "Index",
						child: ["Index", "Openbox"],
						value: "首页",
					},
					{
						id: 2,
						img1: require("@/assets/img/nav2/b6.png"),
						img2: require("@/assets/img/nav2/b66.png"),
						selState: false,
						path: "task-center",
						paths: "TaskCenter",
						child: ["task-center"],
						value: "福利中心",
					},
					{
						id: 3,
						img1: require("@/assets/img/nav2/b3.png"),
						img2: require("@/assets/img/nav2/b33.png"),
						selState: false,
						path: "Arena",
						paths: "Arena",
						child: ["Arena", "ArenaRoom"],
						value: "ROLL房",
					},
					// {
					// 	id: 4,
					// 	img1: require("@/assets/img/nav2/b2.png"),
					// 	img2: require("@/assets/img/nav2/b22.png"),
					// 	selState: false,
					// 	path: "Lucky",
					// 	paths: "Lucky",
					// 	child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
					// 	value: "决战沙城",
					// },
					
					// {
					// 	id: 5,
					// 	img1: require("@/assets/img/nav2/b4.png"),
					// 	img2: require("@/assets/img/nav2/b44.png"),
					// 	selState: false,
					// 	path: "OrnamentOpen",
					// 	paths: "OrnamentOpen",
					// 	child: [
					// 		"Ornament",
					// 		"OrnamentOpen",
					// 		"OrnamentHistory",
					// 		"OrnamentHistory2",
					// 	],
					// 	value: "幸运饰品",
					// },
					{
						id: 6,
						img1: require("@/assets/img/nav2/b5.png"),
						img2: require("@/assets/img/nav2/b55.png"),
						selState: false,
						path: "Roll",
						paths: "Roll",
						child: ["Roll"],
						value: "饰品商城",
					},
					
					// {
					// 	id: 6,
					// 	img1: require("@/assets/img/nav2/b6.png"),
					// 	img2: require("@/assets/img/nav2/b66.png"),
					// 	selState: false,
					// 	path: "Mes",
					// 	child: ["Mes"],
					// 	value: "活动推广",
					// },
					// {
					// 	id: 7,
					// 	img1: require("@/assets/img/nav2/b8.png"),
					// 	img2: require("@/assets/img/nav2/b88.png"),
					// 	selState: false,
					// 	path: "Mes",
					// 	child: ["Mes"],
					// 	value: "饰品背包",
					// },
				],
			};
		},
		mounted() {
			console.log(this.$route)
		},
		methods: {
			goMenu(id, path) {
				this.$emit("goMenu", id, path);
			},
		},
	};
</script>

<style lang="less" scoped>
	.base-header {
		padding-left: 50px;

		.nav2 {
			height: 42px;

			ul {
				display: flex;

				.active {
					color: #ff9b0b;
				}

				li {
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100px;
					text-align: center;
					cursor: pointer;
					color: #fff;
					font-size: 15px;
					line-height: 38px;
					margin-left: 10px;
					white-space: nowrap;
					font-weight: bold;
				}
			}
		}
	}
</style>