<template>
	<div class="dota">
		<div class="dota-warp">
			<div>
				<div class="data-top" v-if="$route.query.type && $route.query.type == '我的背包'">
					<span>如遇饰品取回问题请联系平台客服</span>
					<div @click="quhuijc">取回教程</div>
					<!-- <span>背包流水></span> -->
				</div>
				<div class="data-top" v-if="$route.query.type && $route.query.type == '提取中的饰品'">
					<span>提取中的饰品</span>
				</div>
				<div class="data-top" v-if="$route.query.type && $route.query.type == '已提取的饰品'">
					<span>已提取的饰品</span>
				</div>
				<el-tabs type="border-card" v-model="activeName" @tab-click="getTab">
					<el-tab-pane label="我的背包" name="one">
						<div class="dota-sel" v-if="$route.query.type && $route.query.type == '我的背包'">
							<!-- <div class="sel-left">价格从低到高</div> -->
							<div class="sel-right">
								<div class="right-one">
									<div class="xuanze" @click="offAll()" v-if="checkedPrice > 0">
										<img src="../assets/img/fuxuan1.png" style="width: 16px;" alt="">
										<span style="color: #e9b10e; padding: 0">({{ checkedPrice }})</span>
										选择当前页
									</div>
									<span class="xuanze" @click="selAll()" v-else>
										<img src="../assets/img/fuxuan.png" style="width: 16px;" alt="">
										选择当前页
									</span>
									<!-- <span class="span1" @click="offAll()">取消选择</span> -->
									<el-button class="span2" @click="coniAll()" :disabled="loading"><i v-if="loading"
											class="el-icon-loading"></i>全部兑换</el-button>
									<el-button class="span2" @click="conisAll()" :disabled="loading"><i v-if="loading"
											class="el-icon-loading"></i>全部提取</el-button>
									<!--<span class="span3">取回</span> -->
								</div>
							</div>
						</div>
						<div class="dota-list">
							<ul>
								<li v-for="(item, index) in skinList" :key="index" @click="check(item.id)">
									<div class="list-warp">
										<div class="warp3" v-if="item.state1"><span>取回中</span></div>
										<div class="warp1" v-if="item.state"></div>
										<img class="warp2" v-if="item.state" src="../assets/img/gou.png" />
										<span class="ico" v-if="item.exteriorName">{{ item.exteriorName }}</span>
										<span v-if="item.state" class="buy-state">
											<img src="../assets/img/gou.png" />
										</span>
										<div class="list-img">
											<img :src="item.img" />
										</div>
										<div class="bot">
											<div class="list-name" :title="item.name">{{ item.name }}</div>
											<div class="list-pirce">
												<div class="pirce-left">
													<img src="../assets/img/money.png" />
													<span>{{ item.price }}</span>
												</div>
												<div class="pirce-right"
													v-if="$route.query.type && $route.query.type == '我的背包'">
													<el-button class="spanbtn1" @click="getExchange($event, item.id)"
														:disabled="item.loading1"><i v-if="item.loading1"
															class="el-icon-loading"></i>兑换</el-button>
													<el-button class="spanbtn2"
														@click="getPull($event, item.id, item.itemId)"
														:disabled="item.loading2"><i v-if="item.loading2"
															class="el-icon-loading"></i>取回</el-button>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
							<div class="clear"></div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="提取中的饰品" name="two">
						<div class="dota-sel" v-if="$route.query.type && $route.query.type == '我的背包'">
							<!-- <div class="sel-left">价格从低到高</div> -->
							<div class="sel-right">
								<div class="right-one">
									<div class="xuanze" @click="offAll()" v-if="checkedPrice > 0">
										<img src="../assets/img/fuxuan1.png" style="width: 16px;" alt="">
										<span style="color: #e9b10e; padding: 0">({{ checkedPrice }})</span>
										选择当前页
									</div>
									<span class="xuanze" @click="selAll()" v-else>
										<img src="../assets/img/fuxuan.png" style="width: 16px;" alt="">
										选择当前页
									</span>
									<!-- <span class="span1" @click="offAll()">取消选择</span> -->
									<el-button class="span2" @click="coniAll()" :disabled="loading"><i v-if="loading"
											class="el-icon-loading"></i>全部兑换</el-button>
									<el-button class="span2" @click="conisAll()" :disabled="loading"><i v-if="loading"
											class="el-icon-loading"></i>全部提取</el-button>
									<!--<span class="span3">取回</span> -->
								</div>
							</div>
						</div>
						<div class="dota-list">
							<ul>
								<li v-for="(item, index) in skinList" :key="index" @click="check(item.id)">
									<div class="list-warp">
										<div class="warp3" v-if="item.state1"><span>取回中</span></div>
										<div class="warp1" v-if="item.state"></div>
										<img class="warp2" v-if="item.state" src="../assets/img/gou.png" />
										<span class="ico" v-if="item.exteriorName">{{ item.exteriorName }}</span>
										<span v-if="item.state" class="buy-state">
											<img src="../assets/img/gou.png" />
										</span>
										<div class="list-img">
											<img :src="item.img" />
										</div>
										<div class="bot">
											<div class="list-name" :title="item.name">{{ item.name }}</div>
											<div class="list-pirce">
												<div class="pirce-left">
													<img src="../assets/img/money.png" />
													<span>{{ item.price }}</span>
												</div>
												<div class="pirce-right"
													v-if="$route.query.type && $route.query.type == '我的背包'">
													<el-button class="spanbtn1" @click="getExchange($event, item.id)"
														:disabled="item.loading1"><i v-if="item.loading1"
															class="el-icon-loading"></i>兑换</el-button>
													<el-button class="spanbtn2"
														@click="getPull($event, item.id, item.itemId)"
														:disabled="item.loading2"><i v-if="item.loading2"
															class="el-icon-loading"></i>取回</el-button>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
							<div class="clear"></div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="已提取的饰品" name="three">
						<div class="dota-sel" v-if="$route.query.type && $route.query.type == '我的背包'">
							<!-- <div class="sel-left">价格从低到高</div> -->
							<div class="sel-right">
								<div class="right-one">
									<div class="xuanze" @click="offAll()" v-if="checkedPrice > 0">
										<img src="../assets/img/fuxuan1.png" style="width: 16px;" alt="">
										<span style="color: #e9b10e; padding: 0">({{ checkedPrice }})</span>
										选择当前页
									</div>
									<span class="xuanze" @click="selAll()" v-else>
										<img src="../assets/img/fuxuan.png" style="width: 16px;" alt="">
										选择当前页
									</span>
									<!-- <span class="span1" @click="offAll()">取消选择</span> -->
									<el-button class="span2" @click="coniAll()" :disabled="loading"><i v-if="loading"
											class="el-icon-loading"></i>全部兑换</el-button>
									<el-button class="span2" @click="conisAll()" :disabled="loading"><i v-if="loading"
											class="el-icon-loading"></i>全部提取</el-button>
									<!--<span class="span3">取回</span> -->
								</div>
							</div>
						</div>
						<div class="dota-list">
							<ul>
								<li v-for="(item, index) in skinList" :key="index" @click="check(item.id)">
									<div class="list-warp">
										<div class="warp3" v-if="item.state1"><span>取回中</span></div>
										<div class="warp1" v-if="item.state"></div>
										<img class="warp2" v-if="item.state" src="../assets/img/gou.png" />
										<span class="ico" v-if="item.exteriorName">{{ item.exteriorName }}</span>
										<span v-if="item.state" class="buy-state">
											<img src="../assets/img/gou.png" />
										</span>
										<div class="list-img">
											<img :src="item.img" />
										</div>
										<div class="bot">
											<div class="list-name" :title="item.name">{{ item.name }}</div>
											<div class="list-pirce">
												<div class="pirce-left">
													<img src="../assets/img/money.png" />
													<span>{{ item.price }}</span>
												</div>
												<div class="pirce-right"
													v-if="$route.query.type && $route.query.type == '我的背包'">
													<el-button class="spanbtn1" @click="getExchange($event, item.id)"
														:disabled="item.loading1"><i v-if="item.loading1"
															class="el-icon-loading"></i>兑换</el-button>
													<el-button class="spanbtn2"
														@click="getPull($event, item.id, item.itemId)"
														:disabled="item.loading2"><i v-if="item.loading2"
															class="el-icon-loading"></i>取回</el-button>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
							<div class="clear"></div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>

			<div class="roll-page">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
					@current-change="currentChange">
				</el-pagination>
			</div>
		</div>
		<span :plain="true">{{ exchangeHint }}</span>
		<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>

		<div class="exchange-decoration" v-if="changeDecoration.length&& !changeDecorations.length">
			<div class="content">
				<span class="title">确定要回收吗?</span>
				<!-- <div class="types">
					<div @click="exchangeType = 'dollar'" class="btn" :class="{
              'active-btn': exchangeType == 'dollar',
            }">
						<span>金币：</span>
						<img class="dollar" src="../assets/img/money.png" />
						<span>{{ checkedPrice }}</span>
					</div>
					<div class="btn" @click="exchangeType = 'diamond'" :class="{
              'active-btn': exchangeType == 'diamond',
            }">
						<span>c币：</span>
						<img class="diamond" src="../assets/img/masonry.png" />
						<span>{{ checkedPrice }}</span>
					</div>
				</div> -->
				<div class="operate">
					<div @click="changeDecoration = []">取消</div>
					<div @click="startExchange">确认</div>
				</div>
			</div>
		</div>
		<div class="exchange-decoration" v-if="changeDecorations.length && changeDecorations.length">
			<div class="content">
				<span class="title">确定要提取吗?</span>
				<!-- <div class="types">
					<div @click="exchangeType = 'dollar'" class="btn" :class="{
              'active-btn': exchangeType == 'dollar',
            }">
						<span>金币：</span>
						<img class="dollar" src="../assets/img/money.png" />
						<span>{{ checkedPrice }}</span>
					</div>
					<div class="btn" @click="exchangeType = 'diamond'" :class="{
              'active-btn': exchangeType == 'diamond',
            }">
						<span>c币：</span>
						<img class="diamond" src="../assets/img/masonry.png" />
						<span>{{ checkedPrice }}</span>
					</div>
				</div> -->
				<div class="operate">
					<div @click="NoExchange">取消</div>
					<div @click="startExchanges">确认</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Utils from "./../assets/js/util.js";
	import navheader from "@/components/navheader.vue";
	export default {
		components: {
			navheader
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				activeName: "one",
				loading: false,
				total: 0,
				total_value: 0,
				skinList: [],
				pageSize: 12,
				exchangeHint: "",
				checkedPrice: 0,
				random: "",
				exchangeType: "diamond",
				changeDecoration: [],
				page: 2,
				changeDecorations: []
			};
		},
		methods: {
			getTab(tab) {
				//console.log(tab.name);
				if (tab.name == "one") {
					this.$route.query.type = '我的背包'
				} else if (tab.name == "two") {
					this.$route.query.type = '提取中的饰品'
				} else if ((tab.name == "three")) {
					this.$route.query.type = '已提取的饰品'
				}
				this.getList();
			},
			//兑换多个
			coniAll() {
				if (!Number.parseFloat(this.checkedPrice)) {
					this.$message.error("请选中饰品");
					return;
				}
				var arr = [];
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state1) {
						this.$message({
							message: "正在取回中，稍后重试",
							type: "warning",
						});
						return;
					}
				}
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state) {
						arr.push(this.skinList[i].id);
					}
				}
				this.changeDecoration = arr;
			},
			// 提取多个
			conisAll() {
				// console.log('提取')
				if (!Number.parseFloat(this.checkedPrice)) {
					this.$message.error("请选中饰品");
					return;
				}
				var arr = [];
				var arrs = [];
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state1) {
						this.$message({
							message: "正在取回中，稍后重试",
							type: "warning",
						});
						return;
					}
				}
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state) {
						arr.push(this.skinList[i].id);
						arrs.push(this.skinList[i].itemId);
					}
				}
				this.changeDecoration = arr;
				this.changeDecorations = arrs;
			},
			//单个选中
			check(id) {
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].id == id) {
						this.skinList[i].state = !this.skinList[i].state;
						console.log(this.skinList[i].state1)
						if (this.skinList[i].state == true && !this.skinList[i].state1) {
							this.checkedPrice = (
								parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
							).toFixed(2);
						} else {
							this.checkedPrice = (
								parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
							).toFixed(2);
						}
					}
				}
				this.$forceUpdate();
			},
			//选择当前页
			selAll() {
				this.checkedPrice = 0;
				for (let i = 0; i < this.skinList.length; i++) {
					if (!this.skinList[i].state1) {
						if (this.skinList[i].price != null) {
							this.skinList[i].state = true;
							this.checkedPrice = (
								parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
							).toFixed(2);
						}
					}
				}
			},
			//取消当前页
			offAll() {
				this.checkedPrice = 0;
				for (let i = 0; i < this.skinList.length; i++) {
					this.skinList[i].state = false;
					this.checkedPrice = (
						parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
					).toFixed(2);
				}
				this.$forceUpdate();
				this.tiqu = false;
			},
			/**
			 * 兑换成 c币
			 * @param {*} id
			 */
			exchangeToMoney(id) {
				let param = {
					player_skins_ids: id,
				};
				this.$axios
					.post("/index/User/exchangeToMoney", this.$qs.stringify(param))
					.then((res) => {
						console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$store.commit("getMoney", data.data.total_amount);
							Utils.$emit("masonry", data.data.total_amount);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
							this.changeDecoration = [];
						} else {
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
								}
							}
							this.$forceUpdate();
							this.$message({
								showClose: true,
								message: data.msg,
								type: "warning",
							});
						}
					});
			},
			/**
			 * 兑换成 金币
			 * @param {*} id
			 */
			exchangeToMoney2(id) {
				let param = {
					player_skins_ids: id,
				};
				this.$axios
					.post("/index/User/exchangeToMoney2", this.$qs.stringify(param))
					.then((res) => {
						console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$store.commit("getMoney", res.data.data.total_amount);
							Utils.$emit("money", res.data.data.total_amount);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
							this.changeDecoration = [];
						} else {
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
								}
							}
							this.$forceUpdate();
							this.$message({
								showClose: true,
								message: data.msg,
								type: "warning",
							});
						}
					});
			},

			//点击取回
			getPull(event, id, steamId) {
				event.stopPropagation();
				for (let i = 0; i < this.skinList.length; i++) {
					if (id == this.skinList[i].id) {
						this.skinList[i].loading1 = true;
						this.skinList[i].loading2 = true;
						this.skinList[i].state1 = true;
					}
				}
				this.$forceUpdate();
				let param = {
					player_skins_id: id,
					itemId: steamId,
					random: this.random,
				};
				this.$axios
					.post("/index/User/skinToSteam1", this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
						} else {
							var hint = "";
							if (data.msg.indexOf("余额不足") != -1) {
								hint = "取回错误，请联系客服";
							} else if (data.msg.indexOf("取回失败,请充值") != -1) {
								hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
							} else {
								hint = data.msg;
							}
							this.$message({
								showClose: true,
								message: hint,
								type: "warning",
							});
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
									this.skinList[i].state1 = false;
								}
							}
							this.$forceUpdate();
						}
						this.randomString();
					});
			},
			randomString(e) {
				e = e || 32;
				var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
					a = t.length,
					n = "";
				for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
				this.random = n;
			},
			quhuijc() {
				this.$router.push({
					path: 'jiaocheng'
				})
			},
			//点击兑换
			getExchange(event, id) {
				event.stopPropagation();
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state1) {
						this.$message({
							message: "正在取回中，稍后重试",
							type: "warning",
						});
						return;
					}
				}
				for (let i = 0; i < this.skinList.length; i++) {
					if (id == this.skinList[i].id) {
						this.skinList[i].loading1 = true;
						this.skinList[i].loading2 = true;
						this.checkedPrice = this.skinList[i].price;
					}
				}
				this.$forceUpdate();
				this.changeDecoration = [id];
			},
			startExchange() {
				// 兑换金币
				if (this.exchangeType == "dollar") {
					this.exchangeToMoney2(this.changeDecoration);
				} else {
					// 兑换c币
					this.exchangeToMoney(this.changeDecoration);
				}
				this.checkedPrice = 0;
			},
			//点击取回
			getPulls(id, steamId) {
				for (let i = 0; i < this.skinList.length; i++) {
					if (id == this.skinList[i].id) {
						this.skinList[i].loading1 = true;
						this.skinList[i].loading2 = true;
						this.skinList[i].state1 = true;
					}
				}
				this.$forceUpdate();
				let param = {
					player_skins_ids: id,
					itemId: steamId,
					random: this.random,
				};
				this.$axios
					.post("/index/User/skinToSteam1", this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.changeDecoration=[]
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
						} else {
							var hint = "";
							if (data.msg.indexOf("余额不足") != -1) {
								hint = "取回错误，请联系客服";
							} else if (data.msg.indexOf("取回失败,请充值") != -1) {
								hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
							} else {
								hint = data.msg;
							}
							this.$message({
								showClose: true,
								message: hint,
								type: "warning",
							});
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
									this.skinList[i].state1 = false;
								}
							}
							this.$forceUpdate();
						}
						this.randomString();
					});
			},
			startExchanges() {
				// 兑换
				this.getPulls(this.changeDecoration, this.changeDecorations);
				// this.checkedPrice = 0;
			},
			NoExchange() {
				this.changeDecoration.forEach(e => {
					for (let i = 0; i < this.skinList.length; i++) {
						if (e == this.skinList[i].id) {
							this.skinList[i].loading1 = false;
							this.skinList[i].loading2 = false;
						}
					}
				})
				this.$forceUpdate();
				this.changeDecoration = [];
				this.changeDecorations = [];
			},
			currentChange(val) {
				this.getList(val);
				this.checkedPrice = 0;
			},
			getList(page) {
				this.page = page;
				let param = {
					pageSize: this.pageSize,
					page: this.page
				};
				var url = "/index/User/packageList";
				if (this.$route.query.type == '已提取的饰品') {
					url = "/index/User/backList";
				}
				if (this.$route.query.type == '提取中的饰品') {
					url = "/index/User/processingList";
				}
				this.$axios
					.post(url, this.$qs.stringify(param))
					.then((res) => {
						var data = res.data;
						this.skinList = [];
						if (data.status == "1") {
							this.total = data.data.total;
							this.total_value = data.data.total_value;
							this.skinList = data.data.skinList;
						}
						if (data.msg == "无数据") {
							this.skinList = [];
						}
					});
			}
		},
		mounted() {
			if (!this.$route.query.type) {
				this.$route.query.type = '我的背包';
			}
			this.randomString();
			this.getList();
		},
	};
</script>

<style lang="less" scoped>
	//分页
	.roll-page {
		margin: 10px 0 0px -10px;
		// margin-bottom: 50px;
		padding-bottom: 50px;
	}

	.roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #e9b10e;
		color: #1a1c24;
	}

	.roll-page /deep/ .el-pagination.is-background .btn-next,
	/deep/ .el-pagination.is-background .btn-prev,
	/deep/ .el-pagination.is-background .el-pager li {
		background-color: #333542;
		color: #848492;
	}

	/deep/ .el-tabs--border-card {
		// background-color: #2b2c37;
		background-color: rgba(16, 36, 48, 1);
		border: none;
	}

	/deep/ .el-tabs--border-card>.el-tabs__header {
		border: none;
		background-color: rgba(16, 36, 48, 1);
	}

	/deep/.cell {
		text-align: center;
		height: 35px;
		line-height: 35px;
	}

	/deep/.el-tab-pane {
		min-height: 65vh;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
	}

	/deep/.el-table__header th {
		background-color: rgba(16, 36, 48, 1) !important;
	}

	/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		background-color: rgba(27, 77, 98, 1);
		border: none;
	}

	/deep/.el-tabs__nav {
		width: 100%;
	}

	/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
		// background-color: #24252f;
		background-color: rgba(24, 59, 79, 1);
		height: 35px;
		line-height: 35px;
		width: 33.33%;
		font-size: 12px;
		text-align: center;
		border: none;
		padding: 0 12px;
	}

	/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		color: #e9b10e;
	}

	.exchange-decoration {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 999999999999;
		background-color: rgba(0, 0, 0, 0.6);
		display: grid;
		place-items: center;

		.content {
			width: 80%;
			max-width: 500px;
			background-color: #1b2330;
			border-radius: 10px;
			color: #fff;
			text-align: center;
			box-sizing: border-box;
			padding: 30px 0px 0 0px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			.title {
				font-size: 20px;
				flex: 1;
				font-weight: bold;
				margin: 0 0 30px 0;
			}

			.types {
				width: 70%;
				margin-bottom: 50px;

				.active-btn {
					background-color: #e9b10e;
					color: #000 !important;
					font-weight: bold;
				}

				.btn {
					margin: 10px 0;
					height: 40px;
					line-height: 40px;
					color: #fff;
					cursor: pointer;
					border-radius: 8px;
					border: 1px solid #2b3441;
					display: flex;
					justify-content: center;
					align-items: center;

					.dollar {
						width: 20px;
						height: 20px;
						margin-right: 3px;
					}

					.diamond {
						width: 17px;
						margin-right: 3px;
						height: 17px;
					}
				}
			}

			.operate {
				width: 100%;
				height: 50px;
				font-weight: bold;
				display: flex;
				border-top: 1px solid #000;

				&>div {
					flex: 1;
					display: grid;
					place-items: center;
					cursor: pointer;

					&:first-child {
						border-right: 1px solid #000;
					}
				}
			}
		}
	}

	.dota {
		width: 100;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		// background-color: #1a1c24;

		.hint {
			margin-top: 10px;
			color: #c3c3e2;

			i {
				font-size: 20px;
				margin-right: 5px;
			}
		}

		.dota-warp {
			height: 100%;
			// padding: 16px;
			min-height: 79vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.data-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 16px;

				span:first-child {
					color: #848492;
					font-size: 16px;
				}

				span:last-child {
					color: #848492;
					font-size: 14px;
				}

				span:last-child:hover {
					color: #e9b10e;
					cursor: pointer;
				}

				div {
					color: #e9b10e;
					font-size: 12px;
					text-decoration: underline;
				}
			}

			.dota-sel {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.sel-left {
					color: #e9b10e;
					font-size: 12px;
					font-weight: 600;
				}

				.sel-left:hover {
					cursor: pointer;
					text-decoration: underline;
				}

				.sel-right {
					margin-top: 0;
					max-height: 50px;
					display: flex;
					align-items: center;
					width: 100%;

					.right-one {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						width: 100%;
						justify-content: space-between;


						.xuanze {
							margin-top: 0;
							width: calc(48% - 24px);
							padding-left: 24px;
							height: 40px;
							border-radius: 5px;
							font-size: 12px;
							color: #848492;
							font-weight: 600;
							background-color: #333452;
							display: flex;
							align-items: center;

							img {
								margin-right: 6px !important;
							}

							span {
								margin: 0 !important;
								margin-right: 6px !important;
							}
						}

						.span1 {
							background-color: #333452;
						}

						.span1:hover {
							cursor: pointer;
							background-color: #3a3f50;
						}

						.span2 {
							font-size: 12px;
							margin-top: 0;
							width: 24%;
							background-color: #e9b10e;
							color: #1a1c24;
							display: flex;
							align-items: center;
							justify-content: center;
							border-color: #e9b10e;
							margin-left: 0;
						}

						.span2:hover {
							cursor: pointer;
							background-color: #f5c432;
						}

						.span3 {
							background-color: #17b4ed;
							color: #1a1c24;
						}

						.span3:hover {
							cursor: pointer;
							background-color: #3eccff;
						}
					}

					/* .right-two /deep/ .el-input__inner{
            background-color: #2b2c3f;
        }*/
				}
			}

			//分页
			.roll-page {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 10px 0 0px -10px;
				// margin-bottom: 50px;
				padding-bottom: 50px;
			}

			.roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: #e9b10e;
				color: #1a1c24;
			}

			.roll-page /deep/ .el-pagination.is-background .btn-next,
			/deep/ .el-pagination.is-background .btn-prev,
			/deep/ .el-pagination.is-background .el-pager li {
				background-color: #333542;
				color: #848492;
			}

			.dota-list {
				margin-top: 20px;

				ul {
					margin: 0 -8px;


					li {
						width: 12.5%;
						float: left;

						.list-warp {
							margin: 8px;
							background-color: #2b2c37;
							border-radius: 5px;
							position: relative;
							overflow: hidden;

							.warp1 {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: rgba(233, 177, 14, 0.1);
								z-index: 33;
							}

							.warp3 {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: rgba(0, 0, 0, 0.3);
								z-index: 66;
								display: flex;
								justify-content: center;

								span {
									margin-top: 40px;
									color: #fff;
									font-size: 20px;
								}
							}

							.warp2 {
								width: 20px;
								height: 20px;
								position: absolute;
								right: 0;
								top: 0;
							}

							.list-bor {
								position: absolute;
								top: 0;
								z-index: 666;
								width: 100%;
								height: 100%;
								background-color: rgba(233, 177, 14, 0.1);
							}

							.ico {
								position: absolute;
								top: 0;
								left: 0;
								font-size: 12px;
								color: #f7faf7;
								background-color: rgb(81, 113, 156);
								padding: 2px 4px;
							}

							.buy-state {
								position: absolute;
								top: 0;
								right: 0;

								img {
									width: 15px;
									height: 15px;
								}
							}


							.list-img {
								// height: 160px;
								background-image: url("../assets/img/beibao.png");
								background-size: 100% 100%;
								padding: 0 20px;
								display: flex;
								align-items: center;
								justify-content: center;

								img {
									width: auto;
									height: 100%;
									max-height: 108px;
								}
							}

							.bot {
								background-color: #2b2c37;

								.list-name {
									padding-left: 5px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									font-size: 16px;
									color: #c3c3e2;
								}

								.list-pirce {
									padding: 10px 5px 10px 5px;
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: space-between;
									// justify-content: flex-end;

									.pirce-left {
										display: flex;
										align-items: center;
										margin-right: 10px;

										img {
											width: auto;
											height: 15px;
											margin-right: 5px;
										}

										span {
											color: #e9b10e;
											font-size: 16px;
										}
									}

									.pirce-right {
										span {
											padding: 4px 15px;
											border-radius: 5px;
											font-size: 12px;
											white-space: nowrap;
										}

										span:hover {
											cursor: pointer;
										}

										.spanbtn1 {
											border: 1px solid #e9b10e;
											color: #e9b10e;
											padding: 4px 8px;
											font-size: 12px;
										}

										.spanbtn2 {
											padding: 4px 8px;
											background-color: #e9b10e;
											border-color: #e9b10e;
											font-size: 12px;
										}
									}
								}
							}
						}

						.list-warp:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>