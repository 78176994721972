<template>
	<div class="me">
		<div class="me-warp">
			<div class="me-tou">
				<div class="met-left">
					<div class="tou-left">
						<img :src="Data.img" />
					</div>
					<div class="tou-right">
						<div class="tou-right-top">
							<div class="tou1">
								<!-- <img src="../assets/img/13mdpi.png" /> -->
								<span class="tou1-span1">{{ Data.name }}</span>
							</div>
							<div class="tou1">
								<!-- <img src="../assets/img/13mdpi.png" /> -->
								<span class="tou1-span3" @click="toNav(99)">立即充值</span>
							</div>
						</div>
						<!-- <div class="tou-right-bot">
								<span>上次登录</span>
								<span><i class="el-icon-location"></i>{{ site }}</span>
								<span><i class="el-icon-timer"></i>{{ time }}</span>
							</div> -->
					</div>
				</div>
				<div class="met-right">
					<div class="moneny">
						<img src="../assets/img/money.png" alt="">
						<div>
							<span>{{Data.total_amount}}</span>
						</div>
					</div>
					<div class="moneny">
						<img src="../assets/img/masonry.png" alt="">
						<div>
							<span>{{Data.masonry}}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="center-box">
				<div class="title">我的背包</div>
				<ul>
					<li @click="toNav(0,'我的背包')">
						<!-- <img src="../assets/img/index/beibao.png" alt=""> -->
						<span>我的背包</span>
					</li>
					<li @click="toNav(0,'提取中的饰品')">
						<!-- <img src="../assets/img/index/beibao.png" alt=""> -->
						<span>提取中的饰品</span>
					</li>
					<li @click="toNav(0,'待提取的饰品')">
						<!-- <img src="../assets/img/index/beibao.png" alt=""> -->
						<span>已提取的饰品</span>
					</li>
				</ul>
			</div>
			<div class="center-box">
				<div class="title">账户明细</div>
				<ul>
					<li @click="toNav(1,'充值记录')">
						<!-- <img src="../assets/img/index/beibao.png" alt=""> -->
						<span>充值记录</span>
					</li>
					<li @click="toNav(1,'兑换记录')">
						<!-- <img src="../assets/img/index/beibao.png" alt=""> -->
						<span>兑换记录</span>
					</li>
					<li @click="toNav(1,'账户变动')">
						<!-- <img src="../assets/img/index/beibao.png" alt=""> -->
						<span>账户明细</span>
					</li>
				</ul>
			</div>
			<div class="center-box">
				<div class="title">账户设置</div>
				<ul>
					<li @click="toNav(2)">
						<span>头像设置</span>
					</li>
					<li @click="toNav(2)">
						<span>昵称设置</span>
					</li>
					<li @click="toNav(2)">
						<span>修改密码</span>
					</li>
					<li @click="toNav(2)">
						<span>实名验证</span>
					</li>
					<li @click="toNav(2)">
						<span>steam交易链接</span>
					</li>
				</ul>
			</div>
			<div class="center-box">
				<div class="title">任务中心</div>
				<ul>
					<li @click="toNav(3,'签到福利')">
						<span>签到福利</span>
					</li>
					<li @click="toNav(3,'盲盒福利')">
						<span>盲盒福利</span>
					</li>
					<li @click="toNav(3,'对战福利')">
						<span>对战福利</span>
					</li>
					<li @click="toNav(3,'充值福利')">
						<span>充值福利</span>
					</li>
				</ul>
			</div>
			<div class="center-box">
				<div class="title">推广中心</div>
				<ul>
					<li class="classc">
						<div>
							<span>我的推广人:</span>
							<input type="text" :value="tuiguangren" />
						</div>
						<span class="button" @click="tuiguang">修改</span>
					</li>
				</ul>
			</div>
			<div class="center-box">
				<div class="title">全民推广福利</div>
				<div class="contents">
					<span>我的邀请链接:</span>
					<div class="r1">
						<input type="text" :value="valueUrl">
						<span class="button" @click="copyText(valueUrl)">复制</span>
					</div>
					<div class="r2">
						<div><span>我邀请的人数:</span> <img src="../assets/img/sp1.png" alt=""><span>{{ value1 }}</span>
						</div>
						<div><span>总充值:</span><img src="../assets/img/sp3.png"
								alt=""><span>{{ value3/(value2/10) }}</span></div>
					</div>
				</div>
			</div>
		</div>

		<span :plain="true">{{ urlHint }}</span>

		<!-- 邮箱弹框 -->
		<div class="hide-box">
			<div class=""></div>
		</div>
		<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
	</div>
</template>

<script>
	import Utils from "./../assets/js/util.js";
	import navheader from "@/components/navheader.vue";
	export default {
		inject: ["reload"],
		components: {
			navheader
		},
		props: ["yidongmenu", "showNav"],
		data() {
			return {
				inviteImg: "",
				inviteName: "",
				Data: {},
				site: "",
				time: "",
				url: "",
				url1: "http://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
				url2: "http://www.bilibili.com/video/BV15D4y1X79w/",
				urlState: true,
				checked1: false,
				checked2: false,
				flag: false,
				urlHint: "",
				valueUrl: 'https://www.csgokx.com',
				tuiguangren: '',
				valueCode: 'TRtqaetA',
				tableData1: [],
				value1: 0,
				value2: 0,
				value3: 0,
				dateList: [{
					name: '推广统计',
					state: false,
					val: 0
				}, ],
				payInfoData: {
					flag: true,
					type: '',
					checked2: false,
					checked1: false,
				}, // 充值提示
			};
		},
		mounted() {
			this.getList();
		},
		methods: {
			//挑战推广详情
			goLonger() {
				this.$router.push({
					path: `/SpreadLonger`,
				})
			},
			tuiguang() {
				let param = {
					invite_code: this.tuiguang,
				};
				this.$axios
					.post("index/User/bindInviter", this.$qs.stringify(param))
					.then((res) => {
						var data = res.data;
						console.log(data);
						if (data.status == "1") {
							this.$message({
								message: "绑定成功",
								type: "success",
							});
						} else {
							this.$message({
								message: "绑定失败，" + data.msg,
								type: "info",
							});
						}
					});
			},

			//复制
			copyText(text) {
				var input = document.createElement('input') // js创建一个input输入框
				input.value = text // 将需要复制的文本赋值到创建的input输入框中
				document.body.appendChild(input)
				input.select()
				document.execCommand('Copy')
				document.body.removeChild(input)
				this.$message({
					message: '复制成功',
					type: 'success',
				})
			},
			toNav(index, e) {
				if (index == 0) {
					this.$router.push({
						path: `/Dota`,
						query: {
							type: e,
						},
					});
				} else if (index == 1) {
					this.$router.push({
						path: `/Bill`,
						query: {
							type: e,
						},
					});
				} else if (index == 2) {
					this.$router.push({
						path: `/MeYiDong`
					});
				} else if (index == 3) {
					this.$router.push({
						path: `/task-center`,
						query: {
							type: e
						}
					});
				} else if (index == 99) {
					this.$router.push({
						path: `/Payment`
					});
				}
			},
			handleChange(file, fileList) {
				let _this = this;
				let formData = new FormData();
				formData.append('player_id', _this.$store.state.id);
				formData.append('file', file.raw);
				console.log(file)
				//--------------
				_this.$axios({
					url: "/index/User/editHeadImage",
					method: "post",
					dataType: 'json',
					data: formData,
					// processData: false,
					// contentType: false,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
					// headers:{'Content-Type':'application/x-www-form-urlencoded'}
				}).then((res) => {
					// console.log(res);
					if (res.data.status == 1) {
						_this.Data.img = res.data.data;
						Utils.$emit("img", _this.Data.img);
						_this.reload();
					}
				});
				// this.fileList = fileList.slice(-3);
			},
			//跟换头像
			getImg(response, file, fileList) {
				return
			},
			//更换昵称
			getName() {
				this.$prompt("请输入昵称", "昵称修改", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
					})
					.then(({
						value
					}) => {
						let param = {
							name: value,
						};
						this.$axios
							.post("/index/User/editNickname", this.$qs.stringify(param))
							.then((res) => {
								var data = res.data;
								// console.log(data);
								if (data.status == "1") {
									this.$message({
										message: "修改成功",
										type: "success",
									});
								} else {
									this.$message({
										message: "修改失败",
										type: "info",
									});
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "取消输入",
						});
					});
			},
			//邮箱绑定
			openEmail() {
				this.$prompt("请输入邮箱", "邮箱绑定", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
						inputErrorMessage: "邮箱格式不正确",
					})
					.then(({
						value
					}) => {
						// console.log(value);
						let param = {
							email: value,
						};
						this.$axios
							.post("/index/User/bindEmail", this.$qs.stringify(param))
							.then((res) => {
								var data = res.data;
								// console.log(data);
								if (data.status == "1") {
									this.$message({
										message: "绑定成功",
										type: "success",
									});
								} else {
									this.$message({
										message: "绑定失败",
										type: "info",
									});
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "取消输入",
						});
					});
			},

			//修改密码
			openPass() {
				this.$prompt("请输入密码", "密码修改", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
					})
					.then(({
						value
					}) => {
						let param = {
							password: value,
						};
						this.$axios
							.post("/index/User/editPass", this.$qs.stringify(param))
							.then((res) => {
								var data = res.data;
								// console.log(data);
								if (data.status == "1") {
									this.$message({
										message: "修改成功",
										type: "success",
									});
								} else {
									this.$message({
										message: "修改失败",
										type: "info",
									});
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "取消输入",
						});
					});
			},
			//绑定上级
			openTop() {
				this.$prompt("请输入邀请码", "绑定上级", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
					})
					.then(({
						value
					}) => {
						let param = {
							invite_code: value,
						};
						this.$axios
							.post("index/User/bindInviter", this.$qs.stringify(param))
							.then((res) => {
								var data = res.data;
								console.log(data);
								if (data.status == "1") {
									this.$message({
										message: "绑定成功",
										type: "success",
									});
									this.reload();
								} else {
									this.$message({
										message: "绑定失败，" + data.msg,
										type: "info",
									});
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "取消输入",
						});
					});
			},
			//个人中心信息
			getList() {
				let param = {};
				this.$axios
					.post("/index/User/playerInfo", this.$qs.stringify(param))
					.then((res) => {
						console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.Data = data.data;
							this.url = this.Data.tradeUrl;
							if (this.url) {
								this.flag = true;
							}
							this.tuiguangren = data.myInviter;
							if (!this.tuiguangren) {
								this.tuiguangren = '官方';
							}
							this.site = this.Data.last_login_info.position;
							this.time = this.Data.last_login_info.time;
							if (data.data.myInviter) {
								this.inviteImg = data.data.myInviter.img;
								this.inviteName = data.data.myInviter.name;
							}
						}
					});
				this.$axios
					.post('index/Invite/inviteInfo', this.$qs.stringify(param))
					.then((res) => {
						let data = res.data
						if (data.status == 1) {
							this.valueUrl = data.data.invite_url
							this.valueCode = data.data.invite_code
						}
					})
				this.$axios
					.post('index/Invite/offline', {
						days: 7
					})
					.then((res) => {
						let data = res.data
						if (data.status == 1) {
							//console.log(data.data.people_num)
							if (data.data.people_num) {
								this.value1 = data.data.people_num
							}
							if (data.data.ratio) {
								this.value2 = data.data.ratio
							}
							if (data.data.invite_commission) {
								this.value3 = data.data.invite_commission
							}
							this.tableData1 = data.data.list
							for (let i = 0; i < this.tableData1.length; i++) {
								if (this.tableData1[i].status == 1) {
									this.tableData1[i].state = '已结算'
								} else {
									this.tableData1[i].state = '未结算'
								}
							}
						}
					})
			},
			//复制用户ID 和 steamid
			copyText(text) {
				var input = document.createElement("input"); // js创建一个input输入框
				input.value = text; // 将需要复制的文本赋值到创建的input输入框中
				document.body.appendChild(input);
				input.select();
				document.execCommand("Copy");
				document.body.removeChild(input);
				this.$message({
					message: "复制成功",
					type: "success",
				});
			},
			//steam教程
			goUrl(url) {
				window.open(url, "_blank");
			},
			edit() {
				if (this.flag) {
					this.$message({
						message: "steam链接只能修改一次，请联系客服修改",
						type: "error",
					});
					return
				}
				this.urlState = false;

			},
			off() {
				this.urlState = true;
			},
			confirm() {
				//console.log(this.url);
				let param = {
					tradeUrl: this.url,
				};
				this.$axios
					.post("/index/User/bindSteam", this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
							this.urlState = true;
							this.flag = true;
						} else {
							this.$message({
								showClose: true,
								message: data.msg,
								type: "error",
							});
							this.urlState = true;
							// this.url = '';
						}
					});
			},
		},
	};
</script>

<style lang="less" scoped>
	.me {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		padding-bottom: 100px;
		// background: rgba(78, 128, 137, 1);
		background: url('../assets/img/my/back.png') no-repeat;
		background-size: 100%;
		// background-color: #1a1c24;
		// background-color: #06090f;

		.center-box {
			padding: 0 16px;
			color: white;

			.title {
				// font-weight: 600;
				font-size: 14px;
				padding: 24px 0;
			}

			.title1 {
				// font-weight: 600;
				font-size: 10px;
				padding: 24px 0;
			}

			.contents {
				display: flex;
				flex-direction: column;
				font-size: 12px;

				.r1 {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.r2 {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					div {
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}

					div:last-child {
						margin-left: 100px;
					}
				}

				input {
					margin: 6px 0;
					padding: 0 4px;
					background: rgba(27, 77, 98, 1);
					border: none;
					width: 70%;
					border-radius: 4px;
					height: 35px;
					line-height: 35px;
					color: rgba(161, 172, 189, 1);
				}

				.button {
					width: 70px;
					height: 30px;
					background-repeat: no-repeat;
					font-weight: 400;
					font-size: 13px;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 12px;
					line-height: 25px;
					background-image: url('../assets/img/my/button.png');
					background-size: 100% 100%;
				}
			}

			ul {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				.classc{
					
					justify-content: space-between;
				}
				li {
					display: flex;
					justify-content: center;
					align-items: center;
					// width: 100%;
					div {
						width: 70%;
						// margin: ;
					}

					img {
						width: 16px;
						margin-right: 6px;
					}

					span {
						font-size: 10px;
					}

					input {
						padding: 0 4px;
						margin-left: 12px;
						width: 100px;
						padding: 0 4px;
						background: rgba(27, 77, 98, 1);
						border: none;
						border-radius: 4px;
						height: 30px;
						line-height: 30px;
						color: white;
					}

					.button {
						width: 70px;
						height: 30px;
						background-repeat: no-repeat;
						font-weight: 400;
						font-size: 13px;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-left: 12px;
						line-height: 25px;
						background-image: url('../assets/img/my/button.png');
						background-size: 100% 100%;
					}
				}
			}

		}


		.me-warp {
			// padding: 16px;
		}

		.me-title1 {
			padding: 16px 0;
			color: #c3c3e2;
			font-size: 20px;
		}

		.me-title {
			padding: 16px 0;
			color: #c3c3e2;
			font-size: 16px;
		}

		.me-tou {
			padding: 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			// background-color: #2b2c37;
			border-radius: 5px;
			justify-content: space-between;

			.met-left {
				display: flex;
				align-items: center;
			}

			.met-right {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: space-between;
				height: 60px;
				margin-bottom: 10px;


				.moneny {
					display: flex;
					// height: 30px;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					min-width: 80px;
					font-size: 12px;
					font-family: '微软雅黑';

					span {
						margin-left: 10px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						text-align: left;
						color: white;
					}

					span:first-child {
						font-weight: 400;
						color: white;
						line-height: 16px;
						margin-bottom: 2px;
					}

					img {
						width: 20px;
						height: 20px;
					}
				}
			}

			.tou-left {
				width: 90px;
				// position: relative;
				// border-radius: 50%;

				img {
					width: 80px;
					height: 80px;
					border-radius: 50%;
				}

				span {
					// position: absolute;
					// bottom: 0;
					// left: 0;
					width: 100%;
					text-align: center;
					height: 30px;
					line-height: 30px;
					font-size: 14px;
					font-weight: 600;
					color: #848492;
					background-color: rgba(0, 0, 0, 0.7);

					.upload-demo {
						width: 80px;

						/deep/ .el-upload {
							width: 100%;

							.btn {
								width: 100%;
								color: #848492;
								background-color: rgba(0, 0, 0, 0.7);
								border-color: rgba(0, 0, 0, 0.7);
							}
						}
					}
				}

				span:hover {
					cursor: pointer;
				}
			}

			.tou-right {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-bottom: 24px;

				.tou-right-top {
					.tou1 {
						display: flex;
						align-items: center;

						img {
							width: 20px;
							height: 20px;
						}

						.tou1-span1 {
							font-size: 14px;
							font-weight: 400;
							color: #c3c3e2;
						}

						.tou1-span3 {
							width: 90px;
							height: 34px;
							background-repeat: no-repeat;
							font-weight: 400;
							font-size: 13px;
							color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							line-height: 25px;
							background-image: url('../assets/img/my/button.png');
							background-size: 100% 100%;
						}

						.tou1-span2 {
							background-color: #858493;
							border-radius: 3px;
							font-size: 12px;
							padding: 0 8px;
						}

						i {
							margin-left: 15px;
							font-size: 20px;
							color: #858493;
						}

						i:hover {
							cursor: pointer;
							color: #e9b10e;
						}
					}

					.tou2 {
						display: flex;
						align-items: center;
						font-size: 14px;
						color: #848492;

						.tou2-name {
							margin-right: 20px;

							i:hover {
								cursor: pointer;
								color: #e9b10e;
							}
						}
					}
				}

				.tou-right-bot {
					padding-bottom: 10px;
					// display: flex;
					// float: left;
					display: flex;
					justify-content: space-between;

					span {
						color: #848492;
						font-size: 14px;
					}

					span:first-child {
						color: #c3c3e2;
					}
				}
			}
		}

		.url {
			padding: 16px;
			background-color: #2b2c37;
			border-radius: 5px;

			.url1 {
				font-size: 14px;
				color: #848492;
			}

			.url2 {
				margin-top: 20px;
				display: flex;

				.url2-input {
					width: 380px;
					min-width: 100px;

					input {
						height: 40px;
						line-height: 40px;
						padding: 0 10px;
						border-radius: 5px;
						width: 100%;
						color: #c3c3e2;
						font-size: 12px;
						background-color: #24252f;
						outline: none;
						box-sizing: border-box;
						border: none;
					}
				}

				.url2-btn {
					span {
						display: inline-table;
						height: 40px;
						margin-left: 20px;
						line-height: 40px;
						padding: 0 20px;
						background-color: #e9b10e;
						border-radius: 5px;
						font-weight: 600;
						color: #1a1c24;
						font-size: 15px;
						white-space: nowrap;
					}

					span:hover {
						cursor: pointer;
						background-color: #f5c432;
					}

					.url2-btn {
						background-color: #333542;
						color: #848492;
					}

					.url2-btn:hover {
						background-color: #3a3f50;
					}
				}
			}

			.url3 {
				margin-top: 20px;

				div {
					margin-right: 20px;
					font-size: 14px;
					color: #c3c3e2;

					strong {
						color: #e9b10e;
					}

					strong:hover {
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}
		}

		.invite {
			padding: 16px;
			background-color: #2b2c37;
			border-radius: 5px;

			.invite-list {
				display: flex;
				align-items: center;

				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}

				span {
					margin-left: 20px;
					font-size: 16px;
					color: #848492;
				}
			}

			.invite-list1 {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #c3c3e2;

				span {
					font-weight: 600;
					color: #e9b10e;
					margin-left: 10px;
				}

				span:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}

		.number {
			padding: 10px 30px;
			background-color: #2b2c37;
			border-radius: 5px;

			.number1 {
				padding: 15px 0;
				display: flex;
				justify-content: space-between;
				color: #848492;
				font-size: 16px;

				i {
					font-size: 20px;
				}

				i:hover {
					cursor: pointer;
					color: #e9b10e;
				}

				.number-span:hover {
					cursor: pointer;
				}
			}
		}

		.hobby {
			padding: 10px 30px;
			background-color: #2b2c37;
			border-radius: 5px;
			display: flex;
			flex-direction: column;

			.hobby1 {
				padding: 15px 0;
			}
		}
	}
</style>