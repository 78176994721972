<template>
	<div class="hist">
		<div class="roomleft-num">
			<h6 style="margin-left: 12px;font-size: 20px;color: #c3c3e2;">最近掉落</h6>
		</div>

		<div class="boxlist-botsm" >
			<ul>
				<li v-for="(item, index) in tableData" class="card" :key="index">
					<div class="boxlist-warp">
						<div class="boxlist1-top">
							<img :src="item.img" />
						</div>
						<div class="boxlist1-bot1">
							{{item.probability}}%
						</div>
						<div class="boxlist1-top">
							<img :src="item.imageUrl" />
							<span :class="item.status == '成功'? 'select':''">{{item.skins}}</span>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<!-- <div class="hist-warp"> -->
		<!-- <div class="roomleft-num">
				<h5 @click="goLucky">幸运饰品</h5>
				>
				<h6>获得饰品记录</h6>
			</div> -->

		<!-- <div class="hist-list">
				<el-tabs type="border-card">
					<el-tab-pane label="获得饰品记录">
						<div class="bot">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="time" label="时间" width="180">
								</el-table-column>
								<el-table-column prop="name" label="玩家" width="150">
								</el-table-column>
								<el-table-column prop="skins" label="饰品名称" width="300">
								</el-table-column>
								<el-table-column prop="price" label="价格"> </el-table-column>
								<el-table-column prop="probability" label="选择概率">
								</el-table-column>
								<el-table-column prop="status" label="结果"> </el-table-column>
							</el-table>
						</div>
						<div class="roll-page">
							<el-pagination background layout="prev, pager, next" :total="totalSize"
								:page-size="pageSize" @current-change="currentChange">
							</el-pagination>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div> -->
		<!-- </div> -->

	</div>
</template>

<script>
	import {
		parse
	} from "qs";
	export default {
		data() {
			return {
				totalSize: 0,
				page: 1,
				pageSize: 20,
				tableData: [],
				totalSize: 0,
			};
		},
		mounted() {
			this.getluckyhist(this.page);
		},
		methods: {
			getluckyhist(page) {
				let param = {
					page: page,
					pageSize: this.pageSize,
				};
				this.$axios
					.post("/index/Lucky/LuckyHistory2", this.$qs.stringify(param))
					.then((res) => {
						//  console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.totalSize = data.data.total;
							this.tableData = data.data.list;
						}
					});
			},
			//分页 所有记录
			currentChange(val) {
				this.getluckyhist(val);
			},
			goLucky() {
				this.$router.push({
					path: `/Ornament`,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.hist {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		// background-color: #1a1c24;

		.hist-warp {
			padding: 16px;
		}

		//tabs
		.hist-list {
			margin-top: 20px;
		}

		.hist-list /deep/ .el-tabs--border-card {
			//overflow: hidden;
			background-color: #2b2c37;
			border: none;
			// border-radius: 5px;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header {
			border: none;
			background-color: #1a1c24;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
			background-color: #2b2c37;
			border: none;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
			background-color: #24252f;
			border: none;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
			color: #e9b10e;
		}

		//页数
		.roll-page {
			margin: 10px 0 0 -10px;
		}

		.roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #e9b10e;
			color: #1a1c24;
		}

		.roll-page /deep/ .el-pagination.is-background .btn-next,
		/deep/ .el-pagination.is-background .btn-prev,
		/deep/ .el-pagination.is-background .el-pager li {
			background-color: #333542;
			color: #848492;
		}

		//表格
		.bot /deep/ .el-table th,
		.bot /deep/ .el-table tr {
			background-color: #2b2c37;
		}

		.bot /deep/ .el-table td,
		.bot /deep/ .el-table th.is-leaf {
			border-bottom: 1px solid #444659;
		}

		.bot /deep/ .el-table::before {
			height: 0;
		}

		.bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
			background-color: #212e3e !important;
		}

		.bot /deep/ .cell {
			padding: 0;
			height: 60px;
			line-height: 60px;
		}

		.bot /deep/ .el-table__empty-block {
			background-color: #2b2c37;
		}

		.roomleft-num {
			display: flex;
			align-items: center;
			color: #848492;
			font-size: 16px;

			h5 {
				font-size: 16px;
				font-weight: 200;
			}

			h5:hover {
				cursor: pointer;
				color: #e9b10e;
				text-decoration: underline;
			}

			h6 {
				font-weight: 200;
				color: #c3c3e2;
				font-size: 16px;
			}
		}
	}

	.boxlist-botsm {
		// zoom: 0.64;
		padding: 0 0px;

		ul {
			width: 100%;

			li {
				width: 100%;
				// background-size: 100% 100%;
				// background-repeat: no-repeat;
				// cursor: pointer;
				height: 50px;
				background: rgba(22, 26, 37, 1);
				border-radius: 3px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 12px;

				.boxlist-warp {
					// background-color: #24252f;
					width: 100%;
					margin: 12px;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.boxlist1-top {
						padding: 0 20px;
						text-align: center;
						border-radius: 8px;
						display: flex;
						align-items: center;
						.select{
							color: #f1a920 !important;
						}
						// border-bottom: 3px solid #f1a920;

						// box-shadow: 0px 5px 10px #f1a920;
						img {
							width: 24px !important;
							border-radius: 8px;
							height: auto;
						}

						span {
							width: 130px;
							color: white;
							margin-left: 8px;
							color: #c3c3e2;
							font-size: 14px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}

					.boxlist1-top:last-child {
						display: flex;
						margin-left: 0 !important;

						img {
							width: 32px !important;
							border-radius: 8px;
							height: auto;
						}
					}

					.boxlist1-bot {
						padding: 8px;
						color: #c3c3e2;
						text-overflow: ellipsis;
						overflow: hidden;
						font-size: 10px;
						white-space: nowrap;
						// word-wrap: break-word;
					}

					.boxlist1-bot1 {
						width: 80px;
						padding: 8px;
						display: flex;
						align-items: center;
						margin-left: 20px !important;
						color: white !important;

						img {
							width: 14px;
							height: 14px;
							border-radius: 50%;
							margin-right: 6px !important;
						}

						span {
							margin-left: 8px;
							font-size: 14px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}

					.list-pirce {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.ico {
						position: absolute;
						top: 0;
						left: 0;
						font-size: 12px;
						color: #04f540;
						background-color: rgba(0, 0, 0, 0.5);
						padding: 2px 4px;
					}

					.pirce-left {
						flex: 1;
						display: flex;
						align-items: center;
						padding-right: 10px;

						img {
							width: auto;
							height: 15px;
							margin-right: 5px;
						}

						&>div {
							flex: 1;
							color: #f4bd50;
							font-size: 10px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							span:last-child {
								color: #c3c3e2;
							}
						}
					}

					.pirce-right {
						color: #848492;
						font-size: 10px;
					}
				}
			}
		}
	}
</style>