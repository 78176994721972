<template>
	<div class="hist">
		<div class="hist-warp">
			<div class="hist-list">
				<el-tabs type="border-card" v-model="activeName" @tab-click="getTab">
					<el-tab-pane label="充值记录" name="one">
						<div class="bot">
							<el-table :data="tableData" style="width: 100%" :cell-style="columnStyle">
								<el-table-column prop="create_time" label="日期" width="180">
								</el-table-column>
								<el-table-column prop="money" label="充值金额" width="100">
								</el-table-column>
								<el-table-column prop="statusName" label="充值结果" width="100">
								</el-table-column>
								<el-table-column prop="order_num" label="订单号" width="260">
								</el-table-column>
							</el-table>
						</div>
						<div class="roll-page">
							<el-pagination background layout="prev, pager, next" :total="totalSize"
								:page-size="pageSize" @current-change="currentChange">
							</el-pagination>
						</div>
					</el-tab-pane>
					<el-tab-pane label="兑换记录" name="two">
						<div class="bot">
							<el-table :data="tableData1" style="width: 100%" :cell-style="columnStyle1">
								<el-table-column prop="create_time" label="日期" width="180">
								</el-table-column>
								<el-table-column prop="price" label="收支" width="100">
									<template slot-scope="scope">
										{{scope.row.way != 5 ? `+${scope.row.price}`:`-${scope.row.price}`}}
									</template>
								</el-table-column>
								<el-table-column prop="name" label="饰品名称" width="300">
								</el-table-column>
								<el-table-column prop="img" label="饰品图片">
									<template slot-scope="scope">
										<img :src="scope.row.img" alt="" style="width: 45px;height: 100%;" />
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="roll-page">
							<el-pagination background layout="prev, pager, next" :total="totalSize1"
								:page-size="pageSize" @current-change="currentChange1">
							</el-pagination>
						</div>
					</el-tab-pane>

					<el-tab-pane label="账户明细" name="three">
						<div class="bot">
							<el-table :data="tableData2" style="width: 100%" :cell-style="columnStyle2">
								<el-table-column prop="create_time" label="日期">
								</el-table-column>
								<el-table-column prop="state" label="类型" :width="150" show-overflow-tooltip> </el-table-column>
								<!-- <el-table-column prop="pay" label=""> </el-table-column> -->
								<el-table-column prop="amount" label="收支">
									<template slot-scope="scope">
										{{scope.row.amount > 0 ? `+${scope.row.amount}`:`${scope.row.amount}`}}
									</template>
								</el-table-column>
								<el-table-column prop="total_amount" label="变动前">
									<template slot-scope="scope">
										{{(parseFloat(scope.row.total_amount)- parseFloat(scope.row.amount)).toFixed(2)}}
									</template>
								</el-table-column>
								<el-table-column prop="total_amount" label="变动后">
								</el-table-column>
							</el-table>
						</div>
						<div class="roll-page">
							<el-pagination background layout="prev, pager, next" :total="totalSize2"
								:page-size="pageSize" @current-change="currentChange2">
							</el-pagination>
						</div>
					</el-tab-pane>




				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: "one",
				totalSize: 0,
				page: 1,
				pageSize: 10,
				totalSize1: 0,
				totalSize2: 0,
				totalSize3: 0,
				tableData: [],
				tableData1: [],
				tableData2: [],
				tableData3: [],
			};
		},
		mounted() {
			this.getTopupList(this.page);
			if (this.$route.query.type == '分解记录') {
				this.getFenJieList(this.page);
				this.activeName = 'fore';
			}
			if (this.$route.query.type == '账户变动') {
				this.getBalanceList(this.page);
				this.getShoppList(this.page);
				this.activeName = 'three';
			}
			if (this.$route.query.type == '兑换记录') {
				this.getBalanceList(this.page);
				this.getShoppList(this.page);
				this.activeName = 'two';
			}
		},
		methods: {
			columnStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 2) {
					//第三第四列的背景色就改变了2和3都是列数的下标
					return "color: #e9b10e ;";
				}
			},
			columnStyle1({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 1) {
					//第三第四列的背景色就改变了2和3都是列数的下标
					return "color: #e9b10e ;";
				}
			},
			columnStyle2({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 4) {
					//第三第四列的背景色就改变了2和3都是列数的下标
					return "color: #e9b10e ;";
				}
				if (columnIndex == 3) {
					return "color: #c3c3e2 ;";
				}

				if (columnIndex == 1) {
					if (row.pay == "支出") {
						return "color:  #c3c3e2 ;";
					} else {
						return "color:#02bf4d;";
					}
				}

			},
			columnStyle3({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 4) {
					//第三第四列的背景色就改变了2和3都是列数的下标
					return "color: #c3c3e2 ;";
				}
				if (columnIndex == 3) {
					return "color: #e9b10e ;";
				}

				if (columnIndex == 1) {
					// if (row.pay == "支出") {
						return "color:  #02bf4d ;";
					// } else {
					// 	return "color:#c3c3e2;";
					// }
				}

			},
			getTab(tab) {
				//console.log(tab.name);
				if (tab.name == "one") {} else if (tab.name == "two") {
					this.getShoppList(this.page);
				} else if ((tab.name == "three")) {
					this.getBalanceList(this.page);
				} else {
					this.getFenJieList(this.page);
				}
			},
			getTopupList(page) {
				let param = {
					page: page,
					pageSize: this.pageSize,

				};
				this.$axios
					.post("index/User/recharge", this.$qs.stringify(param))
					.then((res) => {
						//console.log(res.data);
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.totalSize = data.data.total;
							this.tableData = data.data.list;
							for (let i = 0; i < this.tableData.length; i++) {
								if (this.tableData[i].mode == "zhifubao") {
									this.tableData[i].modeName = "支付宝";
								} else if (this.tableData[i].mode == "jdpay") {
									this.tableData[i].modeName = "支付宝";
								} else {
									this.tableData[i].modeName = "微信";
								}

								if (this.tableData[i].status == 1) {
									this.tableData[i].statusName = "未支付";
								} else if (this.tableData[i].status == 2) {
									this.tableData[i].statusName = "待支付";
								} else if (this.tableData[i].status == 3) {
									this.tableData[i].statusName = "支付成功";
								} else {
									this.tableData[i].statusName = "支付失败";
								}
							}
						}
					});
			},

			getShoppList(page) {
				let param = {
					page: page,
					pageSize: this.pageSize,

				};
				this.$axios
					.post("index/User/skinshistory1", this.$qs.stringify(param))
					.then((res) => {
						//console.log(res.data);
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.totalSize1 = data.data.total;
							this.tableData1 = data.data.list;
						}
					});
			},
			getFenJieList(page) {
				let param = {
					page: page,
					pageSize: this.pageSize,

				};
				this.$axios
					.post("index/User/exchange_history", this.$qs.stringify(param))
					.then((res) => {
						//console.log(res.data);
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.totalSize3 = data.data.total;
							this.tableData3 = data.data.skins_history;
						}
					});
			},
			getBalanceList(page) {
				let param = {
					page: page,
					pageSize: this.pageSize,

				};
				this.$axios
					.post("index/User/balanceDetail", this.$qs.stringify(param))
					.then((res) => {
						//console.log(res.data);
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.totalSize2 = data.data.total;
							this.tableData2 = data.data.list;

							for (let i = 0; i < this.tableData2.length; i++) {
								if (Number(this.tableData2[i].amount) >= 0) {
									this.tableData2[i].pay = "收入";
								} else {
									this.tableData2[i].pay = "支出";
								}

								if (this.tableData2[i].type == 1) {
									this.tableData2[i].state = "皮肤兑换G币";
								} else if (this.tableData2[i].type == 2) {
									this.tableData2[i].state = "福利赠送";
								} else if (this.tableData2[i].type == 3) {
									this.tableData2[i].state = "充值";
								} else if (this.tableData2[i].type == 4) {
									this.tableData2[i].state = "对战失败";
								} else if (this.tableData2[i].type == 5) {
									this.tableData2[i].state = "对战存在多个平局赢家平分输家的钱";
								} else if (this.tableData2[i].type == 7) {
									this.tableData2[i].state = "幸运饰品失败";
								} else if (this.tableData2[i].type == 8) {
									this.tableData2[i].state = "cdk兑换";
								} else if (this.tableData2[i].type == 10) {
									this.tableData2[i].state = "下级充值推广佣金";
								} else if (this.tableData2[i].type == -1) {
									this.tableData2[i].state = "购买盲盒";
								} else if (this.tableData2[i].type == -2) {
									this.tableData2[i].state = "加入对战房间";
								} else if (this.tableData2[i].type == -3) {
									this.tableData2[i].state = "购买幸运饰品";
								} else if (this.tableData2[i].type == -4) {
									this.tableData2[i].state = "商城购买饰品";
								}else if (this.tableData2[i].type == -6) {
									this.tableData2[i].state = "钻石兑换c币";
								}
							}
						}
					});
			},

			//充值流水 页数
			currentChange(val) {
				this.getTopupList(val);
			},

			//玩家商城 分页
			currentChange1(val) {
				this.getShoppList(val);
			},

			//余额流水 分页
			currentChange2(val) {
				this.getBalanceList(val);
			},
			//余额流水 分页
			currentChange3(val) {
				this.getFenJieList(val);
			},

			goLucky() {
				this.$router.push({
					path: `/Lucky`,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.hist {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;

		.hist-warp {}

		//tabs
		.hist-list {
			margin-top: 12px;
		}

		.hist-list /deep/ .el-tabs--border-card {
			// background-color: #2b2c37;
			background-color: rgba(16, 36, 48, 1);
			border: none;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header {
			border: none;
			background-color: rgba(16, 36, 48, 1);
		}

		/deep/.cell {
			text-align: center;
			height: 35px;
			line-height: 35px;
		}
		/deep/.el-tab-pane{
			min-height: 65vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		/deep/.el-table__header th {
			background-color: rgba(16, 36, 48, 1) !important;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
			background-color: rgba(27, 77, 98, 1);
			border: none;
		}

		/deep/.el-tabs__nav {
			width: 100%;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
			// background-color: #24252f;
			background-color: rgba(24, 59, 79, 1);
			height: 35px;
			line-height: 35px;
			width: 33.33%;
			font-size: 12px;
			text-align: center;
			border: none;
			padding: 0 12px;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
			color: #e9b10e;
		}

		//页数
		.roll-page {
			// margin: 10px 0 0 -10px;
			margin-top: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #e9b10e;
			color: #1a1c24;
		}

		.roll-page /deep/ .el-pagination.is-background .btn-next,
		/deep/ .el-pagination.is-background .btn-prev,
		/deep/ .el-pagination.is-background .el-pager li {
			background-color: #333542;
			color: #848492;
		}

		//表格
		.bot /deep/ .el-table th,
		.bot /deep/ .el-table tr {
			background-color: rgba(16, 36, 48, 1) !important;
		}

		.bot /deep/ .el-table td,
		.bot /deep/ .el-table th.is-leaf {
			border-bottom: 1px solid #444659;
		}

		.bot /deep/ .el-table::before {
			height: 0;
		}

		.bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
			background-color: #212e3e !important;
		}

		/*.bot /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/

		.roomleft-num {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h5 {
				font-size: 20px;
				font-weight: 400;
				color: #c3c3e2;
			}

			h6 {
				font-weight: 200;
				color: #848492;
				font-size: 16px;

				span {
					color: #17b4ed;
				}

				span:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
</style>