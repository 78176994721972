<template>
	<div class="oran">
		<div class="oran-warp">
			<div class="oran-top">
				<div class="orantop-left">幸运饰品</div>
				<!-- 暂不开放 -->
				<div class="orantop-right" @click="goHistory">最近掉落</div>
			</div>

			<div class="oran-sel">
				<div class="sel-top">
					<ul>
						<li v-for="(item, index) in selList" :key="index" @click="selOran(item.status, item.id)">
							<div :class="item.state ? 'seltop-warp seltop-warp1' : 'seltop-warp'">
								<img :src="item.img" />
								<span>{{ item.name }}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="clear"></div>

				<div class="class-box">
					<!-- 子类 -->
				<!-- 	<div class="class-list">
						<ul>
							<li v-for="(item, index) in classList" :key="index">
								<span :class="item.state ? '' : 'span2'" @click="selClass(item)">{{ item.name }}</span>
							</li>
						</ul>
					</div> -->
					<div class="sel-bot">
						<!-- <div class="selbot-left">
							<span class="span1">价格从高到低</span>
						</div> -->
						<div class="selbot-right">

							<!-- <el-input placeholder="最低金额" v-model="pirce1" class="input">
								<img src="../assets/img/money.png" slot="prefix" />
							</el-input>
							<span class="span">~</span>
							<el-input placeholder="最高金额" v-model="pirce2" class="input">
								<img src="../assets/img/money.png" slot="prefix" />
							</el-input> -->
							<!-- <div class="pirec-btn">确定</div> -->
							<el-input placeholder="按名称搜索" v-model="searchKey"
								style="width: calc(100% - 86px);max-width: 280px; margin-right: 12px;" class="input1">
								<i slot="suffix" class="el-input__icon el-icon-search input1-i" @click="getList"></i>
							</el-input>
							<div class="shengjiang" @click="onHandledSort">
								<span style="font-weight: bold">价格</span>
								<img src="../assets/img/s1.png" v-if="sortType == 'desc'" alt="">
								<img src="../assets/img/s2.png" v-if="sortType == 'asc'" alt="">
								<img src="../assets/img/s3.png" v-if="sortType == ''" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="oran-list">
				<div class="roll-list">
					<ul>
						<li v-for="(item, index) in list" :key="index" @click="goOrnamentOpen(item)">
							<div class="list-warp">
								<!-- list-bor -->
								<div :class="item.state ? '' : ''"></div>
								<span class="ico">{{ item.exteriorName }}</span>
								<!-- <span v-if="item.state" class="buy-state">
									<img src="../assets/img/gou.png" />
								</span> -->
								<div class="list-img">
									<img :src="item.imageUrl" />
									<div class="bot">
										<div class="list-name" :title="item.itemName">
											{{ item.itemName }}
										</div>
										<div class="list-pirce">
											<div class="pirce-left">
												<img src="../assets/img/money.png" />
												<span>{{ item.price }}</span>
											</div>
											<!-- <div class="pirce-right">库存 {{ item.stock }}</div> -->
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<!-- <div class="roll-list">
					<ul>
						<li v-for="(item, index) in list" :key="index">
							<div class="list-warp">
								<div :class="item.state ? 'list-bor' : ''"></div>
								<span class="ico">{{ item.exteriorName }}</span>
								<div class="list-img">
									<img :src="item.imageUrl" />
								</div>
								<div class="bot-1">
									<div class="list-name" :title="item.itemName">{{ item.itemName }}</div>
									<div class="list-pirce">
										<div class="pirce-left">
											<img src="../assets/img/money.png" />
											<span>{{ item.price }}</span>
										</div>
										<div class="pirce-right"></div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div> -->
				<div class="clear"></div>
			</div>
		</div>
		<div class="clear"></div>
		<div class="myhomebot-mag"></div>
		<myhomebot></myhomebot>
		<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
	</div>
</template>

<script>
	import myslide from "@/components/my_slide1.vue";
	import myhomebot from "@/components/my_homebot.vue";
	import navheader from "@/components/navheader.vue";
	import myinform from "@/components/my_inform.vue";
	export default {
		components: {
			myslide,
			myhomebot,
			myinform,
			navheader
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				pirce1: "",
				pirce2: "",
				search: "",
				page: 1,
				pageSize: 100,
				list: [],
				classList: [],
				selList: [],
				listSlide: [],
				classObj: [{
					name: "全部",
					flag: 0,
					skin_type_id: "",
					state: true
				}],

				// 0 未排序  desc反序，ace正序
				sortType: "",
				// 关键词
				searchKey: "",
				// 品质
				pzList: [],
				// 外观
				wgList: [],
				// 品质选中
				rarity: "不限",
				// 外观选中
				exterior: "不限",
				// 主分类id
				classId: "1",
				// 小分类id
				subClassId: "all",
				// 推荐
				recommend: 1,
			};
		},
		watch: {
			searchKey: {
				handler(value) {
					if (value.length) return;
					this.getList();
				},
			},

			// 监听一级分类选中获取二级分类
			classId: {
				handler(value) {
					if (!value) return;
					this.classList = [{
						name: "全部",
						id: "all",
					}, ];
					this.subClassId = "all";
					this.$axios
						.post(
							"/index/Lucky/subclassList",
							this.$qs.stringify({
								type_id: value
							})
						)
						.then((res) => {
							if (res.data.status) {
								this.classList.push(...res.data.data.list);
								this.subClassId = this.classList[0].id;
							}
						});
				},
			},
		},
		mounted() {
			this.getList();
			this.getListClass();
			this.getTagAndPz();
		},
		methods: {
			getTagAndPz() {
				this.$axios
					.post("/index/Store/exterior", this.$qs.stringify({}))
					.then((res) => {
						const {
							exterior,
							rarity
						} = res.data.data;
						this.pzList = ["不限"].concat(rarity.map((item) => item.rarityName));
						this.wgList = ["不限"].concat(
							exterior.map((item) => item.exteriorName)
						);
					});
			},
			onHandledSort() {
				if (this.sortType == "") {
					this.sortType = "asc";
				} else if (this.sortType == "asc") {
					this.sortType = "desc";
				} else {
					this.sortType = "";
				}
				this.getList();
			},
			//分类
			getListClass() {
				this.$axios.post("/index/Lucky/luckyTypeList").then((res) => {
					let data = res.data;
					if (data.status == 1) {
						this.selList = data.data;
						for (let i = 0; i < this.selList.length; i++) {
							this.selList[i].state = false;
						}
						this.selList[0].state = true;
					}
				});
			},
			//默认推荐列表
			getList() {
				let param = {
					page: this.page,
					pageSize: this.pageSize,
					// recommend: this.recommend,
				};
				if (this.sortType) {
					param["sort"] = this.sortType;
				}
				if (this.searchKey) {
					param["searchKey"] = this.searchKey;
				}

				if (this.classId && this.classId != "all") {
					param["type_id"] = this.classId;
				}
				if (this.subClassId && this.subClassId != "all") {
					param["subclass_id"] = this.subClassId;
				}
				if (this.rarity && this.rarity != "不限") {
					param["rarity"] = this.rarity;
				}
				if (this.exterior && this.exterior != "不限") {
					param["exterior"] = this.exterior;
				}

				if (this.classId == '1') {
					param["recommend"] = 1;
					delete param.type_id
				}

				this.$axios
					.post("/index/Lucky/skinList", this.$qs.stringify(param))
					.then((res) => {
						let data = res.data;
						this.list = data.data.list;
						//分类中小分类
						let param = {
							type_id: "1",
						};
						this.$axios
							.post("/index/Lucky/subclassList", this.$qs.stringify(param))
							.then((res) => {
								let data = res.data;
								if (data.status == 1) {
									if (data.data.list.length != 0) {
										for (let i = 0; i < data.data.list.length; i++) {
											this.classObj.push(data.data.list[i]);
										}
									}
									this.classList = this.classObj;
								}
							});
					});
			},
			//点击分类获取列表
			selOran(status, id) {
				console.log(status, id);
				this.recommend = status == 2 ? 1 : 2;
				this.sortType = "";
				this.classId = id;
				for (let i = 0; i < this.selList.length; i++) {
					if (id == this.selList[i].id) {
						this.selList[i].state = true;
					} else {
						this.selList[i].state = false;
					}
				}

				if (status == 2 && id == 1) {
					var param = {
						page: this.page,
						pageSize: this.pageSize,
						recommend: 1,
						rarity: this.rarity != "不限" ? this.rarity : "",
						exterior: this.exterior != "不限" ? this.exterior : "",
					};
					var param1 = {
						type_id: "1",
					};
				}
				if (status == 2 && id == 2) {
					var param = {
						page: this.page,
						pageSize: this.pageSize,
						type_id: id,
						rarity: this.rarity != "不限" ? this.rarity : "",
						exterior: this.exterior != "不限" ? this.exterior : "",
					};
					var param1 = {
						type_id: id,
					};
				}
				if (status == 1) {
					var param = {
						page: this.page,
						pageSize: this.pageSize,
						type_id: id,
						rarity: this.rarity != "不限" ? this.rarity : "",
						exterior: this.exterior != "不限" ? this.exterior : "",
					};
					var param1 = {
						type_id: id,
					};
				}

				// console.log(param, param1);

				//列表数据
				// this.$axios
				//   .post("/index/Lucky/skinList", this.$qs.stringify(param))
				//   .then((res) => {
				//     let data = res.data;
				//     //  console.log(res.data);
				//     if (data.status == "1") {
				//       this.list = data.data.list;
				//     } else {
				//       this.list = [];
				//     }
				//   });
				this.getList();
				this.$forceUpdate();
			},
			//点击小分类
			selClass(item) {
				console.log(item);
				this.subClassId = item.id;
				for (let i = 0; i < this.classList.length; i++) {
					if (item.id == this.classList[i].id) {
						this.classList[i].state = true;
					} else {
						this.classList[i].state = false;
					}
				}
				if (item.flag == 0) {
					if (this.classId == 1) {
						var param = {
							page: this.page,
							pageSize: this.pageSize,
							recommend: 1,
						};
					} else {
						var param = {
							page: this.page,
							pageSize: this.pageSize,
							type_id: item.skin_type_id,
						};
					}
				} else {
					if (this.classId == 1) {
						var param = {
							page: this.page,
							pageSize: this.pageSize,
							recommend: 1,
							subclass_id: item.id,
						};
					} else {
						var param = {
							page: this.page,
							pageSize: this.pageSize,
							type_id: item.skin_type_id,
							subclass_id: item.id,
						};
					}
				}

				//console.log(param);
				//列表数据
				// this.$axios
				//   .post("/index/Lucky/skinList", this.$qs.stringify(param))
				//   .then((res) => {
				//     let data = res.data;
				//     if (data.status == "1") {
				//       this.list = data.data.list;
				//     } else {
				//       this.list = [];
				//     }
				//   });
				this.getList();
			},
			goOrnamentOpen(item) {
				console.log(item);
				this.$router.push({
					path: `/OrnamentOpen`,
					query: {
						skin_id: item.id,
						// item: JSON.stringify(item),
					},
				});
			},
			//获得历史
			goHistory() {
				this.$router.push({
					path: `/OrnamentHistory2`,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.oran {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		// background-color: #1a1c24;
		background-color: #06090f;

		.myhomebot-mag {
			margin-top: 120px;
		}

		.oran-warp {
			height: 100%;
			padding: 0 16px;
		}

		.oran-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.orantop-left {
				color: #c3c3e2;
				font-size: 20px;
			}

			.orantop-right {
				padding: 6px 22px;
				background-color: #333542;
				border-radius: 5px;
				color: #848492;
				font-size: 15px;
				font-weight: 600;
			}

			.orantop-right:hover {
				cursor: pointer;
				background-color: #3a3f50;
			}
		}

		.shengjiang{
			padding: 8px 12px;
			border-radius: 4px;
			background-color: #e9b10e;
			display: flex;
			align-items: center;
			span{
				margin-right: 6px;
				font-size: 14px;
			}
			img{
				width: 14px;
			}
		}
		
		.oran-sel {
			// background: url("../assets/95/linebg.png") no-repeat;
			// background-size: 100% 100%;
			// border-top: 1px solid #e9b10e;
			// margin: 20px auto 0 auto;
			// background-color: #2b2c37;
			display: flex;
			flex-direction: column;
		
			@media screen and (max-width: 750px) {
				.sel-top {
					ul {
						// display: grid !important;
						// grid-template-columns: repeat(auto-fit, minmax(75px, 1fr)) !important;
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						flex-wrap: wrap;

						li {
							width: 22% !important;
							margin-left: 0 !important;
						}
					}
				}
			}
		
			.sel-top {
				// border-bottom: 1px solid #e9b10e;
				// background-color: #1a1c24;
		
				ul {
					display: flex;
		
					li {
						margin-top: 12px;
						margin-left: 12px;
		
						.seltop-warp {
							width: 75px;
							height: 75px;
							background-image: url('../assets/img/scs2.png');
							background-size: 75px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
		
							img {
								width: 60%;
								height: auto;
								max-height: 78px;
							}
		
							span {
								font-size: 12px;
								color: #878787;
							}
						}
		
						.seltop-warp1 {
							// background-color: #2b2c37;
							background-image: url('../assets/img/scs1.png');
							background-size: 75px;
							border-radius: 0 !important;
							span {
								color: white;
							}
						}
					}
		
					li:hover {
						cursor: pointer;
					}
				}
			}
		
			.class-box {
				// background-color: #2b2c37;
				padding: 0 !important;
				width: 100%;
				.class-list {
					margin-top: 15px;
		
					ul {
						display: flex;
		
						li {
							margin-right: 10px;
		
							span {
								border: 1px solid #ffc400;
								border-radius: 5px;
								padding: 5px 16px;
								font-size: 12px;
								color: #fff;
							}
		
							.span2 {
								border: 1px solid #ffc400;
								color: #fff;
								font-size: 12px;
							}
		
							span:hover {
								cursor: pointer;
								background-color: #ffc400;
								color: #000;
							}
						}
					}
				}
			}
		
			.sel-bot {
				display: flex;
				flex-wrap: wrap;
		
				&>div {
					margin: 30px 0 10px 0px;
		
					&:last-child {
						display: flex;
						justify-content: flex-start;
					}
				}
		
				.selbot-left {
					flex: 1;
					min-width: 200px;
					min-height: 60px;
		
					.pz-container,
					.wg-container {
						display: flex;
						align-items: center;
						font-size: 14px;
						padding: 5px 0;
		
						.name {
							color: #fff;
							width: 45px;
						}
		
						.wg-content {
							color: #848492;
							flex: 1;
							white-space: nowrap;
							overflow: auto;
		
							span {
								padding: 0 10px;
								cursor: pointer;
		
								&:hover {
									color: #e9b10e;
								}
							}
		
							.active {
								color: #e9b10e;
							}
						}
					}
				}
		
				.selbot-right {
					margin-top: 10px !important;
					min-width: 100% !important;
					// margin-left: 10px;
					display: flex;
					// align-items: center;
					justify-content: space-between;
		
					.span {
						margin: 0 8px;
						color: #848492;
					}
		
					.pirec-btn {
						margin: 0 10px;
						background-color: #333542;
						border-radius: 5px;
						color: #848492;
						font-size: 15px;
						font-weight: 600;
						padding: 10px 26px;
					}
		
					.pirec-btn:hover {
						cursor: pointer;
						background-color: #3a3f50;
					}
		
					.input {
						width: 120px;
		
						img {
							width: auto;
							height: 18px;
						}
					}
		
					.input /deep/ .el-input__prefix,
					.input /deep/ .el-input__suffix {
						top: 11px;
					}
		
					.input1 {
						width: 200px;
					}
		
					.input1-i:hover {
						cursor: pointer;
					}
				}
		
				.selbot-right /deep/ .el-input__inner {
					background-color: #2b2c37;
					border: 1px solid #e9b10e;
					color: #c3c3e2;
				}
			}
		}
		
		.roll-list {
			margin-top: 20px;
		
			ul {
				margin: 0 -4px;
		
				li {
					width: 16.66%;
					float: left;
		
		
					.list-warp {
						margin: 4px;
						background-color: #2b2c37;
						border-radius: 5px;
						position: relative;
						overflow: hidden;
		
						.list-bor {
							position: absolute;
							top: 0;
							z-index: 666;
							width: 100%;
							height: 100%;
							background-color: rgba(233, 177, 14, 0.1);
						}
		
						.ico {
							position: absolute;
							top: 0;
							left: 0;
							font-size: 12px;
							// color: #fafcfa;
							// background-color: rgb(81, 113, 156);
							color: #ccc;
							padding: 8px 8px;
						}
		
						.buy-state {
							position: absolute;
							top: 0;
							right: 0;
		
							img {
								width: 15px;
								height: 15px;
							}
						}
		
						.list-img {
							// background-image: url("../assets/img/box-skins-blue.jpg");
							background-image: url("../assets/img/backroll_pc.png");
							background-size: 100% 100%;
							padding: 0 20px;
							overflow: hidden;
							padding-top: 24px;
		
							// height: 130px;
							img {
								width: 100%;
								height: 100%;
							}
						}
		
		
						@media screen and (max-width: 767px) {
							.list-img {
								// background-image: url("../assets/img/box-skins-blue.jpg");
								background-image: url("../assets/img/backroll.png");
								background-size: 100% 100%;
								padding: 0 20px;
								overflow: hidden;
								padding-top: 24px;
			
								// height: 130px;
								img {
									width: 100%;
									height: 100%;
								}
							}
						}
						.bot {
		
							// background-color: #2b2c37;
							.list-name {
								// text-align: center;
								font-size: 14px;
								color: #c3c3e2;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
		
							}
		
							.list-pirce {
								padding: 10px 8px;
								display: flex;
								text-align: center;
								justify-content: center;
		
								.pirce-left {
									display: flex;
									align-items: center;
		
									img {
										width: auto;
										height: 15px !important;
										margin-right: 5px;
									}
		
									span {
										color: white !important;
										font-size: 16px;
									}
								}
		
								.pirce-right {
									color: #848492;
									font-size: 14px;
								}
							}
						}
					}
		
					.list-warp {
						margin: 4px;
						background-color: #2b2c37;
						border-radius: 5px;
						position: relative;
						overflow: hidden;
		
						.list-bor {
							position: absolute;
							top: 0;
							z-index: 666;
							width: 100%;
							height: 100%;
							background-color: rgba(233, 177, 14, 0.1);
						}
		
						.ico {
							position: absolute;
							top: 0;
							left: 0;
							font-size: 12px;
							// color: #fafcfa;
							// background-color: rgb(81, 113, 156);
							color: #ccc;
							padding: 8px 8px;
						}
		
						.buy-state {
							position: absolute;
							top: 0;
							right: 0;
		
							img {
								width: 15px;
								height: 15px;
							}
						}
		
						.list-img {
							// background-image: url("../assets/img/box-skins-blue.jpg");
							// background-image: url("http://oss.wwmanghe.com/prod/lucky/f1d5f606-2ba9-4ce6-b4dc-ab44553e3c1a.png?x-oss-process=image/format,webp");
							background-size: 100% 100%;
							padding: 0 20px;
							overflow: hidden;
							padding-top: 24px;
		
							// height: 130px;
							img {
								width: 100%;
								height: 100%;
							}
						}
		
						.bot {
		
							// background-color: #2b2c37;
							.list-name {
								// text-align: center;
								font-size: 14px;
								color: #c3c3e2;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
		
							}
		
							.list-pirce {
								padding: 10px 8px;
								display: flex;
								text-align: center;
								justify-content: center;
		
								.pirce-left {
									display: flex;
									align-items: center;
		
									img {
										width: auto;
										height: 15px;
										margin-right: 5px;
									}
		
									span {
										color: #e9b10e;
										font-size: 16px;
									}
								}
		
								.pirce-right {
									color: #848492;
									font-size: 14px;
								}
							}
						}
					}
				}
		
			}
		}
		
	}
</style>