<template>
	<div class="base-header">
		<div class="box">

			<ul class="nav">
				<li class="select" v-if="type == 0">我的库存</li>
				<li class="select" v-if="type == 1">已取回</li>
				<li class="select" v-if="type == 2">正在取回</li>
				<div class="nav-right">
					<div class="rrrs"> {{total}}件饰品 </div>
					<div class="rrrs" v-if="type == 0"> 总价值{{total_value}} </div>
					<!-- <div class="button">全部出售</div> -->
					<div class="dota-sel" v-if="type == 0">
						<!-- <div class="sel-left">价格从低到高</div> -->
						<div class="sel-right">
							<div class="right-one">
								<div class="xuanze" @click="offAll()" v-if="checkedPrice > 0">
									<img src="../../../assets/img/fuxuan1.png" style="width: 16px;" alt="">
									<span style="color: #e9b10e; padding: 0">({{ checkedPrice }})</span>
									选择当前页
								</div>
								<span class="xuanze" @click="selAll()" v-else>
									<img src="../../../assets/img/fuxuan.png" style="width: 16px;" alt="">
									选择当前页
								</span>
								<!-- <span class="span1" @click="offAll()">取消选择</span> -->
								<el-button class="span2" @click="coniAll()" :disabled="loading"><i v-if="loading"
										class="el-icon-loading"></i>全部兑换</el-button>
								<el-button class="span2" @click="conisAll()" :disabled="loading"><i v-if="loading"
										class="el-icon-loading"></i>全部提取</el-button>
								<!--<span class="span3">取回</span> -->
							</div>
						</div>
					</div>
				</div>
			</ul>

			<div class="dota-list">
				<ul>
					<li v-for="(item, index) in skinList" :key="index" @click="check(item.id)">
						<div class="list-warp">
							<div class="warp3" v-if="item.state1"><span>取回中</span></div>
							<div class="warp1" v-if="item.state"></div>
							<img class="warp2" v-if="item.state" src="../../../assets/img/gou.png" />
							<span class="ico" v-if="item.exteriorName">{{ item.exteriorName }}</span>
							<span v-if="item.state" class="buy-state">
								<img src="../../../assets/img/gou.png" />
							</span>
							<div class="list-img">
								<img :src="item.img" />
							</div>
							<div class="bot">
								<div class="list-name" :title="item.name">{{ item.name }}</div>
								<div class="list-pirce">
									<div class="pirce-left">
										<img src="../../../assets/img/money.png" />
										<span>{{ item.price }}</span>
									</div>
									<div class="pirce-right" v-if="type == 0">
										<el-button class="spanbtn1" @click="getExchange($event, item.id)"
											:disabled="item.loading1"><i v-if="item.loading1"
												class="el-icon-loading"></i>兑换</el-button>
										<el-button class="spanbtn2" @click="getPull($event, item.id, item.itemId)"
											:disabled="item.loading2"><i v-if="item.loading2"
												class="el-icon-loading"></i>取回</el-button>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
				<div class="clear"></div>
			</div>
		</div>
		<div class="roll-page">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
				@current-change="currentChange">
			</el-pagination>
		</div>
		<div class="exchange-decoration" v-if="changeDecoration.length && !changeDecorations.length">
			<div class="content">
				<span class="title">确定要回收吗?</span>
				<!-- <div class="types">
					<div @click="exchangeType = 'dollar'" class="btn" :class="{
              'active-btn': exchangeType == 'dollar',
            }">
						<span>金币：</span>
						<img class="dollar" src="../assets/img/money.png" />
						<span>{{ checkedPrice }}</span>
					</div>
					<div class="btn" @click="exchangeType = 'diamond'" :class="{
              'active-btn': exchangeType == 'diamond',
            }">
						<span>c币：</span>
						<img class="diamond" src="../assets/img/masonry.png" />
						<span>{{ checkedPrice }}</span>
					</div>
				</div> -->
				<div class="operate">
					<div @click="NoExchange">取消</div>
					<div @click="startExchange">确认</div>
				</div>
			</div>
		</div>
		<div class="exchange-decoration" v-if="changeDecorations.length && changeDecorations.length">
			<div class="content">
				<span class="title">确定要提取吗?</span>
				<!-- <div class="types">
					<div @click="exchangeType = 'dollar'" class="btn" :class="{
              'active-btn': exchangeType == 'dollar',
            }">
						<span>金币：</span>
						<img class="dollar" src="../assets/img/money.png" />
						<span>{{ checkedPrice }}</span>
					</div>
					<div class="btn" @click="exchangeType = 'diamond'" :class="{
              'active-btn': exchangeType == 'diamond',
            }">
						<span>c币：</span>
						<img class="diamond" src="../assets/img/masonry.png" />
						<span>{{ checkedPrice }}</span>
					</div>
				</div> -->
				<div class="operate">
					<div @click="NoExchange">取消</div>
					<div @click="startExchanges">确认</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Utils from "./../../../assets/js/util.js";
	export default {
		name: "base-header",
		props: ["type"],
		data() {
			return {
				loading: false,
				total: 0,
				total_value: 0,
				skinList: [],
				pageSize: 12,
				exchangeHint: "",
				checkedPrice: 0,
				random: "",
				exchangeType: "diamond",
				changeDecoration: [],
				page: 2,
				changeDecorations: []

			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			//兑换多个
			coniAll() {
				if (!Number.parseFloat(this.checkedPrice)) {
					this.$message.error("请选中饰品");
					return;
				}
				var arr = [];
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state1) {
						this.$message({
							message: "正在取回中，稍后重试",
							type: "warning",
						});
						return;
					}
				}
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state) {
						arr.push(this.skinList[i].id);
					}
				}
				this.changeDecoration = arr;
			},
			// 提取多个
			conisAll() {
				if (!Number.parseFloat(this.checkedPrice)) {
					this.$message.error("请选中饰品");
					return;
				}
				var arr = [];
				var arrs = [];
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state1) {
						this.$message({
							message: "正在取回中，稍后重试",
							type: "warning",
						});
						return;
					}
				}
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state) {
						arr.push(this.skinList[i].id);
						arrs.push(this.skinList[i].itemId);
					}
				}
				this.changeDecoration = arr;
				this.changeDecorations = arrs;
			},
			//单个选中
			check(id) {
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].id == id) {
						this.skinList[i].state = !this.skinList[i].state;
						console.log(this.skinList[i].state1)
						if (this.skinList[i].state == true && !this.skinList[i].state1) {
							this.checkedPrice = (
								parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
							).toFixed(2);
						} else {
							this.checkedPrice = (
								parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
							).toFixed(2);
						}
					}
				}
				this.$forceUpdate();
			},
			//选择当前页
			selAll() {
				this.checkedPrice = 0;
				for (let i = 0; i < this.skinList.length; i++) {
					if (!this.skinList[i].state1) {
						if (this.skinList[i].price != null) {
							this.skinList[i].state = true;
							this.checkedPrice = (
								parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
							).toFixed(2);
						}
					}
				}
				this.$forceUpdate();
			},
			//取消当前页
			offAll() {
				this.checkedPrice = 0;
				for (let i = 0; i < this.skinList.length; i++) {
					this.skinList[i].state = false;
					this.checkedPrice = (
						parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
					).toFixed(2);
				}
				this.$forceUpdate();
				this.tiqu = false;
			},
			/**
			 * 兑换成 c币
			 * @param {*} id
			 */
			exchangeToMoney(id) {
				let param = {
					player_skins_ids: id,
				};
				this.$axios
					.post("/index/User/exchangeToMoney", this.$qs.stringify(param))
					.then((res) => {
						console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$store.commit("getMoney", data.data.total_amount);
							Utils.$emit("masonry", data.data.total_amount);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
							this.checkedPrice = 0;
							this.changeDecoration = [];
						} else {
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
								}
							}
							this.$forceUpdate();
							this.$message({
								showClose: true,
								message: data.msg,
								type: "warning",
							});
						}
					});
			},
			/**
			 * 兑换成 金币
			 * @param {*} id
			 */
			exchangeToMoney2(id) {
				let param = {
					player_skins_ids: id,
				};
				this.$axios
					.post("/index/User/exchangeToMoney2", this.$qs.stringify(param))
					.then((res) => {
						console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$store.commit("getMoney", res.data.data.total_amount);
							Utils.$emit("money", res.data.data.total_amount);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
							this.changeDecoration = [];
						} else {
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
								}
							}
							this.$forceUpdate();
							this.$message({
								showClose: true,
								message: data.msg,
								type: "warning",
							});
						}
					});
			},

			//点击取回
			getPull(event, id, steamId) {
				event.stopPropagation();
				for (let i = 0; i < this.skinList.length; i++) {
					if (id == this.skinList[i].id) {
						this.skinList[i].loading1 = true;
						this.skinList[i].loading2 = true;
						this.skinList[i].state1 = true;
					}
				}
				this.$forceUpdate();
				let param = {
					player_skins_id: id,
					itemId: steamId,
					random: this.random,
				};
				this.$axios
					.post("/index/User/skinToSteam1", this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
						} else {
							var hint = "";
							if (data.msg.indexOf("余额不足") != -1) {
								hint = "取回错误，请联系客服";
							} else if (data.msg.indexOf("取回失败,请充值") != -1) {
								hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
							} else {
								hint = data.msg;
							}
							this.$message({
								showClose: true,
								message: hint,
								type: "warning",
							});
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
									this.skinList[i].state1 = false;
								}
							}
							this.$forceUpdate();
						}
						this.randomString();
					});
			},
			randomString(e) {
				e = e || 32;
				var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
					a = t.length,
					n = "";
				for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
				this.random = n;
			},
			//点击兑换
			getExchange(event, id) {
				event.stopPropagation();
				for (let i = 0; i < this.skinList.length; i++) {
					if (this.skinList[i].state1) {
						this.$message({
							message: "正在取回中，稍后重试",
							type: "warning",
						});
						return;
					}
				}
				for (let i = 0; i < this.skinList.length; i++) {
					if (id == this.skinList[i].id) {
						this.skinList[i].loading1 = true;
						this.skinList[i].loading2 = true;
						this.checkedPrice = this.skinList[i].price;
					}
				}
				this.$forceUpdate();
				this.changeDecoration = [id];
			},
			startExchange() {
				// 兑换金币
				if (this.exchangeType == "dollar") {
					this.exchangeToMoney2(this.changeDecoration);
				} else {
					// 兑换c币
					this.exchangeToMoney(this.changeDecoration);
				}
				this.checkedPrice = 0;
			},
			//点击取回
			getPulls(id, steamId) {
				for (let i = 0; i < this.skinList.length; i++) {
					if (id == this.skinList[i].id) {
						this.skinList[i].loading1 = true;
						this.skinList[i].loading2 = true;
						this.skinList[i].state1 = true;
					}
				}
				this.$forceUpdate();
				let param = {
					player_skins_ids: id,
					itemId: steamId,
					random: this.random,
				};
				this.$axios
					.post("/index/User/skinToSteam1", this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1") {
							this.getList(this.page);
							this.$message({
								showClose: true,
								message: data.msg,
								type: "success",
							});
							this.changeDecoration = [];
							this.changeDecorations = [];
							this.checkedPrice = 0;
						} else {
							var hint = "";
							if (data.msg.indexOf("余额不足") != -1) {
								hint = "取回错误，请联系客服";
							} else if (data.msg.indexOf("取回失败,请充值") != -1) {
								hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
							} else {
								hint = data.msg;
							}
							this.$message({
								showClose: true,
								message: hint,
								type: "warning",
							});
							for (let i = 0; i < this.skinList.length; i++) {
								if (id == this.skinList[i].id) {
									this.skinList[i].loading1 = false;
									this.skinList[i].loading2 = false;
									this.skinList[i].state1 = false;
								}
							}
							this.$forceUpdate();
						}
						this.randomString();
					});
			},
			startExchanges() {
				// 兑换
				this.getPulls(this.changeDecoration, this.changeDecorations);
			},
			NoExchange() {
				this.changeDecoration.forEach(e => {
					for (let i = 0; i < this.skinList.length; i++) {
						if (e == this.skinList[i].id) {
							this.skinList[i].loading1 = false;
							this.skinList[i].loading2 = false;
						}
					}
				})
				this.$forceUpdate();
				this.changeDecoration = [];
				this.changeDecorations = [];
			},
			currentChange(val) {
				this.getList(val);
				this.checkedPrice = 0;
			},
			getList(page) {
				this.page = page;
				let param = {
					pageSize: this.pageSize,
					page: this.page
				};
				var url = "/index/User/packageList";
				if (this.type == 1) {
					url = "/index/User/backList";
				}
				if (this.type == 2) {
					url = "/index/User/processingList";
				}
				this.$axios
					.post(url, this.$qs.stringify(param))
					.then((res) => {
						var data = res.data;
						if (data.status == "1") {
							this.total = data.data.total;
							this.total_value = data.data.total_value;
							this.skinList = data.data.skinList;
						}
						if (data.msg == "无数据") {
							this.skinList = [];
						}
					});
			}
		},
	};
</script>

<style lang="less" scoped>
	.base-header {
		font-family: '微软雅黑';

		.box {
			min-height: 76vh;
		}

		.nav {
			height: 42px;
			font-size: 18px;
			font-weight: 500;
			display: flex;
			align-items: center;


			li {
				width: 110px;
				height: 48px;
				line-height: 48px;
				color: white;
				margin-right: 40px;
				text-align: center;
				padding: 0 24px;
			}

			.nav-right {
				display: flex;
				color: white;
				align-items: center;
				justify-content: end;
				width: 80%;
				font-size: 14px;

				.rrrs {
					margin-right: 12px;
				}

				.button {
					width: 112px;
					height: 35px;
					line-height: 35px;
					text-align: center;
					font-size: 15px;
					background-image: url('../../../assets/img/my/button1.png');
					background-size: cover;
					background-repeat: no-repeat;
					font-weight: 400;
					color: #fff;
				}

				.dota-sel {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.sel-left {
						color: #e9b10e;
						font-size: 12px;
						font-weight: 600;
					}

					.sel-left:hover {
						cursor: pointer;
						text-decoration: underline;
					}

					.sel-right {
						max-height: 50px;
						display: flex;
						align-items: center;

						.right-one {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: end;

							span {
								margin-right: 8px;
								padding: 8px 16px;
								border-radius: 5px;
								font-size: 14px;
								color: #848492;
								font-weight: 600;
							}

							.xuanze {
								margin-right: 8px;
								padding: 8px 16px;
								border-radius: 5px;
								font-size: 14px;
								color: #848492;
								font-weight: 600;
								background-color: #333452;
								display: flex;
								align-items: center;

								img {
									margin-right: 6px !important;
								}

								span {
									margin: 0 !important;
									margin-right: 6px !important;
								}
							}

							.span1 {
								background-color: #333452;
							}

							.span1:hover {
								cursor: pointer;
								background-color: #3a3f50;
							}

							.span2 {
								padding: 8px 16px;
								background-color: #e9b10e;
								color: #1a1c24;
								border-color: #e9b10e;
							}

							.span2:hover {
								cursor: pointer;
								background-color: #f5c432;
							}

							.span3 {
								background-color: #17b4ed;
								color: #1a1c24;
							}

							.span3:hover {
								cursor: pointer;
								background-color: #3eccff;
							}
						}

					}
				}

			}

			.select {
				color: #69e0ff !important;
				border-bottom: 2px solid #69e0ff;
			}

			li:hover {
				background: rgba(255, 255, 255, .1);
			}

			.select:hover {
				background: rgba(105, 224, 255, .1);

			}

		}



		//分页
		.roll-page {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0 0px -10px;
			// margin-bottom: 50px;
			padding-bottom: 50px;
		}

		.roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #e9b10e;
			color: #1a1c24;
		}

		.roll-page /deep/ .el-pagination.is-background .btn-next,
		/deep/ .el-pagination.is-background .btn-prev,
		/deep/ .el-pagination.is-background .el-pager li {
			background-color: #333542;
			color: #848492;
		}

		.dota-list {
			margin-top: 20px;

			ul {
				margin: 0 -8px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-around;

				li {
					width: 24%;
					margin-top: 5px;

					.list-warp {
						margin: 8px;
						background-color: #2b2c37;
						border-radius: 5px;
						position: relative;
						overflow: hidden;

						.warp1 {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: rgba(233, 177, 14, 0.1);
							z-index: 33;
						}

						.warp3 {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: rgba(0, 0, 0, 0.3);
							z-index: 66;
							display: flex;
							justify-content: center;

							span {
								margin-top: 40px;
								color: #fff;
								font-size: 20px;
							}
						}

						.warp2 {
							width: 20px;
							height: 20px;
							position: absolute;
							right: 0;
							top: 0;
						}

						.list-bor {
							position: absolute;
							top: 0;
							z-index: 666;
							width: 100%;
							height: 100%;
							background-color: rgba(233, 177, 14, 0.1);
						}

						.ico {
							position: absolute;
							top: 0;
							left: 0;
							font-size: 14px;
							color: #f7faf7;
							background-color: rgb(81, 113, 156);
							padding: 2px 4px;
						}

						.buy-state {
							position: absolute;
							top: 0;
							right: 0;

							img {
								width: 15px;
								height: 15px;
							}
						}

						.list-img {
							// height: 160px;
							background-image: url("../../../assets/img/beibao.png");
							background-size: 100% 100%;
							padding: 0 20px;
							display: flex;
							align-items: center;
							justify-content: center;

							img {
								width: auto;
								height: 100%;
								max-height: 148px;
							}
						}

						.bot {
							background-color: #2b2c37;

							.list-name {
								padding-left: 5px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-size: 16px;
								color: #c3c3e2;
							}

							.list-pirce {
								padding: 10px 5px 0 5px;
								display: flex;
								// flex-direction:row-reverse;
								justify-content: space-between;

								.pirce-left {
									display: flex;
									align-items: center;
									margin-right: 10px;
									margin-bottom: 6px;

									img {
										width: auto;
										height: 15px;
										margin-right: 5px;
									}

									span {
										color: #e9b10e;
										font-size: 16px;
									}
								}

								.pirce-right {
									display: flex;
									align-items: center;
									padding-bottom: 6px;

									span {
										margin-left: 10px;
										padding: 4px 15px;
										border-radius: 5px;
										font-size: 12px;
										white-space: nowrap;
									}

									span:hover {
										cursor: pointer;
									}

									.spanbtn1 {
										border: 1px solid #e9b10e;
										color: #e9b10e;
										padding: 4px 15px;
									}

									.spanbtn2 {
										padding: 4px 15px;
										background-color: #e9b10e;
										border-color: #e9b10e;
									}
								}
							}
						}
					}

					.list-warp:hover {
						cursor: pointer;
					}
				}
			}
		}

		.exchange-decoration {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			z-index: 999999999999;
			background-color: rgba(0, 0, 0, 0.6);
			display: grid;
			place-items: center;

			.content {
				width: 80%;
				max-width: 500px;
				background-color: #1b2330;
				border-radius: 10px;
				color: #fff;
				text-align: center;
				box-sizing: border-box;
				padding: 30px 0px 0 0px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				.title {
					font-size: 20px;
					flex: 1;
					font-weight: bold;
					margin: 0 0 30px 0;
				}

				.types {
					width: 70%;
					margin-bottom: 50px;

					.active-btn {
						background-color: #e9b10e;
						color: #000 !important;
						font-weight: bold;
					}

					.btn {
						margin: 10px 0;
						height: 40px;
						line-height: 40px;
						color: #fff;
						cursor: pointer;
						border-radius: 8px;
						border: 1px solid #2b3441;
						display: flex;
						justify-content: center;
						align-items: center;

						.dollar {
							width: 20px;
							height: 20px;
							margin-right: 3px;
						}

						.diamond {
							width: 17px;
							margin-right: 3px;
							height: 17px;
						}
					}
				}

				.operate {
					width: 100%;
					height: 50px;
					font-weight: bold;
					display: flex;
					border-top: 1px solid #000;

					&>div {
						flex: 1;
						display: grid;
						place-items: center;
						cursor: pointer;

						&:first-child {
							border-right: 1px solid #000;
						}
					}
				}
			}
		}
	}
</style>