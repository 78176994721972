var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-header"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"c-top"},[_c('span',[_vm._v("充值金额")]),_c('span',[_vm._v("1个c币="+_vm._s(_vm.rmb)+"元人民币，盲盒娱乐，请理性消费 ")])]),(!_vm.is_new)?_c('div',{staticClass:"c-top1"},[(_vm.ordinaryActivity.end_time)?_c('span',[_vm._v("活动时间 "+_vm._s(_vm.ordinaryActivity.start_time)+" 至 "+_vm._s(_vm.ordinaryActivity.end_time))]):_vm._e(),_vm._v("单笔充值"+_vm._s(_vm.ordinaryActivity.money)+"(含)以上"+_vm._s(_vm.ordinaryActivity.limit)+"以下送"+_vm._s(_vm.ordinaryActivity.billie)+"% ")]):_c('div',{staticClass:"c-top1"},[(_vm.ratioList.end_time)?_c('span',[_vm._v("活动时间 "+_vm._s(_vm.ratioList.start_time)+" 至 "+_vm._s(_vm.ratioList.end_time))]):_vm._e(),_vm._v(" 单笔充值"+_vm._s(_vm.ratioList.money)+"(含)以上"+_vm._s(_vm.ratioList.limit)+"以下首充多送"+_vm._s(_vm.ratioList.billie)+"% ")]),_c('div',{staticClass:"c-center"},[_vm._l((_vm.list.slice(0, _vm.list.length - 1)),function(item,index){return (!_vm.is_new)?_c('div',{staticClass:"boxs",class:{ select: item.state },on:{"click":function($event){return _vm.zhifuMoney(index)}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("../../../assets/img/money.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(item.money))]),(
              item.money >= Number(_vm.ratioList.money) &&
              item.money <= Number(_vm.ordinaryActivity.limit)
            )?_c('div',{staticClass:"jiasong"},[_vm._v(" 多送"+_vm._s((Number(item.money) * Number(_vm.ratioList.billie)) / 100)+" ")]):_vm._e()]):_vm._e()}),_vm._l((_vm.list.slice(0, _vm.list.length - 1)),function(item,index){return (_vm.is_new)?_c('div',{staticClass:"boxs",class:{ select: item.state },on:{"click":function($event){return _vm.zhifuMoney(index)}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("../../../assets/img/money.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(item.money))]),(
              item.money >= Number(_vm.ratioList.money) &&
              item.money <= Number(_vm.ratioList.limit)
            )?_c('div',{staticClass:"jiasong"},[_vm._v(" 首充多送"+_vm._s((Number(item.money) * Number(_vm.ratioList.billie)) / 100)+" ")]):_vm._e()]):_vm._e()})],2),_c('div',{staticClass:"c-bottom"},[(!_vm.is_new)?_c('div',{staticClass:"cb-box"},[_c('div',{staticClass:"cb-content"},[_c('span',[_vm._v("实际支付：")]),_c('span',{staticClass:"yellow"},[_vm._v("￥"+_vm._s(_vm.list[_vm.selectlist].money * _vm.rmb))])]),_c('div',{staticClass:"cb-content"},[_c('span',[_vm._v("实际到账：")]),_c('span',[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("../../../assets/img/money.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.list[_vm.selectlist].money >= Number(_vm.ratioList.money) && _vm.list[_vm.selectlist].money <= Number(_vm.ratioList.limit) ? Number(_vm.list[_vm.selectlist].money) + (Number(_vm.list[_vm.selectlist].money) * Number(_vm.ordinaryActivity.billie)) / 100 : _vm.list[_vm.selectlist].money))])])])]):_c('div',{staticClass:"cb-box"},[_c('div',{staticClass:"cb-content"},[_c('span',[_vm._v("实际支付：")]),_c('span',{staticClass:"yellow"},[_vm._v("￥"+_vm._s(_vm.list[_vm.selectlist].money * _vm.rmb))])]),_c('div',{staticClass:"cb-content"},[_c('span',[_vm._v("实际到账：")]),_c('span',[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("../../../assets/img/money.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.list[_vm.selectlist].money >= Number(_vm.ratioList.money) && _vm.list[_vm.selectlist].money <= Number(_vm.ratioList.limit) ? Number(_vm.list[_vm.selectlist].money) + (Number(_vm.list[_vm.selectlist].money) * Number(_vm.ratioList.billie)) / 100 : _vm.list[_vm.selectlist].money))])])])]),_c('div',{staticClass:"cb-content"},[_c('span',[_vm._v("支付方式：")]),_vm._l((_vm.paymentMethods),function(method,index){return _c('span',{key:index,staticClass:"zhifubao",class:{ 'alipay': (_vm.selectedMethodIndex === index && (_vm.selectedMethodIndex === 0 || _vm.selectedMethodIndex === 1)),
      'wechat': _vm.selectedMethodIndex === 2&&_vm.selectedMethodIndex === index},on:{"click":function($event){return _vm.selectPaymentMethod(method,index)}}},[_c('img',{attrs:{"src":method.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(method.title))])])})],2),_c('div',{staticClass:"cb-chongzhi",on:{"click":function($event){return _vm.payInfo(_vm.payType,_vm.payType2)}}},[_vm._v("确认充值")])]),_c('el-checkbox',{model:{value:(_vm.cheked),callback:function ($$v) {_vm.cheked=$$v},expression:"cheked"}},[_vm._v(" 我已满18岁且具备完全行为能力，登录/注册即代表同意 "),_c('strong',[_vm._v("《用户协议》")]),_vm._v("和"),_c('strong',[_vm._v("《隐私条款》")]),_vm._v("。 ")])],1),_c('el-dialog',{attrs:{"visible":_vm.payBox,"width":"320px","center":"","top":"5%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.payBox=$event}}},[_c('div',{staticClass:"dialog-title",attrs:{"slot":"title"},slot:"title"},[_c('div',[_c('img',{attrs:{"src":require('../../../assets/img/' + _vm.payTitleIcon)}}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.payTitle))])])]),_c('div',{staticStyle:{"min-height":"160px"}},[_c('div',{ref:"qrcode",staticStyle:{"margin-left":"60px"},attrs:{"id":"qrcode"}})]),_c('div',{staticClass:"save-qrcode"},[_c('el-button',{attrs:{"type":"warning"},on:{"click":_vm.saveImg}},[_vm._v("保存二维码")])],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('img',{attrs:{"src":require("../../../assets/img/scan.svg")}}),_c('div',[_c('p',[_vm._v(_vm._s(_vm.payFooter))]),_c('p',[_vm._v(_vm._s(_vm.payFooterDesc))])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nav"},[_c('li',{staticClass:"select"},[_vm._v("充值")])])}]

export { render, staticRenderFns }