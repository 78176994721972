<template>
  <div class="cdk-change" @click="handleClose">
    <div class="info">
      <img src="@/assets/img/win1.png" class="win-tips" />
      <img v-if="info.img" :src="info.img" alt="" />
      <div class="name">{{ info.name || "-" }}</div>
      <div class="join-backpack">放入背包</div>
    </div>
    <div class="mask"></div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="less" scoped>
.cdk-change {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  .info {
    width: 100%;
    max-width: 600px;
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 88888;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    background-color: #000000;
    .name {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
    }
    .join-backpack {
      color: #fff;
      margin-top: 10px;
      color: #e9b10e;
    }
    .win-tips {
      width: 70%;
    }
  }
  .mask {
    background-color: #000000;
    height: 100vh;
    width: 100vw;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}
</style>
