<template>
  <div class="me">
    <div class="me-warp">
      <div class="me-title1">账号设置</div>

      <div class="me-tou">
        <div class="one">
          <span class="left">
            <span>头像</span>
            <span><img :src="Data.img" /></span>
          </span>
          <span
            class="right"
            v-if="!datalist.touxiang"
            @click="datalist.touxiang = true"
            >更换头像</span
          >
          <span class="right1" v-else>
            <div @click="chushihua">取消</div>
            <div @click="changePhoto">保存</div>
          </span>
        </div>
        <div class="touxiang" v-if="datalist.touxiang">
          <div v-for="(item, index) in touxiang">
            <div @click="changeToux(index)">
              <img
                :src="item.img"
                :class="{ selectToux: index == selectToux }"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="one">
          <span class="left">
            <span>昵称</span>
            <span v-if="!datalist.userName">{{ Data.name }}</span>
            <span v-else
              ><input
                type="text"
                placeholder="请输入昵称"
                v-model="dlist.userName"
                class="long"
            /></span>
          </span>
          <span
            class="right"
            v-if="!datalist.userName"
            @click="datalist.userName = true"
            >修改昵称</span
          >
          <span class="right1" v-else>
            <div @click="chushihua">取消</div>
            <div @click="getName">保存</div>
          </span>
        </div>
        <!-- <div class="extend" v-if="datalist.userName">
					昵称必须为包含数字、英文、中文在内的1-14个字符，10天内只能修改一次。 请勿带有辱骂、广告、诱导等其他违反法规的词汇，违者账号将做封禁处理
				</div> -->
        <div class="one">
          <span class="left">
            <span>密码</span>
            <span v-if="!datalist.passWord">******</span>
            <span v-else
              ><input
                type="text"
                placeholder="请输入新密码"
                v-model="dlist.passWord"
                class="long"
            /></span>
          </span>
          <span
            class="right"
            v-if="!datalist.passWord"
            @click="datalist.passWord = true"
            >修改密码</span
          >
          <span class="right1" v-else>
            <div @click="chushihua">取消</div>
            <div @click="openPass">保存</div>
          </span>
        </div>
        <div class="extend1" v-if="datalist.passWord">
          <span
            >短信验证<input
              type="text"
              placeholder="请输入短信验证码"
              class="long"
              v-model="dlist.passWordCode"
          /></span>
          <span @click="getCode(0)">获取验证码</span>
        </div>
        <div class="one">
          <span class="left">
            <span>{{ inviteImg?'邀请人':'关联邀请码'}}</span>
            <span style="display: flex;
    align-items: center;">
              <input
              v-if="!inviteImg"
                type="text"
                placeholder="请填写邀请码"
                v-model="tuiguangren"
                class="long"
              />
              <img v-if="inviteImg" :src="inviteImg" />
              <span>{{ inviteName }}</span>
            </span>
          </span>
          <span class="right" v-if="!inviteImg" @click="tuiguang">绑定</span>
          <span class="right" v-else style="opacity: 0.5" >已绑定</span>
        </div>
        <div class="one">
          <span class="left">
            <span>实名认证</span>
			<span v-if="idcard">已认证</span>
            <span :style="idcard ? 'opacity: 0;' : ''"
              ><input type="text" placeholder="姓名" v-model="username" />
              <input
                class="chang"
                type="text"
                placeholder="身份证号码"
                v-model="card"
            /></span>
          </span>
          <span class="right" @click="shiming" v-if="!idcard">确定</span>
          <span class="right" v-else style="opacity: 0.5">已认证</span>
        </div>
        <div class="one">
          <span class="left">
            <span>交易链接</span>
            <span>
              <input
                type="text"
                style="width: 400px; height: 40px; padding-right: 12px"
                placeholder="请输入内容"
                v-model="dlist.trading"
              />
              <span class="rrr" @click="goUrl(url1)">前往STEAM获取</span>
              <span class="rrr" @click="goUrl(url2)">帮助中心</span>
            </span>
          </span>
          <!-- v-if="!datalist.trading" datalist.trading = true; -->
          <span class="right" @click="confirm">更改/绑定</span>
          <!-- <span class="right1" v-else>
						<div @click="datalist.trading = false">取消</div>
						<div @click="confirm">保存</div>
					</span> -->
        </div>
      </div>

      <div class="me-title" style="color: #999">
        ※
        <span style="color: red">取回饰品</span
        >需要绑定Steam交易链接，请确保您的报价链接有效，且设置Steam库存为公开
      </div>

      <!-- 	<div class="invite">
				<div class="invite-list" v-if="inviteImg">
					<img v-if="inviteImg" :src="inviteImg" />
					<span>{{ inviteName }}</span>
				</div>
				<div class="invite-list1" v-if="!inviteImg">
					新用户3天内可以绑定上级<span @click="openTop">绑定</span>
				</div>
			</div>

			<div class="me-title">账号管理</div>

			<div class="number">
				<div class="number1">
					<span>手机: {{ Data.mobile }}</span><i class="el-icon-edit"></i>
				</div>
				<div class="number1">
					<span>邮箱绑定 {{ Data.email }}</span><span class="number-span" @click="openEmail">绑定</span>
				</div>
				<div class="number1">
					<span>修改密码</span><i class="el-icon-edit" @click="openPass"></i>
				</div>
			</div>

			<div class="me-title">偏好设置</div>

			<div class="hobby">
				<el-checkbox class="hobby1" v-model="checked1">开启声音 / 关闭声音</el-checkbox>
				<el-checkbox class="hobby1 hobby2" v-model="checked2">
					活动结果提醒（参与活动结束后结果会通过浏览器通知告知）</el-checkbox>
			</div> -->
    </div>

    <span :plain="true">{{ urlHint }}</span>

    <!-- 邮箱弹框 -->
    <div class="hide-box">
      <div class=""></div>
    </div>
    <!-- <navheader :showNav="showNav" :yidongmenu="yidongmenu"></navheader> -->
  </div>
</template>

<script>
import Utils from "./../../../assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  inject: ["reload"],
  components: {
    navheader,
  },
  props: ["yidongmenu", "showNav", "Data"],
  data() {
    return {
      datalist: {
        touxiang: false,
        userName: false,
        passWord: false,
        trading: false,
      },
      dlist: {
        touxiang: null,
        userName: null,
        passWord: null,
        passWordCode: null,
        trading: null,
        tradingCode: null,
      },
      username: "",
      card: "",
      inviteImg: "",
      inviteName: "",
      site: "",
      time: "",
      url: "",
      url1: "http://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "http://www.bilibili.com/video/BV15D4y1X79w/",
      urlState: true,
      checked1: false,
      checked2: false,
      flag: false,
      idcard: false,
      urlHint: "",
      tuiguangren: "",
      selectToux: 0,
      touxiang: [
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/default.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/1.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/2.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/3.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/4.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/5.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/6.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/7.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/8.png",
        },
        {
          img: "https://fcskins.oss-cn-beijing.aliyuncs.com/touxiang/9.png",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    tuiguang() {
      let param = {
        invite_code: this.tuiguangren,
      };
      this.$axios
        .post("index/User/bindInviter", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "绑定失败，" + data.msg,
              type: "info",
            });
          }
        });
    },
    changePhoto() {
      var _this = this;
      let param = {
        imageUrl: this.touxiang[this.selectToux].img,
      };
      this.$axios
        .post("/index/User/editHeadImages", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            _this.Data.img = res.data.data;
            Utils.$emit("img", _this.Data.img);
            this.$parent.getList();
            this.chushihua();
          } else {
            this.$message({
              message: "修改失败",
              type: "info",
            });
          }
        });
    },
    changeToux(index) {
      this.selectToux = index;
    },
    handleChange(file, fileList) {
      let _this = this;
      let formData = new FormData();
      formData.append("player_id", _this.$store.state.id);
      formData.append("file", file.raw);
      //--------------
      _this
        .$axios({
          url: "/index/User/editHeadImage",
          method: "post",
          dataType: "json",
          data: formData,
          // processData: false,
          // contentType: false,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // headers:{'Content-Type':'application/x-www-form-urlencoded'}
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 1) {
            _this.Data.img = res.data.data;
            Utils.$emit("img", _this.Data.img);
            _this.reload();
          }
        });
      // this.fileList = fileList.slice(-3);
    },
    //跟换头像
    getImg(response, file, fileList) {
      return;
    },
    chushihua() {
      this.datalist = {
        touxiang: false,
        userName: false,
        passWord: false,
        trading: false,
      };
      this.$forceUpdate();
      // this.dlist = {
      // 	touxiang: null,
      // 	userName: null,
      // 	passWord: null
      // }
    },
    //更换昵称
    getName() {
      let param = {
        name: this.dlist.userName,
      };
      this.$axios
        .post("/index/User/editNickname", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$parent.getList();
            this.getList();
            this.chushihua();
          } else {
            this.$message({
              message: "修改失败",
              type: "info",
            });
          }
        });
    },
    //邮箱绑定
    openEmail() {
      this.$prompt("请输入邮箱", "邮箱绑定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      })
        .then(({ value }) => {
          // console.log(value);
          let param = {
            email: value,
          };
          this.$axios
            .post("/index/User/bindEmail", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              // console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "绑定失败",
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    //修改密码
    openPass() {
      let param = {
        password: this.dlist.passWord,
        code: this.dlist.passWordCode,
      };
      this.$axios
        .post("/index/User/editPass", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$parent.getList();
            this.getList();
            this.chushihua();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //绑定上级
    openTop() {
      this.$prompt("请输入邀请码", "绑定上级", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            invite_code: value,
          };
          this.$axios
            .post("index/User/bindInviter", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: "绑定失败，" + data.msg,
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //个人中心信息
    getList() {
      let param = {};
      this.$axios
        .post("/index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.Data = data.data;
            this.idcard = data.data.is_realname;
            this.dlist.trading = this.Data.tradeUrl;
            if (this.url) {
              this.flag = true;
            }
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
            if (data.data.myInviter) {
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
          }
        });
      this.$axios
        .post("index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.tuiguangren = data.data.myInviter.invite_code;
          }
        });
    },
    shiming() {
      let param = {
        idcard: this.card,
        name: this.username,
      };
      this.$axios
        .post("/index/user/realname", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
		//   console.log(data)
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.idcard = true;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        }).catch((err) => {
           console.log(err) 
        });
    },
    //复制用户ID 和 steamid
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    //steam教程
    goUrl(url) {
      window.open(url, "_blank");
    },
    off() {
      this.urlState = true;
    },
    confirm() {
      //console.log(this.url);
      console.log(this.dlist);
      let param = {
        tradeUrl: this.dlist.trading,
        code: this.dlist.tradingCode,
      };
      this.$axios
        .post("/index/User/bindSteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.$parent.getList();
            this.chushihua();
            this.urlState = true;
            this.flag = true;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
            });
            this.urlState = true;
            // this.url = '';
          }
        });
    },
    getCode(e) {
      var url = "/index/User/getsteamCode";
      if (e == 0) {
        url = "/index/User/getpasswordCode";
      }
      this.$axios.post(url).then((res) => {
        // console.log(res.data);
        var data = res.data;
        if (data.status == "1") {
          console.log(data);
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "error",
          });
          // this.url = '';
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
input {
  font-size: 15px !important;
}

.spread-input {
  margin-top: 30px;
  background-color: rgba(10, 44, 57, 1);
  border-radius: 5px;
  padding: 20px;
  display: flex;

  .input-con {
    margin-right: 20px;

    .input-top {
      font-size: 16px;
      color: #eaeaea;
    }

    .input-bot {
      margin-top: 20px;
      display: flex;

      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 14px;
        background-color: rgba(27, 77, 98, 1);
        outline: none;
        box-sizing: border-box;
        border: none;
      }

      span {
        width: 100px;
        height: 39px;
        font-size: 15px;
        background-repeat: no-repeat;
        font-weight: 400;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 25px;
        background-image: url("../../../assets/img/my/button.png");
        background-size: 100% 100%;
        margin-left: 12px;
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

.me {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: #1a1c24;
  background-color: #06090f;

  .me-warp {
  }

  .me-title1 {
    // padding: 16px 0;
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    line-height: 24px;
    opacity: 0.7;
  }

  .me-title {
    padding: 16px 0;
    color: #c3c3e2;
    font-size: 16px;
  }

  .me-tou {
    width: 100%;
    background: rgba(71, 201, 243, 0.14);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 40px 30px;
    margin-top: 20px;

    .extend {
      width: 100%;
      background: rgba(71, 201, 243, 0.3);
      border-radius: 4px;
      margin-top: 10px;
      padding: 25px 70px;
      font-size: 11px;
      font-weight: 400;
      color: #adb8ba;
      line-height: 16px;
    }

    .extend1 {
      width: calc(100%-140px);
      background: rgba(71, 201, 243, 0.3);
      border-radius: 4px;
      margin-top: 10px;
      padding: 25px 70px;
      font-size: 11px;
      font-weight: 400;
      color: #adb8ba;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span:first-child {
        font-size: 16px;
      }

      span:last-child {
        display: block;
        width: 90px;
        height: 28px;
        font-size: 13px;
        background-repeat: no-repeat;
        background-size: 100%;
        line-height: 30px;
        background-image: url("../../../assets/img/my/button.png");
        font-weight: 400;
        color: #fff;
        margin-left: 12px;
        text-align: center;
        cursor: pointer;
      }

      input {
        margin-left: 30px;
        width: 200px;
        height: 40px;
        padding-left: 0.8rem;
        color: #fff;
        outline: none;
        opacity: 0.9;
        background: rgba(23, 188, 241, 0.2);
        border-radius: 4px;
        border: 0;
      }
    }

    .touxiang {
      width: 85%;
      background: rgba(71, 201, 243, 0.3);
      border-radius: 4px;
      margin-top: 10px;
      padding: 25px 70px;
      font-size: 11px;
      font-weight: 400;
      color: #adb8ba;
      line-height: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 180px;

      div {
        div {
          cursor: pointer;
          width: 70px;
        }

        width: 110px;
      }

      img {
        width: 70px;
        border-radius: 50%;
      }

      .selectToux {
        box-shadow: 0 0 6px 0 rgba(71, 201, 243, 0.64);
        border-radius: 50%;
        border: 2px solid #47c9f3;
      }
    }

    .one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 21px;
      margin-top: 18px;
      min-height: 50px;

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 15px;
        color: #adb8ba;
        font-family: "微软雅黑";

        .rrr {
          margin-left: 20px;
          font-size: 13px;
          font-weight: 400 !important;
          color: #4becff;
          opacity: 0.7;
        }

        .rrr:hover {
          opacity: 1;
        }

        span:first-child {
          width: 90px;
        }

        span:last-child {
          font-weight: 600;
          margin-left: 32px;
        }

        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
        }

        input {
          width: 117px;
          height: 40px;
          padding-left: 0.8rem;
          color: #fff;
          outline: none;
          opacity: 0.9;
          background: rgba(23, 188, 241, 0.2);
          border-radius: 4px;
          border: 0;
        }

        .long {
          width: 200px;
        }

        .chang {
          margin-left: 12px;
          width: 270px;
          height: 40px;
          padding-left: 0.8rem;
          color: #fff;
          outline: none;
          opacity: 0.9;
          background: rgba(23, 188, 241, 0.2);
          border-radius: 4px;
          border: 0;
        }
      }

      .right1 {
        display: flex;

        div:first-child {
          width: 90px;
          height: 28px;
          font-size: 13px;
          background-repeat: no-repeat;
          background-size: 100%;
          line-height: 30px;
          background-image: url("../../../assets/img/my/btn_3.png");
          font-weight: 400;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }

        div:last-child {
          width: 90px;
          height: 28px;
          font-size: 13px;
          background-repeat: no-repeat;
          background-size: 100%;
          line-height: 30px;
          background-image: url("../../../assets/img/my/button.png");
          font-weight: 400;
          color: #fff;
          margin-left: 12px;
          text-align: center;
          cursor: pointer;
        }
      }

      .right {
        cursor: pointer;
        width: 124px;
        height: 39px;
        font-size: 15px;
        background-repeat: no-repeat;
        font-weight: 400;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 25px;
        background-image: url("../../../assets/img/my/button.png");
        background-size: 100% 100%;
      }
    }

    .tou-left {
      width: 100px;
      // position: relative;
      // border-radius: 50%;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      span {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        color: #848492;
        background-color: rgba(0, 0, 0, 0.7);

        .upload-demo {
          width: 80px;

          /deep/ .el-upload {
            width: 100%;

            .btn {
              width: 100%;
              color: #848492;
              background-color: rgba(0, 0, 0, 0.7);
              border-color: rgba(0, 0, 0, 0.7);
            }
          }
        }
      }

      span:hover {
        cursor: pointer;
      }
    }

    .tou-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 38px;

      .tou-right-top {
        .tou1 {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }

          .tou1-span1 {
            font-size: 14px;
            font-weight: 400;
            color: #c3c3e2;
          }

          .tou1-span2 {
            background-color: #858493;
            border-radius: 3px;
            font-size: 12px;
            padding: 0 8px;
          }

          i {
            margin-left: 15px;
            font-size: 20px;
            color: #858493;
          }

          i:hover {
            cursor: pointer;
            color: #e9b10e;
          }
        }

        .tou2 {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #848492;

          .tou2-name {
            margin-right: 20px;

            i:hover {
              cursor: pointer;
              color: #e9b10e;
            }
          }
        }
      }

      .tou-right-bot {
        padding-bottom: 10px;
        // display: flex;
        // float: left;
        display: flex;
        justify-content: space-between;

        span {
          color: #848492;
          font-size: 14px;
        }

        span:first-child {
          color: #c3c3e2;
        }
      }
    }
  }

  .url {
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;

    .url1 {
      font-size: 14px;
      color: #848492;
    }

    .url2 {
      margin-top: 20px;
      display: flex;

      .url2-input {
        width: 380px;
        min-width: 100px;

        input {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          border-radius: 5px;
          width: 100%;
          color: #c3c3e2;
          font-size: 12px;
          background-color: #24252f;
          outline: none;
          box-sizing: border-box;
          border: none;
        }
      }

      .url2-btn {
        span {
          display: inline-table;
          height: 40px;
          margin-left: 20px;
          line-height: 40px;
          padding: 0 20px;
          background-color: #e9b10e;
          border-radius: 5px;
          font-weight: 600;
          color: #1a1c24;
          font-size: 15px;
          white-space: nowrap;
        }

        span:hover {
          cursor: pointer;
          background-color: #f5c432;
        }

        .url2-btn {
          background-color: #333542;
          color: #848492;
        }

        .url2-btn:hover {
          background-color: #3a3f50;
        }
      }
    }

    .url3 {
      margin-top: 20px;

      div {
        margin-right: 20px;
        font-size: 14px;
        color: #c3c3e2;

        strong {
          color: #e9b10e;
        }

        strong:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .invite {
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;

    .invite-list {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      span {
        margin-left: 20px;
        font-size: 16px;
        color: #848492;
      }
    }

    .invite-list1 {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #c3c3e2;

      span {
        font-weight: 600;
        color: #e9b10e;
        margin-left: 10px;
      }

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .number {
    padding: 10px 30px;
    background-color: #2b2c37;
    border-radius: 5px;

    .number1 {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      color: #848492;
      font-size: 16px;

      i {
        font-size: 20px;
      }

      i:hover {
        cursor: pointer;
        color: #e9b10e;
      }

      .number-span:hover {
        cursor: pointer;
      }
    }
  }

  .hobby {
    padding: 10px 30px;
    background-color: #2b2c37;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    .hobby1 {
      padding: 15px 0;
    }
  }
}
</style>