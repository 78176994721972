<template>
	<div class="lucky">
		<myslide :showNav="showNav"></myslide>
		<div class="lucky-box">
			<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
			<div class="lucky-warp">
				<div class="title" v-if="showNav">
					<span class="title-text">盲盒对战</span>
					<span class="en-title">LUCKY</span>
				</div>
				<div class="tabs-box">
					<div class="tabs-list">
						<div class="tabs-left">
							<span @click="selectTypes(0)" :class="selectType == 0 ? 'tabs-choice':''">全部</span>
							<span @click="selectTypes(1)" :class="selectType == 1 ? 'tabs-choice':''">等待中</span>
							<span @click="selectTypes(2)" :class="selectType == 2 ? 'tabs-choice':''">进行中</span>
							<span @click="selectTypes(3)" :class="selectType == 3 ? 'tabs-choice':''">已结束</span>
						</div>
						<div class="tabs-right" data-v-58fd0e1e=""></div>
					</div>
				</div>
				<div class="luc-list" v-if="!showNav">
					<div v-for="(item, index) in boxList2" :key="index">
						<ul>
							<li v-for="(item1, index1) in item" :key="index1">
								<div class="list-warp" @click="getBot(item1.name, item1.id)">
									<div class="list-img">
										<img :src="item1.img_main" />
										<img :src="item1.img_active" />
									</div>
									<div class="list-name">{{ item1.name }}</div>
									<div class="list-money">
										<img src="../assets/img/money.png" />
										<span>{{ item1.price }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>

				<div class="luc-list luc-list2">
					<div v-for="(item, index) in boxList2" :key="index">
						<ul>
							<li v-for="(item1, index1) in item" :key="index1">
								<div class="list-warp" @click="getBot(item1.name, item1.id)">
									<div class="list-img">
										<img :src="item1.img_main" />
										<img :src="item1.img_active" />
									</div>
									<div class="list-name">{{ item1.name }}</div>
									<div class="list-money">
										<img src="../assets/img/money.png" />
										<span>{{ item1.price }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="top" v-if="showNav">
					<div class="right">
						<span class="right-span right-span2" @click="openDrawer"><img
								src="../assets/img/button_back_img.png" alt="">创建房间</span>
						<span class="right-span right-span1" @click="goLuckyHistory">我的房间</span>
					</div>
					<div class="left">
						<span class="right-span right-span1" @click="goLuckyRule"></span>
						<span v-if="is_mobile" @click="topRank = true">
							查看排行榜
						</span>
						<div class="tooltip-container" @mousemove="showTooltip()" @mouseleave="hideTooltip()">
							对战规则<img src="../assets/img/wenhao.png" alt="">
							<span class="tooltip">
								<h4>1、创建活动时，可以选择参与人数，一场幸运星活动最多可以选择20个盲盒。</h4>
								<h4>2、玩家创建/参加活动后会立即扣除M币，中途无法退出，若15分钟后活动人数不足将会退还所有M币。</h4>
								<h4>3、幸运星天命之人和逆运都可用获得箱子花费总价值100%的经验</h4>
								<h4>4、天命之人与逆运的判定</h4>
								<p>&nbsp;（1）天命模式：开出饰品总价值高者为天命之人，总价值低者为逆运</p>
								<p>&nbsp;（2）逆运模式：开出饰品总价值低者为天命之人，总价值高者为逆运</p>
								<p>&nbsp;（3）天命之人将获得逆运的饰品，逆运也会获得网站奖励的饰品</p>
								<p>&nbsp;（4）若出现2位或2位以上总价值相同的最高者(逆运模式为最低者)，则所有玩家皆是天命，各自获得各自的饰品</p>
								<p>&nbsp;（5）组队：天命之人玩家将平分逆运玩家总和的饰品</p>
								<h4>5、天命值和逆运值</h4>
								<p>&nbsp;（1）开出的饰品总价值多少，则增加多少的天命值</p>
								<p>&nbsp;（2）逆运次数/总参与场次=逆运值</p>
								<h4>6、随机出现免费盲盒，由D1官方机器人创建</h4>
								<h4>7、排行榜奖励将在隔日0:15分发放</h4>
								<h4 style="color: rgb(139, 87, 251);">备注：请仔细查阅我们的规则，保持理性消费</h4>
							</span>
						</div>
					</div>
				</div>
				<div class="top" v-else>
					<span class="right">
						<span class="right-span right-span2" @click="openDrawer"><img
								src="../assets/img/button_back_img.png" alt="">创建房间</span>
						<span class="right-span right-span1" @click="goLuckyHistory">我的房间</span>
						<span class="left">
							<span v-if="is_mobile" @click="topRank = true">
								查看排行
							</span>
							<div class="tooltip-container" @click="showTooltip()">
								对战规则<img src="../assets/img/wenhao.png" alt="">
								<span class="tooltip">
									<h4>1、创建活动时，可以选择参与人数，一场幸运星活动最多可以选择20个盲盒。</h4>
									<h4>2、玩家创建/参加活动后会立即扣除M币，中途无法退出，若15分钟后活动人数不足将会退还所有M币。</h4>
									<h4>3、幸运星天命之人和逆运都可用获得箱子花费总价值100%的经验</h4>
									<h4>4、天命之人与逆运的判定</h4>
									<p>&nbsp;（1）天命模式：开出饰品总价值高者为天命之人，总价值低者为逆运</p>
									<p>&nbsp;（2）逆运模式：开出饰品总价值低者为天命之人，总价值高者为逆运</p>
									<p>&nbsp;（3）天命之人将获得逆运的饰品，逆运也会获得网站奖励的饰品</p>
									<p>&nbsp;（4）若出现2位或2位以上总价值相同的最高者(逆运模式为最低者)，则所有玩家皆是天命，各自获得各自的饰品</p>
									<p>&nbsp;（5）组队：天命之人玩家将平分逆运玩家总和的饰品</p>
									<h4>5、天命值和逆运值</h4>
									<p>&nbsp;（1）开出的饰品总价值多少，则增加多少的天命值</p>
									<p>&nbsp;（2）逆运次数/总参与场次=逆运值</p>
									<h4>6、随机出现免费盲盒，由D1官方机器人创建</h4>
									<h4>7、排行榜奖励将在隔日0:15分发放</h4>
									<h4 style="color: rgb(139, 87, 251);">备注：请仔细查阅我们的规则，保持理性消费</h4>
								</span>
							</div>
						</span>
					</span>
				</div>


				<div class="clear"></div>

				<el-drawer v-if="is_mobile" title="排行榜" size="100%" :append-to-body="true" :visible.sync="topRank"
					direction="rtl">

					<div class="ranking">
						<div class="rank-top">
							<div class="ranking-title"> 昨日之星 </div>
							<div class="ranking-avatar">
								<span><img :src="list1[0] && list1[0].img"></span>
								<p>{{list1[0].name}}</p>
							</div>
							<div class="ranking-avatar">
								<span><img :src="list1[1].img"></span>
								<p>{{list1[1].name}}</p>
							</div>
							<div class="ranking-avatar">
								<span><img :src="list1[2].img"></span>
								<p>{{list1[2].name}}</p>
							</div>
							<!-- <div class="two">
									<div class="winner-warp">
										<img class="winner" :class="star.img ? 'img-block' : 'img-none'"
											src="../assets/img/winner-bg.svg" alt="" />
										<img class="winner1" :src="star.img" />
									</div>
									<span>Lv1</span>
								</div>
								<div class="three">
									<span>{{ star.name }}</span>
								</div>
								<div class="four">{{ star.total_consume }}</div> -->
						</div>
						<div class="rank-bot">
							<div class="rank-bot-top">
								<span @click="getRankTop(0)" :class="rankTop == 0 ? 'select':''">今日排名</span>
								<span @click="getRankTop(1)" :class="rankTop == 1 ? 'select':''">昨日排名</span>
							</div>
							<div class="ranking-list">
								<ul>
									<li v-for="(item, index) in list1" :key="index" v-if="index <= 20">
										<div class="ripple" @click="touchstart">
											<div class="list-left">
												<div>
													<img v-if="index == 0" src="../assets/img/ranking/one.png" alt="">
													<img v-if="index == 1" src="../assets/img/ranking/two.png" alt="">
													<img v-if="index == 2" src="../assets/img/ranking/three.png" alt="">
													<span class="ranks"
														v-if="index != 0 && index != 1 && index != 2">{{index+1}}</span>
												</div>
												<div class="list-left1">
													<img :src="item.img" />
												</div>
												<div class="list-left2">
													<span>{{ item.name }}</span>
												</div>
											</div>
										</div>
										<div class="list-right">{{ item.total_consume }}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</el-drawer>

				<div class="room">
					<div class="ranking">
						<div class="rank-top">
							<div class="ranking-title"> 昨日之星 </div>
							<div class="ranking-avatar">
								<span><img :src="list1[0] && list1[0].img"></span>
								<p>{{list1[0].name}}</p>
							</div>
							<div class="ranking-avatar">
								<span><img :src="list1[1].img"></span>
								<p>{{list1[1].name}}</p>
							</div>
							<div class="ranking-avatar">
								<span><img :src="list1[2].img"></span>
								<p>{{list1[2].name}}</p>
							</div>
							<!-- <div class="two">
								<div class="winner-warp">
									<img class="winner" :class="star.img ? 'img-block' : 'img-none'"
										src="../assets/img/winner-bg.svg" alt="" />
									<img class="winner1" :src="star.img" />
								</div>
								<span>Lv1</span>
							</div>
							<div class="three">
								<span>{{ star.name }}</span>
							</div>
							<div class="four">{{ star.total_consume }}</div> -->
						</div>
						<div class="rank-bot">
							<div class="rank-bot-top">
								<span @click="getRankTop(0)" :class="rankTop == 0 ? 'select':''">今日排名</span>
								<span @click="getRankTop(1)" :class="rankTop == 1 ? 'select':''">昨日排名</span>
							</div>
							<div class="ranking-list">
								<ul>
									<li v-for="(item, index) in list1" :key="index" v-if="index <= 20">
										<div class="ripple" @click="touchstart">
											<div class="list-left">
												<div>
													<img v-if="index == 0" src="../assets/img/ranking/one.png" alt="">
													<img v-if="index == 1" src="../assets/img/ranking/two.png" alt="">
													<img v-if="index == 2" src="../assets/img/ranking/three.png" alt="">
													<span class="ranks"
														v-if="index != 0 && index != 1 && index != 2">{{index+1}}</span>
												</div>
												<div class="list-left1">
													<img :src="item.img" />
												</div>
												<div class="list-left2">
													<span>{{ item.name }}</span>
												</div>
											</div>
										</div>
										<div class="list-right">{{ item.total_consume }}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="clear"></div>
					<div class="room-box">
						<ul class="room-box-ul">
							<li v-for="(item, index) in list" :key="index" :class="[item.class, `bg_${item.status}`]"
								v-if="selectType == 0? true:selectType == item.status">
								<div class="roombox-warp" :class="{
                    two1: item.status == 1,
                    two3: item.status == 2,
                    two2: item.status == 3,
                  }">
									<div :class="{
                      'one two1 ': item.status == 1,
                      'one two3': item.status == 2,
                      'one two2': item.status == 3,
                    }">
										<div class="status-condiv">
											<div class="point" :class="[`point_${item.status}`]"></div>
											<div :class="[`text_${item.status}`]">
												{{ ["等待中", "进行中", "已结束"][item.status - 1] }}
											</div>
										</div>
										<!-- <img class="status-img" src="@/assets/new/dzwj.png" alt="" srcset="" /> -->
										<span class="wanjia">玩家</span>
										<span class="span3">{{ item.boxInfo.length }}回合</span>
									</div>
									<div :class="{
                      'two two1 ': item.status == 1,
                      'two two3': item.status == 2,
                      'two two2': item.status == 3,
                    }">
										<div class="two-bot">
											<div class="room-peo" v-if="item.mode == 2">
												<div class="pk-warp" @click="goLuckyRoom(item.id)"
													v-for="(item2, index2) in item.player_info" :key="index2">
													<span class="pk-tou" :class="
                              item2.class == 'pk-false' ? 'pk-tou-false' : ''
                            ">
														<img style="object-fit: cover" :class="item2.class"
															:src="item2.img" alt="" @ />
														<!-- 头像框 -->
														<img v-if="item2.image" style="object-fit: cover"
															:class="[item2.class, 'image-border']" :src="item2.image"
															alt="" />
													</span>
												</div>
											</div>

											<div class="room-peo" v-if="item.mode == 3">
												<div class="pk-warp" @click="goLuckyRoom(item.id)" v-for="(item3, index3) in [
                            ...item.play1,
                            ...item.play2,
                          ]" :key="index3">
													<span class="pk-tou" :class="
                              item3.class == 'pk-false' ? 'pk-tou-false' : ''
                            ">
														<img :class="item3.class" style="object-fit: cover"
															:src="item3.img" alt="" />
														<!-- 头像框 -->
														<img v-if="item3.image" style="object-fit: cover"
															:class="[item3.class, 'image-border']" :src="item3.image"
															alt="" />
													</span>
												</div>
											</div>

											<div class="room-peo" v-if="item.mode == 4">
												<div class="pk-warp">
													<div v-for="(item4, index4) in [
                              ...item.play1,
                              ...item.play2,
                            ]" :key="index4" class="vs-info">
														<span class="pk-tou" @click="goLuckyRoom(item.id)" :class="
                                item4.class == 'pk-false' ? 'pk-tou-false' : ''
                              ">
															<img :class="item4.class" style="object-fit: cover"
																:src="item4.img" alt="" />
															<!-- 头像框 -->
															<img v-if="item4.image" style="object-fit: cover"
																:class="[item4.class, 'image-border']"
																:src="item4.image" alt="" />
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="lucky-case">
										<div :class="{
										  'three two1 ': item.status == 1,
										  'three two3': item.status == 2,
										  'three two2': item.status == 3,
										}">
											<div class="box-detail" v-for="(item1, index1) in item.boxInfo"
												:key="index1">
												<img :src="item1.img_main" alt="" />
												<img :src="item1.img_active" alt="" />
											</div>
										</div>
									</div>
									<div class="card-footer">
										<div class="two-top">
											<img src="../assets/new/money.png" alt="" />
											<span>{{ item.price }}</span>
										</div>
										<div @click="goLuckyRoom(item.id)">
											{{item.status == 1 ? '加入':'查看'}}
										</div>
									</div>
								</div>
							</li>
							<li></li>
							<li></li>
						</ul>

						<div class="clear"></div>
						<div class="more-btn">
							<span @click="moveList()">查看更多</span>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>

		</div>
		<div class="sty-box" v-if="drawer" @click="drawer = false">

			<!--  右侧创建房间盒子 -->
			<div class="sty" @click.stop="handleClick">
				<div class="sty-warp">
					<div class="header-title">
						添加箱子
					</div>
					<div class="sty-one">
						<div class="sty-one-hint">模式</div>
						<div class="sty-one-pattern">
							<span v-for="(item, index) in patternList" :key="index" :class="item.state ? '' : 'span1'"
								@click="selectPattern(item.name)">{{ item.name }}</span>
						</div>
					</div>
					<div class="form-label">
						<img src="../assets/img/ranking/sss.png" alt="">
						<span>箱子列表</span>
					</div>
					<div class="sty-two">
						<div class="sty-two-list">
							<ul>
								<li v-for="(item, index) in boxList" :key="index" @click="addBox(index, item.price)">
									<div class="twolist-warp">
										<div class="twolist-top">
											<img :src="item.img_main" />
											<img :src="item.img_active" />
										</div>
										<div class="twolist-bot1">
											<span>{{ item.name }}</span>
										</div>
										<div class="twolist-bot">
											<img src="../assets/img/money.png" />
											<span>{{ item.price }}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="form-label">
						<img src="../assets/img/ranking/sss.png" alt="">
						<span>已经选择箱子</span>
						<span class="two">已添加{{currentBoxNum}}个箱子</span>
						<span class="three"><img src="../assets/img/money.png" alt=""><span>{{totalPrice}}</span></span>
					</div>
					<div class="sty-three">
						<div class="sty-three-list">
							<ul>
								<li v-for="(item, index) in selectList" :key="index"
									@click="remBox(item.name, item.price)">
									<div class="twolist-warp">
										<div v-if="item.state">
											<div class="twolist-top">
												<img :src="item.img_main" />
												<img :src="item.img_active" />
											</div>
											<div class="twolist-bot1">
												<span>{{ item.name }}</span>
											</div>
											<div class="twolist-bot">
												<img src="../assets/img/money.png" />
												<span>{{ item.price }}</span>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="sty-three-btn1">
							<el-button :disabled="loading2" class="sty-three-btn" @click="createRoom()">
								<i v-if="loading2" class="el-icon-loading"></i>确认添加
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <el-drawer title="我是标题" :visible.sync="drawerBot" :append-to-body="true" :with-header="false" direction="btt"
			class="box-bot">
			<div class="box-img">
				<div class="box-img-title">
					<span>{{ drawerName }}</span>
					<span class="el-icon-close"></span>
				</div>
				<div class="box-img-title1">
					<span>包含以下奖励</span>
				</div>
				<div class="box-img-list">
					<ul>
						<li :class="getColorPz(item.color)" v-for="(item, index) in drawerList" :key="index">
							<div class="box-img1">
								<img :src="item.img" />
							</div>
							<div class="probability">
								{{item.probability}}%
							</div>
							<div class="box-img-name" :title="item.name">
								<div class="list-pirce">
									<div class="pirce-left">
										<img src="../assets/img/money.png" />
										<span>{{ item.price }}</span>
									</div>
									<div class="pirce-right">
										{{extractValuesInParentheses(item.name)}}
									</div>
								</div>
								{{ getFrist(item.name) }} | {{ getLast(item.name) }}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</el-drawer> -->
		<div class="biaoti-box" v-if="drawerBot">
			<div class="top">
				<span>包含以下奖励</span>
				<span @click="drawerBot = false">
					<img src="../assets/img/ranking/cha.png" alt="">
				</span>
			</div>
			<div class="bottom">
				<ul>
					<li :class="getColorPz(item.color)" v-for="(item, index) in drawerList" :key="index">
						<div class="box-img-name" :title="item.name">
							{{ getFrist(item.name) }} | {{ getLast(item.name) }}
						</div>
						<div class="box-img1">
							<img :src="item.img" />
						</div>
						<div class="libottom">
							<div class="pirce-right">
								{{extractValuesInParentheses(item.name)}}
							</div>
							<div class="list-pirce">
								<div class="pirce-left">
									<img src="../assets/img/money.png" />
									<span>{{ item.price }}</span>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<myhomebot></myhomebot>
	</div>
</template>

<script>
	import myhomebot from "@/components/my_homebot.vue";
	import myinform from "@/components/my_inform.vue";
	import SockJS from "sockjs-client";
	import navheader from "@/components/navheader.vue";
	import Stomp from "stompjs";
	import Utils from "./../assets/js/util.js";
	import myslide from "@/components/my_slide1.vue";

	import {
		battle
	} from "@/api/socket.js";

	export default {
		components: {
			myslide,
			myhomebot,
			myinform,
			navheader
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				loading2: false,
				drawerList: [],
				drawerName: "",
				drawerBot: false,
				loading1: false,
				sockBattleData: "",
				loading: true,
				websock: "",
				timer: "",
				awaitRoom: 0,
				currentBoxNum: 0,
				currentBoxTotalPrice: 0,
				totalPrice: 0,
				mode: "2",
				page: 1,
				pageSize: 20,
				boxInfo: [],
				list: [],
				star: {},
				list1: [],
				boxList: [],
				boxList2: [],
				selectType: 0,
				selectList: [{
						state: false,
						name: ""
					},
					{
						state: false,
						name: ""
					},
					{
						state: false,
						name: ""
					},
					{
						state: false,
						name: ""
					},
					{
						state: false,
						name: ""
					},
					{
						state: false,
						name: ""
					},
				],
				rankTop: 0,
				drawer: false, //右侧导航状态
				patternList: [
					//选择模式
					{
						name: "2人",
						state: true,
						val: 2
					},
					{
						name: "3人",
						state: false,
						val: 3
					},
					{
						name: "4人",
						state: false,
						val: 4
					},
				],
				peopleObj: {
					img: require("../assets/img/jiapeople.png"),
					class: "pk-false",
					border: "1px dashed #e9b10e",
				},
				img: require("../assets/img/15mdpi.png"),

				is_mobile: false,
				topRank: false,
			};
		},
		watch: {
			currentBoxTotalPrice(val) {
				let _this = this;
				_this.totalPrice = val.toFixed(2);
			},
		},

		methods: {
			getColorPz(color) {
				if (color == "#007acc") {
					return "blue";
				}
				if (color == "#8a2be2") {
					return "blue";
				}
				if (color == "#FFA500") {
					return "yellow";
				}
				if (color == "#FF4500") {
					return "red";
				}
			},
			getFrist(e) {
				const resultArray = this.splitAndRemoveParentheses(e);
				return resultArray[0];
			},
			selectTypes(e) {
				this.selectType = e;
				this.getList()
			},
			getLast(e) {
				const resultArray = this.splitAndRemoveParentheses(e);
				return resultArray[1];
			},
			splitAndRemoveParentheses(input) {
				const parts = input.split("|");
				const result = parts.map(part => {
					const indexOfParenthesis = part.indexOf("(");
					if (indexOfParenthesis !== -1) {
						return part.substring(0, indexOfParenthesis).trim();
					}
					return part.trim();
				});
				return result;
			},
			extractValuesInParentheses(input) {
				const regex = /\((.*?)\)/g;
				const matches = [];
				let match;

				while ((match = regex.exec(input)) !== null) {
					matches.push(match[1]);
				}

				return matches[0];
			},
			showTooltip() {
				const tooltipContent = document.querySelector('.tooltip');
				if (tooltipContent.style.opacity == 1 && !this.showNav) {
					this.hideTooltip();
					return
				}
				tooltipContent.style.opacity = '1';
			},
			hideTooltip() {
				const tooltipContent = document.querySelector('.tooltip');
				tooltipContent.style.opacity = '0';
			},
			isMobile() {
				let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				);
				return flag;
			},
			getBot(name, id) {
				this.drawerBot = true;
				this.drawerName = name;
				let param = {
					box_id: id,
				};
				this.$axios
					.post("/index/Box/boxInfo", this.$qs.stringify(param))
					.then((res) => {
						let data = res.data;
						if (data.status == 1) {
							this.drawerList = data.data.box_skins;
						}
					});
			},
			//对战排行
			rankList() {
				this.$axios.post("/index/Battle/ranking", {
					day: 'last'
				}).then((res) => {
					var data = res.data;
					if (data.status == "1") {
						this.list1 = data.data.rank;
						this.list1[1] = data.data.rank[0];
						this.list1[2] = data.data.rank[0];
						this.list1[3] = data.data.rank[0];
						this.list1[4] = data.data.rank[0];
						this.list1[5] = data.data.rank[0];
						this.list1[6] = data.data.rank[0];
						this.list1[7] = data.data.rank[0];
						this.list1[8] = data.data.rank[0];
						this.list1[9] = data.data.rank[0];
						this.star = data.data.star;
						for (let i = 0; i < this.list1.length; i++) {
							this.list1[i].total_consume = this.list1[i].total_consume.toFixed(2);
						}
						if (this.star) {
							this.star.total_consume = this.star.total_consume.toFixed(2);
						}
					}
				});
			},
			getRankTop(e) {
				this.rankTop = e;
				if (e == 0) {
					this.$axios.post("/index/Battle/ranking", {
						day: 'last'
					}).then((res) => {
						var data = res.data;
						if (data.status == "1") {
							this.list1 = data.data.rank;
							this.list1[1] = data.data.rank[0];
							this.list1[2] = data.data.rank[0];
							this.list1[3] = data.data.rank[0];
							this.list1[4] = data.data.rank[0];
							this.list1[5] = data.data.rank[0];
							this.list1[6] = data.data.rank[0];
							this.list1[7] = data.data.rank[0];
							this.list1[8] = data.data.rank[0];
							this.list1[9] = data.data.rank[0];
							this.star = data.data.star;
							for (let i = 0; i < this.list1.length; i++) {
								this.list1[i].total_consume = this.list1[i].total_consume.toFixed(2);
							}
							if (this.star) {
								this.star.total_consume = this.star.total_consume.toFixed(2);
							}
						}
					});
				} else {
					this.$axios.post("/index/Battle/ranking", {
						day: 'day'
					}).then((res) => {
						var data = res.data;
						if (data.status == "1") {
							this.list1 = data.data.rank;
							this.list1[1] = data.data.rank[0];
							this.list1[2] = data.data.rank[0];
							this.list1[3] = data.data.rank[0];
							this.list1[4] = data.data.rank[0];
							this.list1[5] = data.data.rank[0];
							this.list1[6] = data.data.rank[0];
							this.list1[7] = data.data.rank[0];
							this.list1[8] = data.data.rank[0];
							this.list1[9] = data.data.rank[0];
							this.star = data.data.star;
							for (let i = 0; i < this.list1.length; i++) {
								this.list1[i].total_consume = this.list1[i].total_consume.toFixed(2);
							}
							if (this.star) {
								this.star.total_consume = this.star.total_consume.toFixed(2);
							}
						}
					});
				}
			},
			//推送
			initWebSocket() {
				const wsuri = battle();
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onopen = this.websocketonopen;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;
			},

			websocketonopen() {
				//let actions = { test: "12345" };
				//this.websocketsend(JSON.stringify(actions));
			},

			websocketonerror() {
				this.initWebSocket();
			},

			websocketonmessage(d) {
				// console.log("接收:",JSON.parse(d.data));
				// console.log(JSON.parse(d.data));
				this.sockBattleList(d.data);
			},

			websocketsend(Data) {
				if (this.websock.readyState === WebSocket.OPEN) {
					this.websock.send(Data);
				}
			},

			websocketclose(e) {
				// console.log("close:", e);
			},

			updateBattleList(item) {
				this.list.forEach((ele, index) => {
					if (ele.id == item.id) {
						console.log(ele)
						this.list[index].class = "";
						this.$set(this.list, index, item);
						if (item.status == 2) {
							this.list.sort(function(a, b) {
								return a.status - b.status;
							})
						}
						// console.log("更新：", this.list);
					}
				});
			},

			addBattleList(item) {
				if (this.list.length == 0) {
					this.list.unshift(item);
					return;
				}
				let insert = false;
				// for (let i = 0; i < this.list.length; i++) {
				// 	if (this.list[i].status == 3 && this.list.length >= 12) {
				// 		item.class = "replace-room";
				// 		this.$set(this.list, i, item);
				// 		insert = true;
				// 		break;
				// 	}
				// }
				var that = this;
				item.class = "replace-room";
				this.list.splice(-1, 1)
				this.list.unshift(item);
				insert = true;
				this.$forceUpdate()
				setTimeout(function() {
					that.list[0]['class'] = "";
					that.list[1]['class'] = "";
					that.list[2]['class'] = "";
				}, 2000)
				let e = this.list.filter((ele, index, arr) => {
					return ele.id == item.id;
				});
				if (!insert && e.length == 0) {
					this.list.unshift(item);
				}
			},

			sockBattleList(d) {
				let data = JSON.parse(d);

				if (data.info == undefined) {
					return;
				}
				if (data.state == "update") {
					this.updateBattleList(data.info);
				} else if (data.state == "add") {
					this.addBattleList(data.info);
				}

				let _this = this;
				this.awaitRoom = 0;
				for (let i = 0; i < this.list.length; i++) {
					this.list[i].boxInfo = this.list[i].boxInfo;
					this.list[i].price = 0;
					var info = this.list[i].boxInfo;
					for (let j = 0; j < info.length; j++) {
						this.list[i].price += Number(info[j].price);
					}
					this.list[i].price = this.list[i].price.toFixed(2);
					if (this.list[i].status == "1") {
						this.awaitRoom = this.awaitRoom + 1;
					}
				}
				//几个人参与
				for (let i = 0; i < this.list.length; i++) {
					let play = JSON.parse(JSON.stringify(this.list[i].player_info));
					let mode = this.list[i].mode;
					for (let j = 0; j < mode - this.list[i].player_info.length; j++) {
						play.push(this.peopleObj);
					}
					for (let x = 0; x < play.length; x++) {
						if (play[x].id) {
							play[x].class = "pk-true";
							play[x].img = play[x].img;
						}
					}
					this.list[i].player_info = play;

					if (mode == "3") {
						this.list[i].play1 = [];
						this.list[i].play2 = [];
						this.list[i].play1.push(play[0]);
						this.list[i].play2.push(play[1]);
						this.list[i].play2.push(play[2]);
					}
					if (mode == "4") {
						this.list[i].play1 = [];
						this.list[i].play2 = [];
						this.list[i].play1.push(play[0]);
						this.list[i].play1.push(play[1]);
						this.list[i].play2.push(play[2]);
						this.list[i].play2.push(play[3]);
					}
				}
				//排序
			},

			touchstart(event) {
				// console.log("touch");
				var style = document.createElement("style");
				style.innerHTML = `.ripple::after{top:${event.offsetY - 25}px;left:${
        event.offsetX - 180
      }px;}`;
				document.head.appendChild(style);
			},

			//查看更多
			moveList() {
				// console.log(this.pageSize);
				let _this = this;
				let param = {
					page: this.page,
					pageSize: this.pageSize + 12,
				};
				_this.$axios
					.post("/index/Battle/battleList", _this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.pageSize = this.pageSize + 12;
							//let list = data.data.battleList;
							this.list = data.data.battleList;
							for (let i = 0; i < this.list.length; i++) {
								this.list[i].boxInfo = this.list[i].boxInfo;
								this.list[i].price = 0;
								var info = this.list[i].boxInfo;
								for (let j = 0; j < info.length; j++) {
									this.list[i].price += Number(info[j].price);
								}
								this.list[i].price = this.list[i].price.toFixed(2);
								if (this.list[i].status == "1") {
									this.awaitRoom = this.awaitRoom + 1;
								}
							}
							//几个人参与
							for (let i = 0; i < this.list.length; i++) {
								let play = JSON.parse(JSON.stringify(this.list[i].player_info));
								let mode = this.list[i].mode;
								for (let j = 0; j < mode - this.list[i].player_info.length; j++) {
									play.push(this.peopleObj);
								}
								for (let x = 0; x < play.length; x++) {
									if (play[x].id) {
										play[x].class = "pk-true";
										play[x].img = play[x].img;
									}
								}
								this.list[i].player_info = play;

								if (mode == "3") {
									this.list[i].play1 = [];
									this.list[i].play2 = [];
									this.list[i].play1.push(play[0]);
									this.list[i].play2.push(play[1]);
									this.list[i].play2.push(play[2]);
								}
								if (mode == "4") {
									this.list[i].play1 = [];
									this.list[i].play2 = [];
									this.list[i].play1.push(play[0]);
									this.list[i].play1.push(play[1]);
									this.list[i].play2.push(play[2]);
									this.list[i].play2.push(play[3]);
								}
							}
							this.list.forEach(e => {
								e['class'] = "replace-room";
							})
							setTimeout(function() {
								_this.list.forEach(e => {
									e['class'] = "";
								})
								_this.$forceUpdate();
							}, 2000)
						}
					});
			},
			//对战列表
			getList(page) {
				let _this = this;
				let param = {
					page: this.page,
					pageSize: 12,
				};
				_this.$axios
					.post("/index/Battle/battleList", _this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.list = data.data.battleList;
							for (let i = 0; i < this.list.length; i++) {
								this.list[i].boxInfo = this.list[i].boxInfo;
								this.list[i].price = 0;
								var info = this.list[i].boxInfo;
								for (let j = 0; j < info.length; j++) {
									this.list[i].price += Number(info[j].price);
								}
								this.list[i].price = this.list[i].price.toFixed(2);
								if (this.list[i].status == "1") {
									this.awaitRoom = this.awaitRoom + 1;
								}
							}
							//几个人参与
							for (let i = 0; i < this.list.length; i++) {
								let play = JSON.parse(JSON.stringify(this.list[i].player_info));
								let mode = this.list[i].mode;
								for (let j = 0; j < mode - this.list[i].player_info.length; j++) {
									play.push(this.peopleObj);
								}
								for (let x = 0; x < play.length; x++) {
									if (play[x].id) {
										play[x].class = "pk-true";
										play[x].img = play[x].img;
									}
								}
								this.list[i].player_info = play;

								if (mode == "3") {
									this.list[i].play1 = [];
									this.list[i].play2 = [];
									this.list[i].play1.push(play[0]);
									this.list[i].play2.push(play[1]);
									this.list[i].play2.push(play[2]);
								}
								if (mode == "4") {
									this.list[i].play1 = [];
									this.list[i].play2 = [];
									this.list[i].play1.push(play[0]);
									this.list[i].play1.push(play[1]);
									this.list[i].play2.push(play[2]);
									this.list[i].play2.push(play[3]);
								}
							}
							this.list.forEach(e => {
								e['class'] = "replace-room";
							})
							setTimeout(function() {
								_this.list.forEach(e => {
									e['class'] = "";
								})
								_this.$forceUpdate();
							}, 2000)
							//排序
						}
					});
			},
			//盲盒选择列表
			getBattleBoxList() {
				let _this = this;
				_this.$axios.post("/index/Battle/battleBoxList").then((res) => {
					res.data.data.forEach((element) => {
						element.num = 0;
					});
					_this.boxList = res.data.data;
					_this.loading = true;

					let arr = [];
					for (let i = 0; i < this.boxList.length / 10; i++) {
						this.boxList2.push(arr);
					}
					for (let i = 0; i < this.boxList2.length; i++) {
						this.boxList2[i] = this.boxList.slice(i * 10, (i + 1) * 10);
					}
				});
			},
			//创建活动
			createRoom() {
				if (!this.$store.state.token) {
					this.$store.commit("getLogin", true);
					return;
				}

				let _this = this;
				_this.selectList.forEach((e) => {
					if (e.state) {
						e.num = 1;
						_this.boxInfo.push(e);
					}
				});
				if (_this.boxInfo.length == 0) {
					_this.$message({
						message: "请选择箱子",
						type: "warning",
					});
					return;
				}
				this.loading2 = true;
				// console.log(_this.boxInfo);
				let param = {
					mode: _this.mode,

					boxInfo: _this.boxInfo,
				};
				_this.$axios
					.post("/index/Battle/createRoom", _this.$qs.stringify(param))
					.then((res) => {
						// console.log(res);
						let data = res.data;
						// console.log(data);
						if (data.status == 1) {
							this.loading2 = false;
							this.$store.commit("getMoney", data.data.total_amount);
							Utils.$emit("money", data.data.total_amount);
							// _this.$bus.$emit("loading", true);

							//发送
							/* let sendData = {
							   state: "add",
							   battle_id: data.data.battle_id,
							 };
							 _this.websocketsend(JSON.stringify(sendData));*/

							_this.$router.push({
								path: `/LuckyRoom`,
								query: {
									id: data.data.battle_id,
								},
							});
						} else {
							//创建失败
							_this.$message({
								message: data.msg,
								type: "warning",
							});
							_this.boxInfo = [];
							let selectListCopy = [{
									state: false,
									name: ""
								},
								{
									state: false,
									name: ""
								},
								{
									state: false,
									name: ""
								},
								{
									state: false,
									name: ""
								},
								{
									state: false,
									name: ""
								},
								{
									state: false,
									name: ""
								},
							];
							_this.selectList = selectListCopy;
							_this.currentBoxNum = 0;
							_this.totalPrice = 0;
							// console.log(this.boxList);
							for (let i = 0; i < this.boxList.length; i++) {
								this.boxList[i].num = 0;
							}
						}
					});
			},
			//点击挑战房间
			goLuckyRoom(id) {
				for (let i = 0; i < this.list.length; i++) {
					if (id == this.list[i].id) {
						if (this.list[i].status == 1) {
							if (!this.$store.state.token) {
								this.$store.commit("getLogin", true);
							} else {
								this.$router.push({
									path: `/LuckyRoom`,
									query: {
										id: id,
									},
								});
							}
						} else {
							this.$router.push({
								path: `/LuckyRoom`,
								query: {
									id: id,
								},
							});
						}
					}
				}
			},
			//跳转至活动规则
			goLuckyRule() {
				this.$router.push({
					path: `/LuckyRule`,
				});
			},
			//跳转至活动历史
			goLuckyHistory() {
				this.$router.push({
					path: `/LuckyHistory`,
				});
			},
			//右侧创建房间
			openDrawer() {
				this.drawer = true;
			},
			//选择几人模式
			selectPattern(name) {
				for (let i = 0; i < this.patternList.length; i++) {
					if (this.patternList[i].name == name) {
						this.patternList[i].state = true;
						this.mode = this.patternList[i].val;
					} else {
						this.patternList[i].state = false;
					}
				}
				// console.log(this.mode);
			},
			//选择盒子
			addBox(index, price) {
				if (this.currentBoxNum < 6) {
					this.currentBoxNum++;
					this.currentBoxTotalPrice = this.currentBoxTotalPrice + Number(price);
				}
				var boxnum = 0;
				for (let i = 0; i < this.boxList.length; i++) {
					boxnum += this.boxList[i].num;
				}
				if (boxnum < 6) {
					this.boxList[index].num += 1;
					//console.log(this.selectList)
					for (let i = 0; i < this.selectList.length; i++) {
						if (this.selectList[i].state == false) {
							this.selectList[i].state = true;
							this.selectList[i].box_id = this.boxList[index].id;
							this.selectList[i].name = this.boxList[index].name;
							this.selectList[i].price = this.boxList[index].price;
							this.selectList[i].img_main = this.boxList[index].img_main;
							this.selectList[i].img_active = this.boxList[index].img_active;
							break;
						}
					}
				} else {
					this.$notify({
						title: "提示",
						message: "盲盒数量已达上限",
					});
				}
				// console.log(this.selectList);
			},
			//去掉盒子
			remBox(name, price) {
				if (name) {
					if (this.currentBoxNum > 0) {
						this.currentBoxNum--;
						this.currentBoxTotalPrice = this.currentBoxTotalPrice - Number(price);
					}
				}
				for (let i = 0; i < this.boxList.length; i++) {
					if (name == this.boxList[i].name) {
						this.boxList[i].num--;
					}
				}
				for (let i = 0; i < this.selectList.length; i++) {
					if (this.selectList[i].name == name) {
						this.selectList[i].name = "";
						this.selectList[i].state = false;
						break;
					}
				}
				this.selectList.sort((a, b) => b.state - a.state);
			},
		},
		mounted() {
			let _this = this;
			_this.getList();
			_this.getBattleBoxList();
			_this.rankList();
			this.$bus.$emit("loading", false);

			Utils.$on("update", function(data) {
				_this.sockBattleList(data);
			});
		},
		beforeDestroy: function() {},
		created() {
			this.initWebSocket();
			this.is_mobile = this.isMobile();
			window.addEventListener("resize", () => {
				this.is_mobile = this.isMobile();
			});
		},
		destroyed() {
			//clearInterval(this.timer);
			this.websock.close();
		},
	};
</script>

<style lang="less" scoped>
	@import "./css/lucky.less";

	.biaoti-box {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		background-color: rgba(0, 0, 0, .641) !important;
		border-radius: 5px !important;
		width: 1120px;
		height: 600px;
		padding: 16px;
		border: 1px solid #3a4525;
		box-shadow: 0 17px 57px 0 rgba(0, 0, 0, .77);
		color: white;
		font-family: '微软雅黑';
		z-index: 99999;

		.top {
			font-size: 20px;
			display: flex;
			justify-content: space-between;

			img {
				width: 30px;
			}
		}

		.bottom {
			display: flex;
			align-items: center;
			overflow-y: auto;
			margin-top: 22px;

			ul {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				height: 540px;
				font-size: 12px;

				li {
					margin-top: 12px;
					margin-left: 4px;
					width: 180px;
					height: 180px;
					background: #191926;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.libottom {
						width: 80%;
						display: flex;
						align-items: center;
						justify-content: space-between;
					}

					.pirce-left {
						display: flex;
						align-items: center;

						span {
							margin-left: 4px;
							color: rgb(117, 220, 158);
							font-weight: 600;
						}
					}

					.box-img1 img {
						width: 100px;
						height: 100px;
					}

					img {
						width: 20px;
					}
				}
			}
		}
	}

	::v-deep {
		.el-drawer__body {
			padding: 10px 0px 10px 15px;
			background: #1f1f23;
			// overflow-y: auto;
		}

		.el-drawer__header {
			background: #1f1f23;
			margin-bottom: 0;
			padding-bottom: 20px;
		}
	}

	.replace-room {
		transform-style: preserve-3d;
		backface-visibility: hidden;
		-webkit-animation-name: flips;
		animation-name: flips;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
		animation-timing-function: linear;
	}

	@keyframes flips {
		0% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
			transform: perspective(400px) rotateX(90deg);
			-webkit-animation-timing-function: ease-in;
			animation-timing-function: ease-in;
			opacity: 0;
		}

		40% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
			transform: perspective(400px) rotateX(-20deg);
			-webkit-animation-timing-function: ease-in;
			animation-timing-function: ease-in;
		}

		60% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
			transform: perspective(400px) rotateX(10deg);
			opacity: 1;
		}

		80% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
			transform: perspective(400px) rotateX(-5deg);
		}

		100% {
			-webkit-transform: perspective(400px);
			transform: perspective(400px);
		}
	}

	.ranking {
		width: 280px;
		margin-right: 16px;

		.rank-top {
			background: url('../assets/img/ranking/topback.png') no-repeat;
			background-size: 100% 100%;
			// padding: 20px;
			border-radius: 5px;
			padding: 0 0;
			height: 300px;
			position: relative;

			.ranking-title {
				position: absolute;
				top: 5%;
				left: 50%;
				margin-left: -30px;
				background: linear-gradient(98.13deg, #FFEEC1 -10.04%, rgba(255, 140, 195, .55) 108.13%);
				background-clip: border-box;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}

			.ranking-avatar {
				position: absolute;
				width: 90px;
				height: 90px;
				margin-left: -45px;
				text-align: center;

				img {
					margin-top: 12px;
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}

				p {
					font-size: 12px;
					height: 20px;
					line-height: 20px;
					font-weight: 700;
					color: #cdf908;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-family: '微软雅黑';
				}
			}

			.ranking-avatar:nth-of-type(2) {
				left: 50%;
				top: 15%;
				background: url('../assets/img/ranking/ranking-one.png') top no-repeat;
				background-size: auto;
				background-size: 100%;
			}

			.ranking-avatar:nth-of-type(3) {
				left: 30%;
				top: 43%;
				background: url('../assets/img/ranking/ranking-tow.png') top no-repeat;
				background-size: auto;
				background-size: 100%;
			}

			.ranking-avatar:nth-of-type(4) {
				left: 70%;
				top: 43%;
				background: url('../assets/img/ranking/ranking-three.png') top no-repeat;
				background-size: auto;
				background-size: 100%;
			}

			.one {
				text-align: center;
				font-size: 24px;
				color: #c3c3e2;
			}

			.two {
				// width: 60px;
				height: 60px;
				text-align: center;
				margin-top: 15px;
				position: relative;
				background: url(../assets/img/maisui.svg) center no-repeat;

				.winner-warp {
					width: 60px;
					height: 60px;
					// border-radius: 50%;
					//overflow: hidden;
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: -30px;
					z-index: 66;
				}

				.winner1 {
					width: 60px;
					height: 60px;
					border-radius: 50%;
					overflow: hidden;
					position: absolute;
					top: 0;
					left: 0;
					// margin-left: -30px;
					z-index: 66;
				}

				.winner {
					width: 200px;
					height: 200px;
					position: absolute;
					top: -70px;
					left: -75px;
					animation: jss163 5000ms linear infinite;
					user-select: none;
					pointer-events: none;
					z-index: 2;
				}

				@-webkit-keyframes jss163 {
					0% {
						transform: rotate(0deg);
					}

					100% {
						transform: rotate(360deg);
					}
				}

				span {
					position: absolute;
					left: 50%;
					bottom: 0;
					font-size: 12px;
					border-radius: 2px;
					padding: 0 6px;
					background-color: #858493;
					margin-left: -14px;
					z-index: 88;
				}
			}

			.three {
				margin-top: 5px;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					margin-left: 5px;
					color: #c3c3e2;
					font-size: 14px;
				}

				img {
					width: 15px;
					height: 15px;
				}
			}

			.four {
				text-align: center;
				margin-top: 5px;
				font-size: 16px;
				color: #e9b10e;
			}
		}

		.rank-bot {
			margin-top: 15px;
			width: 100%;
			min-height: 20px;
			background: #4C2473;

			.rank-bot-top {
				height: 40px;
				display: flex;
				align-items: center;
				background: linear-gradient(270.48deg, rgba(214, 50, 255, .4) -2.44%, rgba(139, 87, 251, .4) 58.11%, rgba(50, 57, 119, .4) 103.24%);

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50%;
					height: 40px;
					color: #00f4e8;
				}

				span:hover {
					background: #4e5ae8;
					color: white;
				}

				.select {
					color: white;
					background: #4e5ae8;
				}
			}

			.title {
				text-align: center;
				font-size: 20px;
				color: #c3c3e2;
			}

			.ranking-list {
				margin-top: 12px;

				ul li {
					padding: 16.6px 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					// transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

					.list-left {
						display: flex;
						align-items: center;

						.ranks {
							display: block;
							width: 20px;
							color: #c672cb;
							font-family: '微软雅黑';
							text-align: center;
						}

						img {
							width: 20px;
							height: 22px;
						}

						.list-left1 {
							margin-left: 10px;
							position: relative;

							img {
								border-radius: 50%;
								width: 30px;
								height: 30px;
							}

							span {
								position: absolute;
								left: 50%;
								bottom: 0;
								font-size: 12px;
								border-radius: 2px;
								padding: 0 6px;
								background-color: #858493;
								margin-left: -14px;

							}
						}

						.list-left2 {
							margin-left: 12px;
							display: flex;
							align-items: center;

							img {
								margin-right: 5px;
								width: 15px;
								height: 15px;
							}

							span {
								font-size: 12px;
								color: #fff;
							}
						}
					}
				}

				ul li:hover {
					cursor: pointer;
				}

				/* ul li:active{
              background-color: #fff;
            } */
			}

			.list-right {
				font-size: 14px;
				color: #ff9c28;
				font-family: '微软雅黑';
			}
		}
	}

	.lucky {
		width: 100%;
		height: 100%;
		background-color: rgba(22, 14, 57, 1);
		overflow: hidden;
		overflow-y: auto;

		.lucky-box {
			background: url('../assets/img/main-bg.png') no-repeat;
			height: 100%;
			background-position: 50% 0%;
			background-size: 95%;
			padding: 0 32px;
			padding-top: 32px;

			.lucky-warp {
				width: 100vw;
				max-width: 1440px;
				margin: auto;

				.title {
					height: 57px;
					font-family: YouSheBiaoTiHei;
					position: relative;
					max-width: 700px;
					width: 100vw;
					margin: 0 auto;
					padding: 50px 0;
				}

				.title::after {
					content: " ";
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					background: url('../assets/img/title-bg.png') center no-repeat;
					background-size: auto;
					background-size: 100%;
					z-index: 10;
					top: 0;
				}

				.title .title-text {
					position: absolute;
					top: 52px;
					font-size: 36px;
					z-index: 11;
					left: 50%;
					margin-left: -100px;
					width: 200px;
					text-align: center;
					color: #fff;
				}

				.title .en-title {
					position: absolute;
					top: 20px;
					font-size: 50px;
					color: #392782;
					opacity: .3;
					z-index: 9;
					left: 50%;
					margin-left: -100px;
					width: 200px;
					text-align: center;
				}

				.tabs-list {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1.5px solid #502387;
					margin-top: 20px;

					span {
						width: 126px;
						height: 40px;
						line-height: 40px;
						color: rgba(219, 98, 215, .8);
						position: relative;
						display: inline-block;
						text-align: center;
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
					}

					span:hover {
						cursor: pointer;
						color: #fff;
						background: linear-gradient(269.34deg, #CD1FF8 -51.56%, #8B57FB 37.03%, #3648F3 103.07%);
					}

					.tabs-choice {
						cursor: pointer;
						color: #fff;
						background: linear-gradient(269.34deg, #CD1FF8 -51.56%, #8B57FB 37.03%, #3648F3 103.07%);
					}
				}

				.top {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.tooltip-container {
						position: relative;
						display: inline-block;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							margin-left: 12px;
						}
					}

					.tooltip {
						width: 450px;
						background: rgba(33, 19, 66, .9) !important;
						border: 2px solid rgba(146, 79, 255, .3) !important;
						color: #fff !important;
						border-radius: 0 !important;
						inset: 30px 0px auto auto;
						margin: 0px;
						position: absolute;
						padding: 12px;
						border-radius: 5px;
						z-index: 1;
						display: none;
						transition: opacity .5s;
						z-index: 99999;

					}

					.tooltip-container:hover .tooltip {
						display: block;
					}

					.tooltip.bottom {
						top: 100%;
						/* Display below the text */
						margin-top: 5px;
						/* Add some spacing */
					}

					.tooltip.top {
						bottom: 100%;
						/* Display above the text */
						margin-bottom: 5px;
						/* Add some spacing */
					}

					.left {
						color: #db62d7;
						cursor: pointer;
					}

					.right {
						display: flex;
						align-items: center;

						.right-span {
							padding: 4px 22px 4px 12px;
							font-size: 15px;
						}

						.right-span:hover {
							color: #db62d7;
						}

						.right-span1 {
							margin-left: 10px;
							height: 30px;
							line-height: 30px;
							width: 80px;
							text-align: center;
							color: white;
							background-color: #5d5d5d;
							padding: 4px 12px 4px 12px;
						}

						.right-span1:hover {
							cursor: pointer;
						}

						.right-span2 {
							color: white;
							background: url('../assets/img/button_back.png') no-repeat;
							background-size: 100% 100%;
							display: flex;
							align-items: center;
							justify-content: center;

							img {
								width: 30px;
							}
						}

						.right-span2:hover {
							cursor: pointer;
						}
					}
				}

				.luc-list {
					height: 260px;
					margin-top: 20px;

					ul {
						width: 100%;
						height: 260px;
						border-radius: 5px;
						overflow-x: scroll;
						overflow-y: hidden;
						white-space: nowrap;
						/*在一行内显示*/
						display: flex;

						li {
							flex-shrink: 0;
							height: 100%;
							float: left;
							width: 10%;

							.list-warp::before {
								content: " ";
								display: block;
								background: url('../assets/img/zhe.png');
								background-size: cover;
								height: 30px;
								width: 30px;
							}

							.list-warp {
								height: 100%;
								padding-top: 10px;

								.list-img {
									text-align: center;
									position: relative;

									img {
										// height: 80px;
										width: 100px;
										// height: 75px;
									}

									img:last-child {
										width: 60px;
										height: auto;
										position: absolute;
										top: 0;
										bottom: 0;
										left: 0;
										right: 0;
										margin: auto;
									}
								}

								.list-money {
									display: flex;
									align-items: center;
									justify-content: center;

									img {
										height: 20px;
										width: auto;
										margin-right: 5px;
									}

									span {
										font-family: '微软雅黑';
										color: #e9b10e;
										font-weight: 600;
									}
								}

								.list-name {
									text-align: center;
									margin-top: 5px;
									color: white;
									padding-bottom: 10px;
								}
							}
						}

						li:hover {
							cursor: pointer;
						}
					}
				}

				.luc-list1 {
					display: none;
					// height: 150px;
					margin-top: 20px;
					background-color: #2b2c37;
					width: 100%;
					//overflow: hidden;
					overflow-x: auto;

					ul {
						width: 1000px;
						background-color: #2b2c37;
						border-radius: 5px;
						overflow-y: hidden;

						li {
							background-color: #2b2c37;
							float: left;
							width: 100px;

							.list-warp {
								border-right: 1px solid #1a1c24;
								padding-top: 10px;

								.list-img {
									text-align: center;

									img {
										// height: 80px;
										width: 100px;
										height: auto;
									}
								}

								.list-money {
									display: flex;
									align-items: center;
									justify-content: center;

									img {
										height: 15px;
										width: auto;
										margin-right: 5px;
									}

									span {
										color: #e9b10e;
									}
								}

								.list-name {
									text-align: center;
									margin-top: 5px;
									color: #c3c3e2;
									padding-bottom: 10px;
								}
							}
						}

						li:hover {
							cursor: pointer;
							background-color: #30313f;
						}
					}
				}

				.luc-list /deep/ .el-carousel__indicators--outside {
					display: none;
				}

				/* .luc-list::-webkit-scrollbar {
			 
			  width: 2000px; 
			  height: 10px;
			}
			.luc-list::-webkit-scrollbar-thumb {
			 
			  border-radius: 10px;
			  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			  background: #535353;
			}
			.luc-list::-webkit-scrollbar-track {
			 
			  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			  border-radius: 10px;
			  background: #ededed;
			} */

				.hint {
					margin-top: 20px;
					display: flex;
					justify-content: space-between;

					span {
						color: #848492;
						font-size: 14px;
					}
				}

				.room {
					padding-bottom: 50px;
					margin-top: 20px;
					display: flex;
					.bg_1 {
						// background-image: url("../assets/new/await_1.png") !important;
						// background-size: 100% 100%;
						// background-repeat: no-repeat;
						background: linear-gradient(0deg, #d88674, rgba(210, 84, 53, .5));
						color: #fb9a84;
					}

					.bg_2 {
						background: linear-gradient(0deg, #b9d2ff, rgba(6, 20, 131, .5));
						color: #7ca5ee;
					}

					.bg_3 {
						background: linear-gradient(0deg, rgb(87, 87, 87), rgba(3, 3, 3, .5));
						color: #767676;
						opacity: .5;
					}

					.room-box {
						flex-grow: 3;
						flex-basis: 0;

						.room-box-ul {
							// margin-top: -8px;
							// display: grid;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-around;
							// gap: 10px;
							// grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
							margin-top: -6px;
							li {
								width: 33%;
								margin-top: 6px;
								// height: 187px;
								border-radius: 5px;

								.roombox-warp {
									// margin: 8px;
									// background-color: #22222d;
									border-radius: 5px;

									.status-condiv {
										display: flex;
										flex-direction: row;
										align-items: center;
										color: #fff;
										font-size: 14px;

										.point {
											width: 8px;
											height: 8px;
											// border-radius: 50%;
											background-color: #fff;
											margin-right: 5px;
										}

										.point_1 {
											// background-color: #e9b10e;
											width: 18px;
											height: 18px;
											background: url('../assets/img/ranking/dd.png');
										}

										.point_2 {
											width: 21px;
											height: 16px;
											background: url('../assets/img/ranking/jxz.png');
										}

										.point_3 {
											width: 24px;
											height: 24px;
											background: url('../assets/img/ranking/yjs.png');
										}

										.text_1 {
											color: #fb9a84;
										}

										.text_2 {
											color: #67c23a;
										}

										.text_3 {
											color: #999;
										}
									}

									.wanjia {
										display: inline-block;
										text-align: center;
										color: #fff;
										font-size: 20px;
										font-weight: 700;
										font-family: YouSheBiaoTiHei;
										width: 30%;
									}

									.status-img {
										height: 17px;
									}

									.vs {
										width: 17px;
										height: 12px;
										margin: 0 5px 0 10px;
									}

									.vs-info {
										display: flex;
										align-items: center;
									}

									.lucky-case {
										height: 60px;
										background: rgba(0, 0, 0, .2);
										display: flex;
										justify-content: flex-start;
										align-items: center;
										overflow: hidden;
									}

									.card-footer {
										display: flex;
										align-items: center;
										justify-content: space-between;
										padding: 15px 10px;

										div:first-child {
											display: flex;
											align-items: center;
											justify-content: center;

											span {
												font-size: 16px;
												padding-left: 5px;
												font-weight: 600;
												font-family: '微软雅黑';
												color: #e9b10e;
											}

											img {
												height: 20px;
												width: auto;
											}
										}

										div:last-child {
											clip-path: polygon(10px 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 10px 100%, 0 50%);
											padding: 0 10px 2px;
											background-color: #8875fb;
											color: #fff;
											width: unset;
											height: 32px;
											min-width: 60px;
											font-size: 14px;
											font-weight: 700;
											display: inline-flex;
											align-items: center;
											justify-content: center;
											cursor: pointer;
										}

										div:last-child:hover {
											background: #8e56ff;
										}
									}

									.box-detail {
										position: relative;
										margin-right: 5px;

										.box-num {
											position: absolute;
											top: 0;
											right: -5px;
											width: 15px;
											display: flex;
											font-size: 12px;
											align-items: center;
											justify-content: center;
											height: 15px;
											border-radius: 50%;
											background-color: #e9b10e;
											color: #000;
										}
									}

									.one {
										height: 30px;
										display: flex;
										padding: 5px 10px;
										align-items: center;
										justify-content: space-between;
										// background-color: #243438;
										border-top-left-radius: 5px;
										border-top-right-radius: 5px;
										font-weight: 600;
										font-size: 14px;
										font-family: '微软雅黑';

										.span1 {
											color: #02bf4d;
											font-size: 14px;
											display: flex;
											align-items: center;

											.span-dian {
												width: 8px;
												height: 8px;
												display: inline-block;
												animation: jss684 500ms linear infinite alternate;
												// box-shadow: 0 0  4px rgb(2 191 77 / 20%);
												box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
												margin-left: 10px;
												margin-right: 10px;
												border-radius: 4px;
												vertical-align: middle;
												background-color: #02bf4d;
											}

											@keyframes jss684 {
												0% {
													box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
												}

												100% {
													box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
												}
											}
										}

										.span3 {
											color: #c3c3e2;
											font-size: 14px;
										}

										.status2 {
											display: flex;
											align-items: center;

											.span2 {
												color: #ae7bfe;
												font-size: 14px;
												margin-right: 5px;
											}

											.img1 {
												animation: jss163 1000ms linear infinite;
											}

											@keyframes jss163 {
												0% {
													transform: rotate(0deg);
												}

												100% {
													transform: rotate(360deg);
												}
											}
										}
									}

									.two1 {
										// background-image: linear-gradient(#2a2c37, #23463b);
									}

									.two2 {
										// background-image: linear-gradient(#2d2d36, #483856);
										opacity: 0.5;
										// background-image: url("../assets/new/going.png");
										// background-size: 100% 100%;
										// background-repeat: no-repeat;
									}

									.two3 {
										// background-image: linear-gradient(#2d2d36, #483856);
										// background-image: url("../assets/new/end.png");
										// background-size: 100% 100%;
									}

									.two {
										padding: 20px 0;

										.two-bot {
											// margin-top: 10px;

											.room-peo {
												// min-height: 124px;
												display: flex;
												flex-direction: row;
												align-items: center;
												justify-content: center;

												.pk-warp {
													margin-top: 10px;
													display: flex;
													align-items: center;

													span:first-child {
														margin-left: 10px;
													}

													.pk-tou {
														margin: 0 5px;
														width: 40px;
														height: 40px;
														border-radius: 50%;
														display: flex;
														justify-content: center;
														align-items: center;
														//border: 1px dashed #e9b10e;
														position: relative;
														cursor: pointer;

														.image-border {
															position: absolute;
															top: 50%;
															left: 50%;
															transform: translate(-50%, -50%);
															width: 60px !important;
															height: 60px !important;
														}

														.pk-true {
															width: 40px;
															height: 40px;
															border-radius: 50%;
														}

														.pk-false {
															width: 25px;
															border-radius: 50%;
															height: 25px;
														}
													}

													.pk-tou-false {
														border: 1px dashed #e9b10e;
													}
												}
											}

											.room-btn {
												margin-top: 20px;
												display: flex;
												justify-content: center;

												span {
													border: 2px solid #e9b10e;
													padding: 4px 50px;
													border-radius: 30px;
													color: #e9b10e;
												}

												/* span:hover {
			                  cursor: pointer;
			                }*/
												.span1:hover {
													cursor: pointer;
													background-color: rgba(233, 177, 14, 0.1);
												}

												.span2:hover {
													cursor: pointer;
													background-color: rgba(132, 132, 146, 0.2);
												}
											}
										}
									}

									.three {
										border-bottom-right-radius: 5px;
										border-bottom-left-radius: 5px;
										// background-color: #243438;
										display: flex;
										flex-wrap: nowrap;
										margin-top: 10px;
										padding: 0 5px;
										position: relative;

										img {
											height: 45px;
											width: auto;
										}

										img:nth-child(2n) {
											position: absolute;
											z-index: 99;
											left: 0;
										}
									}

									.three::-webkit-scrollbar {
										/*滚动条整体样式*/
										width: 10px;
										/*高宽分别对应横竖滚动条的尺寸*/
										height: 6px;
									}

									.three::-webkit-scrollbar-thumb {
										/*滚动条里面小方块*/
										border-radius: 10px;
										background-color: #4b575c;
									}

									.three::-webkit-scrollbar-track {
										/*滚动条里面轨道*/
										box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
										// background: #243438;
									}
								}
							}

							li:nth-child(4n + 1) .roombox-warp {
								margin-left: 0;
							}

							li:nth-child(4n) .roombox-warp {
								margin-right: 0;
							}
						}
					}

					.more-btn {
						margin-top: 16px;
						display: flex;
						justify-content: center;

						span {
							color: #848492;
							font-size: 15px;
							padding: 9px 22px;
							border-radius: 5px;
							font-weight: 600;
							background-color: #333542;
						}

						span:hover {
							background-color: #3a3f50;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.sty-box {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .7);
		z-index: 999;
		margin: auto;
	}

	.sty {
		width: 644px;
		margin: 32px auto;
		height: 740px;
		background: url('../assets/img/ranking/dialog-bg.png') no-repeat;
		background-size: 100% 100%;
		padding: 0 16px;

		.header-title {
			font-weight: 700;
			color: #fff;
			text-align: center;
			width: 100%;
			padding-top: 36px;
		}

		.sty-one {
			margin: 16px 0;
			margin-top: 32px;
			display: flex;
			align-items: center;

			.sty-one-hint {
				width: 100px;
				font-size: 14px;
				padding-bottom: 5px;
				color: #c3c3e2;
			}

			.sty-one-pattern {
				display: flex;

				span {
					text-align: center;
					color: white;
					background: #409eff;
					border: 0px;
					border-radius: 0;
					padding: 1px;
					padding: 7px 19px !important;
					border: 1px solid #3C2378;
				}

				:first-child {
					// border-top-left-radius: 5px;
					// border-bottom-left-radius: 5px;
				}

				:last-child {
					// border-top-right-radius: 5px;
					// border-bottom-right-radius: 5px;
				}

				:hover {
					cursor: pointer;
				}

				.span1:hover {
					color: #409eff;
				}

				.span1 {
					color: #e447c1;
					text-align: center;
					background: transparent;
					border: 0px;
					border-radius: 0;
					padding: 1px;
					padding: 7px 19px !important;
					border: 1px solid #3C2378;
				}
			}
		}

		.form-label {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #9d9ddd;
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 10px;

			.two {
				font-size: 14px;
				color: #e447c1;
				margin-left: 4px;
			}

			.three {
				display: flex;
				align-items: center;
				margin-left: 4px;

				img {
					width: 20px;
				}

				span {
					margin-left: 4px;
					font-weight: 600;
					color: #ffc83c;
					font-family: '微软雅黑';
				}
			}
		}

		.sty-two {
			margin: 16px 0;

			.sty-two-hint1 {
				font-size: 14px;
				color: #c3c3e2;
			}

			.sty-two-hint2 {
				font-size: 12px;
				padding-bottom: 5px;
				color: #848492;
			}

			.sty-two-list {
				width: 100%;

				ul {
					width: 100%;
					// margin: 0 -2px;
					display: flex;
					flex-flow: row wrap;
					height: 220px;
					overflow: hidden;
					overflow-y: scroll;

					li {
						margin-top: 5px;
						width: 122px;
						height: 155px;

						.twolist-warp {
							height: 100%;
							margin: 2px;
							position: relative;
							display: flex;
							flex-direction: column;
							align-items: center;

							.twolist-top {
								width: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;

								img {
									width: 80%;
								}

								img:last-child {
									width: 50%;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									margin: auto;
									z-index: 9999;
								}
							}

							.twolist-top:hover img:last-child {
								animation: flip 1s infinite alternate;
							}

							@keyframes flip {
								25% {
									top: -10px;
								}

								50%,
								100% {
									top: 0;
								}

								75% {
									top: 10px;
								}
							}

							.twolist-bot1 {
								color: white;
								font-weight: 600;
								font-size: 14px;
							}

							.twolist-bot {
								padding: 4px 0;
								display: flex;
								justify-content: center;
								align-items: center;

								img {
									width: auto;
									height: 20px;
								}

								span {
									margin-left: 5px;
									// color: #e9b10e;
									font-weight: 600;
									color: #ffc83c;
									font-family: '微软雅黑';
								}
							}

							.twolist-num {
								position: absolute;
								width: 100%;
								height: 100%;
								top: 0;
								border: 1px solid #e9b10e;
								background-color: rgba(233, 177, 14, 0.3);
								color: #e9b10e;
							}

							.twolist-num1 {
								display: none;
							}
						}
					}

					:hover {
						cursor: pointer;
					}
				}
			}

			/*滚动条样式*/
			.sty-two-list::-webkit-scrollbar {
				width: 4px;
				/*height: 4px;*/
			}

			.sty-two-list::-webkit-scrollbar-thumb {
				border-radius: 10px;
				box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: rgba(0, 0, 0, 0.2);
			}

			.sty-two-list::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 0;
				background: rgba(0, 0, 0, 0.1);
			}
		}

		.sty-three {
			padding: 0 0;

			.sty-three-hint {
				display: flex;
				justify-content: space-between;
				align-items: center;

				span {
					color: #848492;
					font-size: 14px;

					strong {
						color: #e9b10e;
						font-weight: 200;
					}
				}
			}

			.sty-three-list {

				ul {
					width: 100%;
					// margin: 0 -2px;
					display: flex;
					flex-flow: row wrap;
					overflow: hidden;
					height: 170px;
					overflow-y: scroll;

					li {
						width: 122px;
						height: 155px;
						text-align: center;

						.twolist-warp {
							height: 100%;
							margin: 2px;
							position: relative;
							display: flex;
							flex-direction: column;
							align-items: center;

							.twolist-top {
								width: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;

								img {
									width: 80%;
								}

								img:last-child {
									width: 50%;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									margin: auto;
									z-index: 9999;
								}
							}

							.twolist-top:hover img:last-child {
								animation: flip 1s infinite alternate;
							}

							@keyframes flip {
								25% {
									top: -10px;
								}

								50%,
								100% {
									top: 0;
								}

								75% {
									top: 10px;
								}
							}

							.twolist-bot1 {
								color: white;
								font-weight: 600;
								font-size: 14px;
							}

							.twolist-bot {
								padding: 4px 0;
								display: flex;
								justify-content: center;
								align-items: center;

								img {
									width: auto;
									height: 20px;
								}

								span {
									margin-left: 5px;
									// color: #e9b10e;
									font-weight: 600;
									color: #ffc83c;
									font-family: '微软雅黑';
								}
							}

							.twolist-num {
								position: absolute;
								width: 100%;
								height: 100%;
								top: 0;
								border: 1px solid #e9b10e;
								background-color: rgba(233, 177, 14, 0.3);
								color: #e9b10e;
							}

							.twolist-num1 {
								display: none;
							}
						}
					}

					:hover {
						cursor: pointer;
					}
				}
			}

			.sty-three-btn1 {
				display: flex;
				justify-content: center;
			}

			.sty-three-btn {
				text-align: center;
				padding: 10px 25px;
				border-radius: 5px;
				background-color: rgba(64, 53, 170, 70%);
				font-size: 14px;
				margin-top: 30px;
				color: white;
				border: 1px solid #1d2292;
				// border-color: #e9b10e;
			}

			.sty-three-btn:hover {
				background-color: rgba(122, 115, 196, 70%);
				cursor: pointer;
			}
		}
	}

	.sty {
		/deep/ .el-drawer__wrapper {
			top: 60px;
		}

		/deep/ .el-drawer__header {
			display: none;
		}

		/deep/ .el-drawer__body {
			background-color: #30313f;
		}
	}

	.ripple {
		position: relative;
		//隐藏溢出的径向渐变背景
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.ripple:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;
		//设置径向渐变
		background-image: radial-gradient(circle,
				rgb(163, 162, 162) 10%,
				transparent 10.01%);
		background-repeat: no-repeat;
		background-position: 50%;
		transform: scale(20, 20);
		opacity: 0;
		transition: transform 0.3s, opacity 0.5s;
	}

	.ripple:active:after {
		transform: scale(0, 0);
		opacity: 0.3;
		//设置初始状态
		transition: 0s;
	}

	.lucky /deep/ .el-drawer__open .el-drawer.rtl {
		overflow-y: auto;
	}

	//底部弹框
	.box-bot {
		.box-img {
			.box-img-title {
				padding: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					font-size: 16px;
					color: white;
				}

				span:last-child {
					cursor: pointer;
				}

				.span1 {
					color: #e9b10e;
				}
			}

			.box-img-title1 {
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					color: rgb(255, 255, 255);
					text-align: center;
					width: 100%;
					font-size: 18px;
					font-weight: 600;
					margin-bottom: 20px;
				}

				span:last-child {
					cursor: pointer;
				}
			}

			.box-img-list {
				padding: 0 16px;

				ul {
					display: flex;
					flex-wrap: wrap;
					overflow: auto;
					height: 190px;

					.blue {
						background-image: url("../assets/img/ranking/arms-bg1.png");
					}

					.red {
						background-image: url("../assets/img/ranking/arms-bg6.png");
					}

					.yellow {
						background-image: url("../assets/img/ranking/arms-bg7.png");
					}

					.blue:hover {
						background-image: url("../assets/img/ranking/arms-on-bg1.png");
					}

					.red:hover {
						background-image: url("../assets/img/ranking/arms-on-bg6.png");
					}

					.yellow:hover {
						background-image: url("../assets/img/ranking/arms-on-bg7.png");
					}

					li {
						width: 200px;
						height: 134px;
						background-size: 100% 100%;
						margin-right: 16px;
						margin-top: 12px;
						position: relative;

						.box-img1 {
							background-size: 100% 100%;
							position: absolute;
							top: 20%;
							height: 50%;
							left: 50%;
							margin-left: -23%;

							img {
								width: 85px;
							}
						}

						.box-img-name {
							color: white;
							font-size: 12px;
							position: absolute;
							left: 4px;
							bottom: 1%;
							display: inline-block;
							width: 100%;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							text-align: left;
						}

						.probability {
							font-size: 14px;
							position: absolute;
							right: 4px;
							top: 5px;
							font-weight: 700;
							font-family: '微软雅黑';
							color: white;

						}

						.list-pirce {
							padding: 0;
							display: flex;
							justify-content: space-between;
							align-items: center;
						}

						.ico {
							position: absolute;
							top: 0;
							left: 0;
							font-size: 12px;
							color: #c3c3e2;
							background-color: rgba(0, 0, 0, 0.5);
							padding: 2px 4px;
						}

						.pirce-left {
							display: flex;
							align-items: center;

							img {
								width: auto;
								height: 20px;
							}

							span {
								color: #e9b10e;
								font-size: 16px;
								font-weight: 600;
								font-family: '微软雅黑';
								margin-left: 4px;
							}
						}

						.pirce-right {
							color: white;
							font-size: 12px;
							margin-right: 12px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
	}
</style>